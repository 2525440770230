
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { INodeUi } from '@/Interface';
import { BOARD_AUTOMATION_WORKFLOW_TYPE } from '@/constants';

export default Vue.extend({
	name: 'SaveButton',
	props: {
		saved: {
			type: Boolean,
		},
		isSaving: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		saveLabel: {
			type: String,
		},
		savingLabel: {
			type: String,
		},
		savedLabel: {
			type: String,
		},
		isActive: {
			type: Boolean,
		},
	},
	data() {
		return {
			isNewCreated: false,
			savedCount: 1,
		};
	},
	computed: {
		...mapGetters(['workflowId', 'isNewWorkflow']),
		...mapGetters('imbrace', [
			'currentWorkflowType',
			'isActiveLoading',
			'isActiveDisabled',
			'parentOrigin',
		]),
		isButtonLoading(): boolean {
			return this.isSaving || this.isActiveLoading;
		},
		isButtonDisabled(): boolean {
			return this.saved || this.disabled;
		},
		saveButtonLabel(): string {
			return this.isButtonLoading ? '' : this.$locale.baseText('saveButton.save');
		},
		title(): string {
			if (this.disabled && this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
				return this.$locale.baseText('workflowActivator.thisWorkflowHasNoTriggerNodes');
			}
			return '';
		},
	},
	watch: {
		isNewWorkflow(cur, prev) {
			if (!cur && prev) {
				this.$data.isNewCreated = true;
			}
		},
		saved(currentVal) {
			const allNodeNamesOnCanvas = this.$store.getters.allNodes.map((n: INodeUi) => n.name);
			if (currentVal) {
				this.$data.savedCount += 1;
			}

			if (this.parentOrigin) {
				window.parent.postMessage(
					{
						data: {
							code: 'success',
							isNewCreated: this.$data.isNewCreated,
							savedCount: this.$data.savedCount,
							saved: currentVal,
							workflowId: this.workflowId,
							nodeIndex: allNodeNamesOnCanvas,
						},
					},
					this.parentOrigin,
				);
			}
		},
	},
});
