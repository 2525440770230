
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';
import { ABOUT_MODAL_KEY, VIEWS } from '@/constants';
import { userHelpers } from './mixins/userHelpers';

export default mixins(
	userHelpers,
).extend({
	name: 'SettingsSidebar',
	computed: {
		...mapGetters('settings', ['versionCli']),
	},
	methods: {
		canAccessPersonalSettings(): boolean {
			return this.canUserAccessRouteByName(VIEWS.PERSONAL_SETTINGS);
		},
		canAccessUsersSettings(): boolean {
			return this.canUserAccessRouteByName(VIEWS.USERS_SETTINGS);
		},
		onVersionClick() {
			this.$store.dispatch('ui/openModal', ABOUT_MODAL_KEY);
		},
		onReturn() {
			this.$router.push({name: VIEWS.HOMEPAGE});
		},
	},
});
