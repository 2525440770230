
import { copyPaste } from '@/components/mixins/copyPaste';
import { externalHooks } from '@/components/mixins/externalHooks';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { mouseSelect } from '@/components/mixins/mouseSelect';
import { moveNodeWorkflow } from '@/components/mixins/moveNodeWorkflow';
import { newVersions } from '@/components/mixins/newVersions';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { titleChange } from '@/components/mixins/titleChange';
import {
	AUTOMATION_WORKFLOW_TYPE,
	BOARD_AUTOMATION_WORKFLOW_TYPE,
	BOARD_WORKFLOW_TYPE,
	CHANNEL_ACTIONS_CATEGORY,
	CHANNEL_AUTOMATION_WORKFLOW_TYPE,
	CHANNEL_TYPE,
	CHANNEL_WORKFLOW_TYPE,
	DEACTIVATE_WORKFLOW_MODAL_KEY,
	DEFAULT_WORKFLOW_TYPE,
	EXECUTE_WORKFLOW_NODE_TYPE,
	HIDDEN_NODES,
	MODAL_CANCEL,
	MODAL_CLOSE,
	MODAL_CONFIRMED,
	NODE_NAME_PREFIX,
	NODE_OUTPUT_DEFAULT_KEY,
	PLACEHOLDER_EMPTY_WORKFLOW_ID,
	PRESET_WORKFLOW_TYPE,
	START_NODE_TYPE,
	TRIGGERS_CATEGORY,
	VALID_TAGS,
	VIEWS,
	WEBHOOK_NODE_TYPE,
	WORKFLOW_OPEN_MODAL_KEY,
	STICKY_NODE_TYPE,
	DEFAULT_STICKY_WIDTH,
	DEFAULT_STICKY_HEIGHT,
	JOURNEY_WORKFLOW_SOURCE,
	IDEA_WORKFLOW_SOURCE,
	DEFAULT_WORKFLOW_SOURCE, LATEST_NODE_STYLE_VERSION, DEFAULT_NODE_STYLE_VERSION,
} from '@/constants';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import {
	Connection,
	Endpoint,
	N8nPlusEndpoint,
	OnConnectionBindInfo,
	jsPlumb,
} from 'jsplumb';
import Vue from 'vue';

import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { workflowRun } from '@/components/mixins/workflowRun';

import DataDisplay from '@/components/DataDisplay.vue';
import Layer from '@/components/Layer.vue';
import Node from '@/components/Node.vue';
import NodeCreator from '@/components/NodeCreator/NodeCreator.vue';
import NodeDetailsView from '@/components/NodeDetailsView.vue';
import NodeList from '@/components/NodeList/NodeList.vue';
import NodeSettings from '@/components/NodeSettings.vue';
import NodeV2 from '@/components/NodeV2.vue';
import Sticky from '@/components/Sticky.vue';

import * as CanvasHelpers from './canvasHelpers';

import {
	IConnection,
	IConnections,
	IDataObject,
	INode,
	INodeConnections,
	INodeCredentialsDetails,
	INodeIssues,
	INodeTypeDescription,
	INodeTypeNameVersion,
	IRun,
	ITaskData,
	NodeHelpers,
	Workflow,
} from 'n8n-workflow';
import { v4 as uuidv4 } from 'uuid';
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';
import {
	ICredentialsResponse,
	IExecutionResponse,
	IExecutionsSummary,
	INodeUi,
	IPushDataExecutionFinished,
	ITag,
	IUpdateInformation,
	IWorkflowData,
	IWorkflowDataUpdate,
	IWorkflowDb,
	IWorkflowTemplate,
	XYPosition,
} from '../Interface';

import { addNodeTranslation } from '@/plugins/i18n';

import '../plugins/N8nCustomConnectorType';
import '../plugins/PlusEndpointType';

interface AddNodeOptions {
	position?: XYPosition;
	dragAndDrop?: boolean;
}

export default mixins(
	copyPaste,
	externalHooks,
	genericHelpers,
	mouseSelect,
	moveNodeWorkflow,
	restApi,
	showMessage,
	titleChange,
	workflowHelpers,
	workflowRun,
	newVersions,
)
	.extend({
		name: 'NodeView',
		components: {
			DataDisplay,
			NodeDetailsView,
			Layer,
			Node,
			NodeCreator,
			NodeSettings,
			NodeList,
			NodeV2,
			Sticky,
		},
		errorCaptured: (err, vm, info) => {
			console.error('errorCaptured'); // eslint-disable-line no-console
			console.error(err); // eslint-disable-line no-console
		},
		watch: {
			// Listen to route changes and load the workflow accordingly
			'$route': 'initView',
			activeNode() {
				// When a node gets set as active deactivate the create-menu
				this.$store.commit('updateNodeCreatorState', false);
			},
			nodes: {
				async handler(value, oldValue) {
					// Load a workflow
					let workflowId = null as string | null;
					if (this.$route && this.$route.params.name) {
						workflowId = this.$route.params.name;
					}
				},
				deep: true,
			},
			connections: {
				async handler(value, oldValue) {
					// Load a workflow
					let workflowId = null as string | null;
					if (this.$route && this.$route.params.name) {
						workflowId = this.$route.params.name;
					}
				},
				deep: true,
			},
			isStateDirty() {
				if (this.parentOrigin) {
					window.parent.postMessage({
						type: "dirtyAction",
						data: this.isStateDirty,
					}, this.parentOrigin);
				}

				// If workflow is in active status and we edit workflow, open DeactivateWorkflowModal
				if (this.isWorkflowActive && this.isStateDirty && this.finishInitialRender) {
					this.$store.dispatch('ui/openModal', DEACTIVATE_WORKFLOW_MODAL_KEY);
				}
			},
			async reloadWorkflow() {
				// If user choose "cancel" in DeactivateWorkflowModal, reload workflow
				if (this.reloadWorkflow) {
					this.startLoading();
					await this.resetWorkspace();
					await this.initView();
					this.$store.commit("setReloadWorkflow", false);
					this.stopLoading();
				}
			},
			availableNodeTypes() {
				this.$store.commit('imbrace/setAvailableNodeTypes', this.availableNodeTypes);
			},
			selectedNodes(newNodes, oldNodes) {
				if (this.isReadOnly) return;

				// Clear connection colors for old selected nodes
				oldNodes.forEach((node: INodeUi) => {
					const name = `${NODE_NAME_PREFIX}${this.$store.getters.getNodeIndex(node.name)}`;
					// @ts-ignore
					(this.instance.getConnections({ target: name }) as Connection[]).forEach(connection => {
						connection.setPaintStyle(CanvasHelpers.CONNECTOR_PAINT_STYLE_DEFAULT);
					});
					// @ts-ignore
					(this.instance.getConnections({ source: name }) as Connection[]).forEach(connection => {
						connection.setPaintStyle(CanvasHelpers.CONNECTOR_PAINT_STYLE_DEFAULT);
					});
				});

				// Sets the connection color for newly selected nodes
				newNodes.forEach((node: INodeUi) => {
					const name = `${NODE_NAME_PREFIX}${this.$store.getters.getNodeIndex(node.name)}`;
					// @ts-ignore
					(this.instance.getConnections({ target: name }) as Connection[]).forEach(connection => {
						connection.setPaintStyle(CanvasHelpers.CONNECTOR_PAINT_STYLE_PRIMARY);
					});
					// @ts-ignore
					(this.instance.getConnections({ source: name }) as Connection[]).forEach(connection => {
						connection.setPaintStyle(CanvasHelpers.CONNECTOR_PAINT_STYLE_PRIMARY);
					});
				});
			},
			currentChannelType(newValue, oldValue) {
				if (newValue) {
					this.loadImbraceChannel();
				}
			},
		},
		async beforeRouteLeave(to, from, next) {
			const result = this.$store.getters.getStateIsDirty;
			if (result && !this.isWorkflowActive) {
				const confirmModal = await this.confirmModal(
					this.$locale.baseText('nodeView.confirmMessage.beforeRouteLeave.message'),
					this.$locale.baseText('nodeView.confirmMessage.beforeRouteLeave.headline'),
					'warning',
					this.$locale.baseText('nodeView.confirmMessage.beforeRouteLeave.confirmButtonText'),
					this.$locale.baseText('nodeView.confirmMessage.beforeRouteLeave.cancelButtonText'),
					true,
				);

				if (confirmModal === MODAL_CONFIRMED) {
					const saved = await this.saveCurrentWorkflow({}, false);
					if (saved) this.$store.dispatch('settings/fetchPromptsData');
					this.$store.commit('setStateDirty', false);
					next();
					this.initializeSettings();
				} else if (confirmModal === MODAL_CANCEL) {
					this.$store.commit('setStateDirty', false);
					next();
					this.initializeSettings();
				} else if (confirmModal === MODAL_CLOSE) {
					next(false);
				}

			} else {
				next();
				this.initializeSettings();
			}
		},
		computed: {
			...mapGetters({
				isWorkflowActive: "isActive",
				isStateDirty: "getStateIsDirty",
				reloadWorkflow: "reloadWorkflow",
				toggleNodeCreator: "toggleNodeCreator",
				allNodeTypes: "allNodeTypes",
				selectedNodes: 'getSelectedNodes',
			}),
			...mapGetters('ui', [
				'sidebarMenuCollapsed',
			]),
			...mapGetters("tags", ["allTags"]),
			...mapGetters("imbrace", [
				"currentWorkflowType",
				"currentChannelType",
				"needToSetDefaultName",
				"hideStartNode",
				"startingFlow",
				"excludeCategories",
				"parentOrigin",
				"isImbracePaidUser",
				"nodeStyleVersion",
			]),
			defaultLocale(): string {
				return this.$store.getters.defaultLocale;
			},
			isEnglishLocale(): boolean {
				return this.defaultLocale === 'en';
			},
			...mapGetters(['nativelyNumberSuffixedDefaults']),
			activeNode(): INodeUi | null {
				return this.$store.getters.activeNode;
			},
			executionWaitingForWebhook(): boolean {
				return this.$store.getters.executionWaitingForWebhook;
			},
			isDemo(): boolean {
				return this.$route.name === VIEWS.DEMO;
			},
			lastSelectedNode(): INodeUi | null {
				return this.$store.getters.lastSelectedNode;
			},
			nodes(): INodeUi[] {
				return this.$store.getters.allNodes;
			},
			displayNodes(): INodeUi[] {
				if (this.hideStartNode) {
					return this.nodes.filter((node) => node.type !== START_NODE_TYPE);
				}
				return this.nodes;
			},
			defaultNodesToRender(): INodeUi[] {
				return this.nodeStyleVersion !== 2 ? this.displayNodes.filter((node) => node.type !== STICKY_NODE_TYPE) : [];
			},
			v2NodesToRender(): INodeUi[] {
				return this.nodeStyleVersion === 2 ? this.displayNodes.filter((node) => node.type !== STICKY_NODE_TYPE) : [];
			},
			stickiesToRender(): INodeUi[] {
				return this.displayNodes.filter((node) => node.type === STICKY_NODE_TYPE);
			},
			nodesToRenderInNodeList(): INodeUi[] {
				return this.displayNodes.filter((node) => node.type !== STICKY_NODE_TYPE);
			},
			runButtonText(): string {
				if (this.workflowRunning === false) {
					return this.$locale.baseText('nodeView.runButtonText.executeWorkflow');
				}

				if (this.executionWaitingForWebhook === true) {
					return this.$locale.baseText('nodeView.runButtonText.waitingForTriggerEvent');
				}

				return this.$locale.baseText('nodeView.runButtonText.executingWorkflow');
			},
			workflowStyle(): object {
				const offsetPosition = this.$store.getters.getNodeViewOffsetPosition;
				return {
					left: offsetPosition[0] + 'px',
					top: offsetPosition[1] + 'px',
				};
			},
			// backgroundStyle (): object {
			// 	return CanvasHelpers.getBackgroundStyles(this.nodeViewScale, this.$store.getters.getNodeViewOffsetPosition);
			// },
			workflowClasses() {
				const returnClasses = [];
				if (this.ctrlKeyPressed === true || this.isPreview) {
					if (this.$store.getters.isNodeViewMoveInProgress === true) {
						returnClasses.push('move-in-process');
					} else {
						returnClasses.push('move-active');
					}
				}
				if (this.selectActive || this.ctrlKeyPressed === true) {
					// Makes sure that nothing gets selected while select or move is active
					returnClasses.push('do-not-select');
				}
				return returnClasses;
			},
			workflowExecution(): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowRunning(): boolean {
				return this.$store.getters.isActionActive('workflowRunning');
			},
			availableNodeTypes(): INodeTypeDescription[] {
				return this.allNodeTypes
					.filter((nodeType: INodeTypeDescription) => {
						const isValidInCurrentWorkflowType = this.isValidInCurrentWorkflowType(nodeType.name);
						const isValidInCurrentChannelType = this.isValidInCurrentChannelType(nodeType.name);
						return isValidInCurrentWorkflowType && isValidInCurrentChannelType && !HIDDEN_NODES.includes(nodeType.name);
					}).reduce((accumulator: INodeTypeDescription[], currentValue: INodeTypeDescription) => {
						// keep only latest version of the nodes
						// accumulator starts as an empty array.
						const exists = accumulator.findIndex(nodes => nodes.name === currentValue.name);
						if (exists >= 0 && accumulator[exists].version < currentValue.version) {
							// This must be a versioned node and we've found a newer version.
							// Replace the previous one with this one.
							accumulator[exists] = currentValue;
						} else {
							accumulator.push(currentValue);
						}
						return accumulator;
					}, []);
			},
			isFullscreen(): boolean {
				if (Object.keys(this.$route.query).includes('fullscreen')) {
					return true;
				}
				return false;
			},
		},
		data() {
			return {
				GRID_SIZE: CanvasHelpers.GRID_SIZE,
				STICKY_NODE_TYPE,
				instance: jsPlumb.getInstance(),
				lastSelectedConnection: null as null | Connection,
				lastClickPosition: [450, 450] as XYPosition,
				nodeViewScale: 1,
				ctrlKeyPressed: false,
				stopExecutionInProgress: false,
				blankRedirect: false,
				credentialsUpdated: false,
				newNodeInsertPosition: null as XYPosition | null,
				pullConnActiveNodeName: null as string | null,
				pullConnActive: false,
				dropPrevented: false,
				renamingActive: false,
				finishInitialRender: false,
				toggleNodeList: false,
			};
		},
		beforeDestroy() {
			this.resetWorkspace();
			// Make sure the event listeners get removed again else we
			// could add up with them registred multiple times
			document.removeEventListener('keydown', this.keyDown);
			document.removeEventListener('keyup', this.keyUp);
		},
		methods: {
			initializeSettings() {
				this.$store.commit('imbrace/setCurrentWorkflowType', DEFAULT_WORKFLOW_TYPE);
				this.$store.commit('imbrace/setCurrentChannelType', null);
				this.$store.commit('imbrace/setCurrentWorkflowSource', DEFAULT_WORKFLOW_SOURCE);
				this.$store.commit('imbrace/setNeedToSetDefaultName', false);
				this.$store.commit('imbrace/setNodeStyleVersion', null);
			},
			clearExecutionData() {
				this.$store.commit('setWorkflowExecutionData', null);
				this.updateNodesExecutionIssues();
			},
			translateName(type: string, originalName: string) {
				return this.$locale.headerText({
					key: `headers.${this.$locale.shortNodeType(type)}.displayName`,
					fallback: originalName,
				});
			},
			getUniqueNodeName({
				originalName,
				additionalUsedNames = [],
				type = '',
			}: {
				originalName: string,
				additionalUsedNames?: string[],
				type?: string,
			}) {
				const allNodeNamesOnCanvas = this.$store.getters.allNodes.map((n: INodeUi) => n.name);
				originalName = this.isEnglishLocale ? originalName : this.translateName(type, originalName);

				if (
					!allNodeNamesOnCanvas.includes(originalName) &&
					!additionalUsedNames.includes(originalName)
				) {
					return originalName; // already unique
				}

				let natives: string[] = this.nativelyNumberSuffixedDefaults;
				natives = this.isEnglishLocale ? natives : natives.map(name => {
					const type = name.toLowerCase().replace('_', '');
					return this.translateName(type, name);
				});

				const found = natives.find((n) => originalName.startsWith(n));

				let ignore, baseName, nameIndex, uniqueName;
				let index = 1;

				if (found) {
					// name natively ends with number
					nameIndex = originalName.split(found).pop();
					if (nameIndex) {
						index = parseInt(nameIndex, 10);
					}
					baseName = uniqueName = found;
				} else {
					const nameMatch = originalName.match(/(.*\D+)(\d*)/);

					if (nameMatch === null) {
						// name is only a number
						index = parseInt(originalName, 10);
						baseName = '';
						uniqueName = baseName + index;
					} else {
						// name is string or string/number combination
						[ignore, baseName, nameIndex] = nameMatch;
						if (nameIndex !== '') {
							index = parseInt(nameIndex, 10);
						}
						uniqueName = baseName;
					}
				}

				while (
					allNodeNamesOnCanvas.includes(uniqueName) ||
					additionalUsedNames.includes(uniqueName)
				) {
					uniqueName = baseName + (index++);
				}

				return uniqueName;
			},
			async onSaveKeyboardShortcut() {
				// In channel & channel automation WF,
				// everytime user save WF, we will automatically active WF
				if (this.currentChannelType) {
					this.$store.commit('setActive', true);
					this.$store.commit('imbrace/setUpdateActiveChanged', true);
					return;
				}

				const saved = await this.saveCurrentWorkflow();
				if (saved) this.$store.dispatch('settings/fetchPromptsData');
			},
			openNodeCreator(source: string) {
				this.$store.commit('updateNodeCreatorState', true);
				this.$externalHooks().run('nodeView.createNodeActiveChanged', {source, createNodeActive: this.toggleNodeCreator});
				this.$telemetry.trackNodesPanel('nodeView.createNodeActiveChanged', {
					source,
					workflow_id: this.$store.getters.workflowId,
					createNodeActive: this.toggleNodeCreator,
				});
			},
			async openExecution(executionId: string) {
				this.resetWorkspace();

				let data: IExecutionResponse | undefined;
				try {
					data = await this.restApi().getExecution(executionId);
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.openExecution.title'),
					);
					return;
				}

				if (data === undefined) {
					throw new Error(`Execution with id "${executionId}" could not be found!`);
				}

				this.$store.commit('setWorkflowName', {newName: data.workflowData.name, setStateDirty: false});
				this.$store.commit('setWorkflowId', PLACEHOLDER_EMPTY_WORKFLOW_ID);

				this.$store.commit('setWorkflowExecutionData', data);

				await this.addNodes(JSON.parse(JSON.stringify(data.workflowData.nodes)), JSON.parse(JSON.stringify(data.workflowData.connections)));
				this.$nextTick(() => {
					this.zoomToFit();
					this.$store.commit('setStateDirty', false);
				});

				this.$externalHooks().run('execution.open', {workflowId: data.workflowData.id, workflowName: data.workflowData.name, executionId});
				this.$telemetry.track('User opened read-only execution', {
					workflow_id: data.workflowData.id,
					execution_mode: data.mode,
					execution_finished: data.finished,
				});

				if (data.finished !== true && data && data.data && data.data.resultData && data.data.resultData.error) {
					// Check if any node contains an error
					let nodeErrorFound = false;
					if (data.data.resultData.runData) {
						const runData = data.data.resultData.runData;
						errorCheck:
						for (const nodeName of Object.keys(runData)) {
							for (const taskData of runData[nodeName]) {
								if (taskData.error) {
									nodeErrorFound = true;
									break errorCheck;
								}
							}
						}
					}

					if (nodeErrorFound === false) {
						const resultError = data.data.resultData.error;
						const errorMessage = this.$getExecutionError(resultError);
						const shouldTrack = resultError && resultError.node && resultError.node.type.startsWith('n8n-nodes-base');
						this.$showMessage({
							title: 'Failed execution',
							message: errorMessage,
							type: 'error',
						}, shouldTrack);

						if (data.data.resultData.error.stack) {
							// Display some more information for now in console to make debugging easier
							// TODO: Improve this in the future by displaying in UI
							console.error(`Execution ${executionId} error:`); // eslint-disable-line no-console
							console.error(data.data.resultData.error.stack); // eslint-disable-line no-console
						}
					}
				}

				if ((data as IExecutionsSummary).waitTill) {
					this.$showMessage({
						title: this.$locale.baseText('nodeView.thisExecutionHasntFinishedYet'),
						message: `<a onclick="window.location.reload(false);">${this.$locale.baseText('nodeView.refresh')}</a> ${this.$locale.baseText('nodeView.toSeeTheLatestStatus')}.<br/> <a href="https://docs.n8n.io/nodes/n8n-nodes-base.wait/" target="_blank">${this.$locale.baseText('nodeView.moreInfo')}</a>`,
						type: 'warning',
						duration: 0,
					});
				}
			},
			async importWorkflowExact(data: { workflow: IWorkflowDataUpdate }) {
				if (!data.workflow.nodes || !data.workflow.connections) {
					throw new Error('Invalid workflow object');
				}
				this.resetWorkspace();
				data.workflow.nodes = CanvasHelpers.getFixedNodesList(data.workflow.nodes);
				await this.addNodes(data.workflow.nodes, data.workflow.connections);
				this.$nextTick(() => {
					this.zoomToFit();
				});
			},
			async openWorkflowTemplate(templateId: string) {
				this.setLoadingText(this.$locale.baseText('nodeView.loadingTemplate'));
				this.resetWorkspace();

				let data: IWorkflowTemplate | undefined;
				try {
					this.$externalHooks().run('template.requested', {templateId});
					data = await this.$store.dispatch('templates/getWorkflowTemplate', templateId);

					if (!data) {
						throw new Error(
							this.$locale.baseText(
								'nodeView.workflowTemplateWithIdCouldNotBeFound',
								{interpolate: {templateId}},
							),
						);
					}
				} catch (error) {
					this.$showError(error, this.$locale.baseText('nodeView.couldntImportWorkflow'));
					this.$router.replace({name: VIEWS.NEW_WORKFLOW});
					return;
				}

				data.workflow.nodes = CanvasHelpers.getFixedNodesList(data.workflow.nodes);

				this.blankRedirect = true;
				this.$router.replace({name: VIEWS.NEW_WORKFLOW, query: {templateId}});

				await this.addNodes(data.workflow.nodes, data.workflow.connections);
				await this.$store.dispatch('workflows/setNewWorkflowName', data.name);
				this.$nextTick(() => {
					this.zoomToFit();
					this.$store.commit('setStateDirty', true);
				});

				this.$externalHooks().run('template.open', {templateId, templateName: data.name, workflow: data.workflow});
			},
			async openIdeaPreview(ideaId: string) {
				this.resetWorkspace();

				let data: IWorkflowData | undefined;
				try {
					const access_token = this.$store.getters['imbrace/accessToken'] || process.env.VUE_APP_TEMPORARY_TOKEN;

					if (!access_token) {
						throw new Error(
							this.$locale.baseText('nodeView.accessTokenNotFound'),
						);
					}

					const headers = {'x-access-token': `${access_token}`};
					const idea = await this.restApi().getIdeaById(ideaId, headers);
					data = idea.template.workflow_template;

					if (!data) {
						throw new Error(
							this.$locale.baseText(
								'nodeView.ideaWithIdCouldNotBeFound',
								{interpolate: {ideaId}},
							),
						);
					}
				} catch (error) {
					this.$showError(error, this.$locale.baseText('nodeView.couldntImportWorkflow'));
					return;
				}

				this.checkTypeWhenOpenWorkflow(data);

				await this.addNodes(data.nodes, data.connections);
				this.$nextTick(() => {
					this.zoomToFit();
				});
			},
			async openWorkflow(workflowId: string) {
				this.resetWorkspace();

				let data: IWorkflowDb | undefined;
				try {
					data = await this.restApi().getWorkflow(workflowId);
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.openWorkflow.title'),
					);
					return;
				}

				if (data === undefined) {
					throw new Error(
						this.$locale.baseText(
							'nodeView.workflowWithIdCouldNotBeFound',
							{interpolate: {workflowId}},
						),
					);
				}

				this.$store.commit('setActive', data.active || false);
				this.$store.commit('setWorkflowId', workflowId);
				this.$store.commit('setWorkflowName', {newName: data.name, setStateDirty: false});
				this.$store.commit('setWorkflowSettings', data.settings || {});

				this.checkTypeWhenOpenWorkflow(data);

				await this.addNodes(data.nodes, data.connections);
				if (!this.credentialsUpdated) {
					this.$store.commit('setStateDirty', false);
				}

				this.zoomToFit();

				this.$externalHooks().run('workflow.open', {workflowId, workflowName: data.name});

				return data;
			},
			touchTap(e: MouseEvent | TouchEvent) {
				if (this.isTouchDevice) {
					this.mouseDown(e);
				}
			},
			mouseDown(e: MouseEvent | TouchEvent) {
				// Save the location of the mouse click
				this.lastClickPosition = this.getMousePositionWithinNodeView(e);

				this.mouseDownMouseSelect(e as MouseEvent);
				this.mouseDownMoveWorkflow(e as MouseEvent);

				// Hide the node-creator
				this.$store.commit('updateNodeCreatorState', false);
			},
			mouseUp(e: MouseEvent) {
				this.mouseUpMouseSelect(e);
				this.mouseUpMoveWorkflow(e);
			},
			wheelScroll(e: WheelEvent) {
				//* Control + scroll zoom
				if (e.ctrlKey) {
					if (e.deltaY > 0) {
						this.zoomOut();
					} else {
						this.zoomIn();
					}

					e.preventDefault();
					return;
				}

				// disable scroll in preview and not fullscreen
				if (this.isPreview && !this.isFullscreen) return;
				this.wheelMoveWorkflow(e);
			},
			keyUp(e: KeyboardEvent) {
				if (e.key === this.controlKeyCode) {
					this.ctrlKeyPressed = false;
				}
			},
			async keyDown(e: KeyboardEvent) {
				// @ts-ignore
				const path = e.path || (e.composedPath && e.composedPath());

				// Check if the keys got emitted from a message box or from something
				// else which should ignore the default keybindings
				for (let index = 0; index < path.length; index++) {
					if (path[index].className && typeof path[index].className === 'string' && (
						path[index].className.includes('ignore-key-press')
					)) {
						return;
					}
				}

				if (this.toggleNodeList){
					if (e.key === 'Escape') {
						this.toggleNodeList = false;
					}
					return;
				}

				// el-dialog or el-message-box element is open
				if (window.document.body.classList.contains('el-popup-parent--hidden')) {
					return;
				}

				if (e.key === 'Escape') {
					this.$store.commit('updateNodeCreatorState', false);
					if (this.activeNode) {
						this.$externalHooks().run('dataDisplay.nodeEditingFinished');
						this.$store.commit('setActiveNode', null);
					}

					return;
				}

				// node modal is open
				if (this.activeNode) {
					return;
				}

				if (e.key === 'd') {
					this.callDebounced('deactivateSelectedNode', {debounceTime: 350});

				} else if (e.key === 'Delete' || e.key === 'Backspace') {
					// Free users are not allowed to delete nodes
					if (!this.isImbracePaidUser) return;

					e.stopPropagation();
					e.preventDefault();

					this.callDebounced('deleteSelectedNodes', {debounceTime: 500});

				} else if (e.key === 'Tab') {
					this.$store.commit('updateNodeCreatorState', !this.toggleNodeCreator && !this.isReadOnly);
					this.$externalHooks().run('nodeView.createNodeActiveChanged', {source: 'tab', createNodeActive: this.toggleNodeCreator});
					this.$telemetry.trackNodesPanel('nodeView.createNodeActiveChanged', {
						source: 'tab',
						workflow_id: this.$store.getters.workflowId,
						createNodeActive: this.toggleNodeCreator,
					});

				} else if (e.key === this.controlKeyCode) {
					this.ctrlKeyPressed = true;
					// } else if (e.key === 'F2' && !this.isReadOnly) {
					// 	const lastSelectedNode = this.lastSelectedNode;
					// 	if (lastSelectedNode !== null && lastSelectedNode.type !== STICKY_NODE_TYPE) {
					// 		this.callDebounced('renameNodePrompt', { debounceTime: 1500 }, lastSelectedNode.parameters.icsTitle);
					// 	}
				} else if ((e.key === '=' || e.key === '+') && !this.isCtrlKeyPressed(e)) {
					this.zoomIn();
				} else if ((e.key === '_' || e.key === '-') && !this.isCtrlKeyPressed(e)) {
					this.zoomOut();
				} else if ((e.key === '0') && !this.isCtrlKeyPressed(e)) {
					this.resetZoom();
				} else if ((e.key === '1') && !this.isCtrlKeyPressed(e)) {
					this.zoomToFit();
				} else if ((e.key === 'a') && (this.isCtrlKeyPressed(e) === true)) {
					// Select all nodes
					e.stopPropagation();
					e.preventDefault();

					this.callDebounced('selectAllNodes', {debounceTime: 1000});
				} else if ((e.key === 'c') && (this.isCtrlKeyPressed(e) === true)) {
					this.callDebounced('copySelectedNodes', {debounceTime: 1000});
				} else if ((e.key === 'x') && (this.isCtrlKeyPressed(e) === true)) {
					// Free users are not allowed to cut(delete) nodes
					if (!this.isImbracePaidUser) return;
					
					// Cut nodes
					e.stopPropagation();
					e.preventDefault();

					this.callDebounced('cutSelectedNodes', {debounceTime: 1000});
				} else if (e.key === 'o' && this.isCtrlKeyPressed(e) === true) {
					// Open workflow dialog
					e.stopPropagation();
					e.preventDefault();
					if (this.isDemo || this.isPreview) {
						return;
					}

					this.$store.dispatch('ui/openModal', WORKFLOW_OPEN_MODAL_KEY);
				} else if (e.key === 'n' && this.isCtrlKeyPressed(e) === true && e.altKey === true) {
					// Create a new workflow
					e.stopPropagation();
					e.preventDefault();
					if (this.isDemo || this.isPreview) {
						return;
					}

					if (this.$router.currentRoute.name === VIEWS.NEW_WORKFLOW) {
						this.$root.$emit('newWorkflow');
					} else {
						this.$router.push({name: VIEWS.NEW_WORKFLOW});
					}

					this.$showMessage({
						title: this.$locale.baseText('nodeView.showMessage.keyDown.title'),
						type: 'success',
					});
				} else if ((e.key === 's') && (this.isCtrlKeyPressed(e) === true)) {
					// Save workflow
					e.stopPropagation();
					e.preventDefault();

					if (this.isWorkflowActive) {
						return;
					}

					if (this.isReadOnly) {
						return;
					}

					this.callDebounced('onSaveKeyboardShortcut', {debounceTime: 1000});
				} else if (e.key === 'Enter') {
					// Activate the last selected node
					const lastSelectedNode = this.lastSelectedNode;

					if (lastSelectedNode !== null) {
						if (lastSelectedNode.type === STICKY_NODE_TYPE && this.isReadOnly) {
							return;
						}
						this.$store.commit('setActiveNode', lastSelectedNode.name);
					}
				} else if (e.key === 'ArrowRight' && e.shiftKey === true) {
					// Select all downstream nodes
					e.stopPropagation();
					e.preventDefault();

					this.callDebounced('selectDownstreamNodes', {debounceTime: 1000});
				} else if (e.key === 'ArrowRight') {
					// Set child node active
					const lastSelectedNode = this.lastSelectedNode;
					if (lastSelectedNode === null) {
						return;
					}

					const connections = this.$store.getters.outgoingConnectionsByNodeName(lastSelectedNode.name);

					if (connections.main === undefined || connections.main.length === 0) {
						return;
					}

					this.callDebounced('nodeSelectedByName', {debounceTime: 100}, connections.main[0][0].node, false, true);
				} else if (e.key === 'ArrowLeft' && e.shiftKey === true) {
					// Select all downstream nodes
					e.stopPropagation();
					e.preventDefault();

					this.callDebounced('selectUpstreamNodes', {debounceTime: 1000});
				} else if (e.key === 'ArrowLeft') {
					// Set parent node active
					const lastSelectedNode = this.lastSelectedNode;
					if (lastSelectedNode === null) {
						return;
					}

					const workflow = this.getWorkflow();

					if (!workflow.connectionsByDestinationNode.hasOwnProperty(lastSelectedNode.name)) {
						return;
					}

					const connections = workflow.connectionsByDestinationNode[lastSelectedNode.name];

					if (connections.main === undefined || connections.main.length === 0) {
						return;
					}

					this.callDebounced('nodeSelectedByName', {debounceTime: 100}, connections.main[0][0].node, false, true);
				} else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
					// Set sibling node as active

					// Check first if it has a parent node
					const lastSelectedNode = this.lastSelectedNode;
					if (lastSelectedNode === null) {
						return;
					}

					const workflow = this.getWorkflow();

					if (!workflow.connectionsByDestinationNode.hasOwnProperty(lastSelectedNode.name)) {
						return;
					}

					const connections = workflow.connectionsByDestinationNode[lastSelectedNode.name];

					if (connections.main === undefined || connections.main.length === 0) {
						return;
					}

					const parentNode = connections.main[0][0].node;
					const connectionsParent = this.$store.getters.outgoingConnectionsByNodeName(parentNode);

					if (connectionsParent.main === undefined || connectionsParent.main.length === 0) {
						return;
					}

					// Get all the sibling nodes and their x positions to know which one to set active
					let siblingNode: INodeUi;
					let lastCheckedNodePosition = e.key === 'ArrowUp' ? -99999999 : 99999999;
					let nextSelectNode: string | null = null;
					for (const ouputConnections of connectionsParent.main) {
						for (const ouputConnection of ouputConnections) {
							if (ouputConnection.node === lastSelectedNode.name) {
								// Ignore current node
								continue;
							}
							siblingNode = this.$store.getters.getNodeByName(ouputConnection.node);

							if (e.key === 'ArrowUp') {
								// Get the next node on the left
								if (siblingNode.position[1] <= lastSelectedNode.position[1] && siblingNode.position[1] > lastCheckedNodePosition) {
									nextSelectNode = siblingNode.name;
									lastCheckedNodePosition = siblingNode.position[1];
								}
							} else {
								// Get the next node on the right
								if (siblingNode.position[1] >= lastSelectedNode.position[1] && siblingNode.position[1] < lastCheckedNodePosition) {
									nextSelectNode = siblingNode.name;
									lastCheckedNodePosition = siblingNode.position[1];
								}
							}
						}
					}

					if (nextSelectNode !== null) {
						this.callDebounced('nodeSelectedByName', {debounceTime: 100}, nextSelectNode, false, true);
					}
				}
			},

			deactivateSelectedNode() {
				if (this.editAllowedCheck() === false) {
					return;
				}
				this.disableNodes(this.$store.getters.getSelectedNodes);
			},

			deleteSelectedNodes() {
				// Copy "selectedNodes" as the nodes get deleted out of selection
				// when they get deleted and if we would use original it would mess
				// with the index and would so not delete all nodes
				const nodesToDelete: string[] = this.$store.getters.getSelectedNodes.map((node: INodeUi) => {
					return node.name;
				});
				nodesToDelete.forEach((nodeName: string) => {
					this.removeNode(nodeName);
				});
			},

			selectAllNodes() {
				this.nodes.forEach((node) => {
					this.nodeSelectedByName(node.name);
				});
			},

			selectUpstreamNodes() {
				const lastSelectedNode = this.lastSelectedNode;
				if (lastSelectedNode === null) {
					return;
				}

				this.deselectAllNodes();

				// Get all upstream nodes and select them
				const workflow = this.getWorkflow();
				for (const nodeName of workflow.getParentNodes(lastSelectedNode.name)) {
					this.nodeSelectedByName(nodeName);
				}

				// At the end select the previously selected node again
				this.nodeSelectedByName(lastSelectedNode.name);
			},
			selectDownstreamNodes() {
				const lastSelectedNode = this.lastSelectedNode;
				if (lastSelectedNode === null) {
					return;
				}

				this.deselectAllNodes();

				// Get all downstream nodes and select them
				const workflow = this.getWorkflow();
				for (const nodeName of workflow.getChildNodes(lastSelectedNode.name)) {
					this.nodeSelectedByName(nodeName);
				}

				// At the end select the previously selected node again
				this.nodeSelectedByName(lastSelectedNode.name);
			},

			pushDownstreamNodes(sourceNodeName: string, margin: number) {
				const sourceNode = this.$store.getters.nodesByName[sourceNodeName];
				const workflow = this.getWorkflow();
				const childNodes = workflow.getChildNodes(sourceNodeName);
				for (const nodeName of childNodes) {
					const node = this.$store.getters.nodesByName[nodeName] as INodeUi;
					if (node.position[0] < sourceNode.position[0]) {
						continue;
					}

					const updateInformation = {
						name: nodeName,
						properties: {
							position: [node.position[0] + margin, node.position[1]],
						},
					};

					this.$store.commit('updateNodeProperties', updateInformation);
					this.onNodeMoved(node);
				}
			},

			cutSelectedNodes() {
				const deleteCopiedNodes = !this.isReadOnly;
				this.copySelectedNodes(deleteCopiedNodes);
				if (deleteCopiedNodes) {
					this.deleteSelectedNodes();
				}
			},

			copySelectedNodes(isCut: boolean) {
				this.getSelectedNodesToSave().then((data) => {
					const nodeData = JSON.stringify(data, null, 2);
					this.copyToClipboard(nodeData);
					if (data.nodes.length > 0) {
						if (!isCut) {
							this.$showMessage({
								title: 'Copied!',
								message: '',
								type: 'success',
							});
						}
						this.$telemetry.track('User copied nodes', {
							node_types: data.nodes.map((node) => node.type),
							workflow_id: this.$store.getters.workflowId,
						});
					}
				});
			},

			resetZoom() {
				const {scale, offset} = CanvasHelpers.scaleReset({
					scale: this.nodeViewScale,
					offset: this.$store.getters.getNodeViewOffsetPosition,
				});

				this.setZoomLevel(scale);
				this.$store.commit('setNodeViewOffsetPosition', {newOffset: offset});
			},

			zoomIn() {
				const {scale, offset: [xOffset, yOffset]} = CanvasHelpers.scaleBigger({
					scale: this.nodeViewScale,
					offset: this.$store.getters.getNodeViewOffsetPosition,
				});

				this.setZoomLevel(scale);
				this.$store.commit('setNodeViewOffsetPosition', {newOffset: [xOffset, yOffset]});
			},

			zoomOut() {
				const {scale, offset: [xOffset, yOffset]} = CanvasHelpers.scaleSmaller({
					scale: this.nodeViewScale,
					offset: this.$store.getters.getNodeViewOffsetPosition,
				});

				this.setZoomLevel(scale);
				this.$store.commit('setNodeViewOffsetPosition', {newOffset: [xOffset, yOffset]});
			},

			openList() {
				this.toggleNodeList = true;
			},

			setZoomLevel(zoomLevel: number) {
				this.nodeViewScale = zoomLevel; // important for background
				const element = this.instance.getContainer() as HTMLElement;
				if (!element) {
					return;
				}

				// https://docs.jsplumbtoolkit.com/community/current/articles/zooming.html
				const prependProperties = ['webkit', 'moz', 'ms', 'o'];
				const scaleString = 'scale(' + zoomLevel + ')';

				for (let i = 0; i < prependProperties.length; i++) {
					// @ts-ignore
					element.style[prependProperties[i] + 'Transform'] = scaleString;
				}
				element.style['transform'] = scaleString;

				// @ts-ignore
				this.instance.setZoom(zoomLevel);
			},

			zoomToFit() {
				// Some nodes might be hide from canvas, ex 'start' node in channel workflow.
				const nodes = this.displayNodes;
				// const nodes = this.$store.getters.allNodes as INodeUi[];

				if (nodes.length === 0) { // some unknown workflow executions
					return;
				}

				const {zoomLevel, offset} = CanvasHelpers.getZoomToFit(nodes, !this.isDemo && !this.isPreview);

				this.setZoomLevel(zoomLevel);
				this.$store.commit('setNodeViewOffsetPosition', {newOffset: offset});
			},

			fullscreen() {
				// need to interact with dashboard to pop-up a modal
				if (this.parentOrigin) {
					window.parent.postMessage({
						type: "setFullscreen",
						id: this.$store.getters.workflowId,
						name: this.$store.getters.workflowName,
					}, this.parentOrigin);
				}
			},

			async stopExecution() {
				const executionId = this.$store.getters.activeExecutionId;
				if (executionId === null) {
					return;
				}

				try {
					this.stopExecutionInProgress = true;
					await this.restApi().stopCurrentExecution(executionId);
					this.$showMessage({
						title: this.$locale.baseText('nodeView.showMessage.stopExecutionTry.title'),
						type: 'success',
					});
				} catch (error) {
					// Execution stop might fail when the execution has already finished. Let's treat this here.
					const execution = await this.restApi().getExecution(executionId);
					if (execution.finished) {
						const executedData = {
							data: execution.data,
							finished: execution.finished,
							mode: execution.mode,
							startedAt: execution.startedAt,
							stoppedAt: execution.stoppedAt,
						} as IRun;
						const pushData = {
							data: executedData,
							executionId,
							retryOf: execution.retryOf,
						} as IPushDataExecutionFinished;
						this.$store.commit('finishActiveExecution', pushData);
						this.$titleSet(execution.workflowData.name, 'IDLE');
						this.$store.commit('setExecutingNode', null);
						this.$store.commit('setWorkflowExecutionData', executedData);
						this.$store.commit('removeActiveAction', 'workflowRunning');
						this.$showMessage({
							title: this.$locale.baseText('nodeView.showMessage.stopExecutionCatch.title'),
							message: this.$locale.baseText('nodeView.showMessage.stopExecutionCatch.message'),
							type: 'success',
						});
					} else {
						this.$showError(
							error,
							this.$locale.baseText('nodeView.showError.stopExecution.title'),
						);
					}
				}
				this.stopExecutionInProgress = false;
			},

			async stopWaitingForWebhook() {
				try {
					await this.restApi().removeTestWebhook(this.$store.getters.workflowId);
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.stopWaitingForWebhook.title'),
					);
					return;
				}

				this.$showMessage({
					title: this.$locale.baseText('nodeView.showMessage.stopWaitingForWebhook.title'),
					type: 'success',
				});
			},

			/**
			 * This method gets called when data got pasted into the window
			 */
			async receivedCopyPasteData(plainTextData: string): Promise<void> {
				let workflowData: IWorkflowDataUpdate | undefined;

				// Check if it is an URL which could contain workflow data
				if (plainTextData.match(/^http[s]?:\/\/.*\.json$/i)) {
					// Pasted data points to a possible workflow JSON file

					if (this.editAllowedCheck() === false) {
						return;
					}

					const importConfirm = await this.confirmMessage(
						this.$locale.baseText(
							'nodeView.confirmMessage.receivedCopyPasteData.message',
							{interpolate: {plainTextData}},
						),
						this.$locale.baseText('nodeView.confirmMessage.receivedCopyPasteData.headline'),
						'warning',
						this.$locale.baseText('nodeView.confirmMessage.receivedCopyPasteData.confirmButtonText'),
						this.$locale.baseText('nodeView.confirmMessage.receivedCopyPasteData.cancelButtonText'),
					);

					if (importConfirm === false) {
						return;
					}

					workflowData = await this.getWorkflowDataFromUrl(plainTextData);
					if (workflowData === undefined) {
						return;
					}
				} else {
					// Pasted data is is possible workflow data
					try {
						// Check first if it is valid JSON
						workflowData = JSON.parse(plainTextData);

						if (this.editAllowedCheck() === false) {
							return;
						}
					} catch (e) {
						// Is no valid JSON so ignore
						return;
					}
				}

				this.$telemetry.track('User pasted nodes', {
					workflow_id: this.$store.getters.workflowId,
				});

				return this.importWorkflowData(workflowData!);
			},

			// Returns the workflow data from a given URL. If no data gets found or
			// data is invalid it returns undefined and displays an error message by itself.
			async getWorkflowDataFromUrl(url: string): Promise<IWorkflowDataUpdate | undefined> {
				let workflowData: IWorkflowDataUpdate;

				this.startLoading();
				try {
					workflowData = await this.restApi().getWorkflowFromUrl(url);
				} catch (error) {
					this.stopLoading();
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.getWorkflowDataFromUrl.title'),
					);
					return;
				}
				this.stopLoading();

				this.$telemetry.track('User imported workflow', {source: 'url', workflow_id: this.$store.getters.workflowId});

				return workflowData;
			},

			// Imports the given workflow data into the current workflow
			async importWorkflowData(workflowData: IWorkflowDataUpdate): Promise<void> {
				// If it is JSON check if it looks on the first look like data we can use
				if (
					!workflowData.hasOwnProperty('nodes') ||
					!workflowData.hasOwnProperty('connections')
				) {
					return;
				}

				// Check if the given nodes are valid in this workflow:
				// * "Start" node
				// * Node style version not match
				// * Starting flow
				// * Workflow type not match
				// * Channel type not match
				// * maxNodes limitation @addNodesToWorkflow

				// "Start" node
				let validNodes = workflowData.nodes!.filter((node) => node.type !== START_NODE_TYPE);
				let errorMessage = '';
				let isNodeVersionMatch = true;

				// Node style version not match	
				if (this.nodeStyleVersion === 2) {
					isNodeVersionMatch = validNodes.some((node) => node.nodeStyleVersion === 2);
				} else {
					isNodeVersionMatch = validNodes.some((node) => node.nodeStyleVersion !== 2);
				}
				if(!isNodeVersionMatch) {
					errorMessage = this.$locale.baseText('nodeView.showMessage.invalidNode.message.nodeStyleVersionNotMatch');
					await this.showInvalidNodeError(errorMessage);
					return;
				}

				// Starting flow
				const includeStartingFlow = validNodes!.filter((node) => this.startingFlow.includes(node.type));
				if (includeStartingFlow.length > 0) {
					validNodes = validNodes!.filter((node) => !includeStartingFlow.includes(node));

					errorMessage = this.$locale.baseText('nodeView.showMessage.invalidNode.message.startingFlow');
					await this.showInvalidNodeError(errorMessage);
				}

				// Workflow type not match
				const invalidNodesInCurrentWorkflowType = validNodes!.filter((node) => !this.isValidInCurrentWorkflowType(node.type));
				if (invalidNodesInCurrentWorkflowType.length > 0) {
					validNodes = validNodes!.filter((node) => !invalidNodesInCurrentWorkflowType.includes(node));

					const nodeTypes: string[] = [];
					invalidNodesInCurrentWorkflowType.forEach((node) => {
						if (!nodeTypes.includes(node.type)) {
							nodeTypes.push(node.type);
						}
					});

					let nameString = nodeTypes.map((nodeType) => {
						return this.allNodeTypes.find((node: INode) => node.name === nodeType).displayName;
					}).join(', ');

					errorMessage = this.$locale.baseText(
						'nodeView.showMessage.invalidNode.message.wrongWorkflowType',
						{
							interpolate: {
								nodeNames: nameString,
								verbToBe: nameString.includes(', ') ? 'are' : 'is',
							},
						},
					);
					await this.showInvalidNodeError(errorMessage);
				};

				// Channel type not match
				const invalidNodesInCurrentChannelType = validNodes!.filter((node) => !this.isValidInCurrentChannelType(node.type));
				if (invalidNodesInCurrentChannelType.length > 0) {
					validNodes = validNodes!.filter((node) => !invalidNodesInCurrentChannelType.includes(node));

					const nodeTypes: string[] = [];
					invalidNodesInCurrentChannelType.forEach((node) => {
						if (!nodeTypes.includes(node.type)) {
							nodeTypes.push(node.type);
						}
					});

					let nameString = nodeTypes.map((nodeType) => {
						return this.allNodeTypes.find((node: INode) => node.name === nodeType).displayName;
					}).join(', ');

					errorMessage = this.$locale.baseText(
						'nodeView.showMessage.invalidNode.message.wrongChannelType',
						{interpolate: {
							nodeNames: nameString,
							verbToBe: nameString.includes(', ') ? 'are' : 'is',
						}},
					);
					await this.showInvalidNodeError(errorMessage);
				};

				workflowData.nodes = validNodes;


				try {
					// By default we automatically deselect all the currently
					// selected nodes and select the new ones
					this.deselectAllNodes();

					// Fix the node position as it could be totally offscreen
					// and the pasted nodes would so not be directly visible to
					// the user
					this.updateNodePositions(workflowData, CanvasHelpers.getNewNodePosition(this.nodes, this.lastClickPosition));

					const data = await this.addNodesToWorkflow(workflowData);

					setTimeout(() => {
						data.nodes!.forEach((node: INodeUi) => {
							this.nodeSelectedByName(node.name);
						});
					});
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.importWorkflowData.title'),
					);
				}
			},

			closeNodeCreator() {
				this.$store.commit('updateNodeCreatorState', false);
			},

			closeNodeList() {
				this.toggleNodeList = false;
			},

			addStickyNote() {
				if (document.activeElement) {
					(document.activeElement as HTMLElement).blur();
				}
				const offset: [number, number] = [...(this.$store.getters.getNodeViewOffsetPosition as [number, number])];
				const position = CanvasHelpers.getMidCanvasPosition(this.nodeViewScale, offset);
				position[0] -= DEFAULT_STICKY_WIDTH / 2;
				position[1] -= DEFAULT_STICKY_HEIGHT / 2;
				this.addNodeButton(STICKY_NODE_TYPE, {
					position,
				});
			},

			nodeTypeSelected(nodeTypeName: string) {
				this.addNodeButton(nodeTypeName);
				// this.$store.commit('updateNodeCreatorState', false);
			},

			onDragOver(event: DragEvent) {
				event.preventDefault();
			},

			onDrop(event: DragEvent) {
				if (!event.dataTransfer) {
					return;
				}

				const nodeTypeName = event.dataTransfer.getData('nodeTypeName');
				if (nodeTypeName) {
					const mousePosition = this.getMousePositionWithinNodeView(event);

					this.addNodeButton(nodeTypeName, {
						position: [mousePosition[0] - CanvasHelpers.NODE_SIZE / 2, mousePosition[1] - CanvasHelpers.NODE_SIZE / 2],
						dragAndDrop: true,
					});
					// this.$store.commit('updateNodeCreatorState', false);
				}
			},

			nodeDeselectedByName(nodeName: string) {
				const node = this.$store.getters.getNodeByName(nodeName);
				if (node) {
					this.nodeDeselected(node);
				}
			},

			nodeSelectedByName(nodeName: string, setActive = false, deselectAllOthers?: boolean) {
				if (deselectAllOthers === true) {
					this.deselectAllNodes();
				}

				const node = this.$store.getters.getNodeByName(nodeName);
				if (node) {
					this.nodeSelected(node);
				}
				this.$store.commit('setLastSelectedNode', node.name);
				this.$store.commit('setLastSelectedNodeOutputIndex', null);
				this.lastSelectedConnection = null;
				this.newNodeInsertPosition = null;

				if (setActive === true) {
					// Skip new connector dataDisplay popup
					const isAddingNewNode = this.$store.getters['imbrace/isAddingNewNode'];
					if (isAddingNewNode) {
						// Get and set preset workflow data when add a preset connector from creator
						const isAddingPreset = this.$store.getters['imbrace/isAddingPreset'];
						if (isAddingPreset) {
							const presetData = this.$store.getters['imbrace/presetData'];
							const nodeType = this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;

							const updateInformation = {
								name: node.name,
								value: {
									...node.parameters,
									workflowId: presetData.presetId,
									icsTitle: presetData.presetName,
								},
							};
							this.$store.commit('setNodeParameters', updateInformation);

							// Prevent issue
							this.updateNodeParameterIssues(node, nodeType);

							// Reset preset status
							this.$store.commit('imbrace/setPresetData', {});
							this.$store.commit('imbrace/addNewPreset', false);

							// TODO: update subTitle in Node component in setSubtitle method
							this.$externalHooks().run('dataDisplay.nodeTypeChanged', {nodeSubtitle: this.getNodeSubtitle(node, nodeType, this.getWorkflow())});
						}

						this.$store.commit('imbrace/addNewNode', false);
					} else {
						this.$store.commit('setActiveNode', node.name);
					}
				}
			},
			showMaxNodeTypeError(nodeTypeData: INodeTypeDescription) {
				let maxNodes= nodeTypeData.maxNodes;
				let nodeTypeDataDisplayName= nodeTypeData.displayName;

				if (this.startingFlow.includes(nodeTypeData.name)) {
					// For 'Webhook' node, we can't set maxNodes and change displayName,
					// cause only channel WF has these special settings
					if (nodeTypeData.name === WEBHOOK_NODE_TYPE) {
						maxNodes = 1;
						nodeTypeDataDisplayName = this.$store.getters['imbrace/starterNodeTitle'];
					}
				}

				this.$showMessage({
					title: this.$locale.baseText('nodeView.showMessage.showMaxNodeTypeError.title'),
					message: this.$locale.baseText('nodeView.showMessage.showMaxNodeTypeError.message',
						{
							adjustToNumber: maxNodes,
							interpolate: {nodeTypeDataDisplayName},
						},
					),
					type: 'error',
					duration: 0,
				});
			},
			showInvalidNodeError(errorMessage: string) {
				this.$showMessage({
					title: this.$locale.baseText('nodeView.showMessage.invalidNode.title'),
					message: errorMessage,
					type: 'error',
					duration: 0,
				});
			},
			async injectNode(nodeTypeName: string, options: AddNodeOptions = {}) {
				const nodeTypeData: INodeTypeDescription | null = this.$store.getters.nodeType(nodeTypeName);

				if (nodeTypeData === null) {
					this.$showMessage({
						title: this.$locale.baseText('nodeView.showMessage.addNodeButton.title'),
						message: this.$locale.baseText(
							'nodeView.showMessage.addNodeButton.message',
							{interpolate: {nodeTypeName}},
						),
						type: 'error',
					});
					return;
				}

				if (this.verifyLockedConnector(nodeTypeName)) {
					if (this.parentOrigin) {
						window.parent.postMessage({
							type: "lockAction",
						}, this.parentOrigin);
					}
					return;
				}

				if (nodeTypeData.maxNodes !== undefined && this.getNodeTypeCount(nodeTypeName) >= nodeTypeData.maxNodes) {
					this.showMaxNodeTypeError(nodeTypeData);
					return;
				}

				const newNodeData: INodeUi = {
					name: nodeTypeData.defaults.name as string,
					type: nodeTypeData.name,
					typeVersion: Array.isArray(nodeTypeData.version)
						? nodeTypeData.version.slice(-1)[0]
						: nodeTypeData.version,
					position: [0, 0],
					parameters: {},
				};

				// when pulling new connection from node or injecting into a connection
				const lastSelectedNode = this.lastSelectedNode;

				if (options.position) {
					newNodeData.position = CanvasHelpers.getNewNodePosition(this.nodes, options.position);
				} else if (lastSelectedNode) {
					const lastSelectedConnection = this.lastSelectedConnection;
					if (lastSelectedConnection) { // set when injecting into a connection
						const [diffX] = CanvasHelpers.getConnectorLengths(lastSelectedConnection);
						if (diffX <= CanvasHelpers.MAX_X_TO_PUSH_DOWNSTREAM_NODES) {
							this.pushDownstreamNodes(lastSelectedNode.name, CanvasHelpers.PUSH_NODES_OFFSET);
						}
					}

					// set when pulling connections
					if (this.newNodeInsertPosition) {
						newNodeData.position = CanvasHelpers.getNewNodePosition(this.nodes, [
							this.newNodeInsertPosition[0] + CanvasHelpers.GRID_SIZE,
							this.newNodeInsertPosition[1] - CanvasHelpers.NODE_SIZE / 2,
						]);
						this.newNodeInsertPosition = null;
					} else {
						let yOffset = 0;

						if (lastSelectedConnection) {
							const sourceNodeType = this.$store.getters.nodeType(lastSelectedNode.type, lastSelectedNode.typeVersion) as INodeTypeDescription | null;
							const offsets = [[-100, 100], [-140, 0, 140], [-240, -100, 100, 240]];
							if (sourceNodeType && sourceNodeType.outputs.length > 1) {
								const offset = offsets[sourceNodeType.outputs.length - 2];
								const sourceOutputIndex = lastSelectedConnection.__meta ? lastSelectedConnection.__meta.sourceOutputIndex : 0;
								yOffset = offset[sourceOutputIndex];
							}
						}

						// If a node is active then add the new node directly after the current one
						// newNodeData.position = [activeNode.position[0], activeNode.position[1] + 60];
						newNodeData.position = CanvasHelpers.getNewNodePosition(
							this.nodes,
							[lastSelectedNode.position[0] + CanvasHelpers.PUSH_NODES_OFFSET, lastSelectedNode.position[1] + yOffset],
							[100, 0],
						);
					}
				} else {
					// If no node is active find a free spot
					newNodeData.position = CanvasHelpers.getNewNodePosition(this.nodes, this.lastClickPosition);
				}


				// Check if node-name is unique else find one that is
				newNodeData.name = this.getUniqueNodeName({
					originalName: newNodeData.name,
					type: newNodeData.type,
				});

				if (nodeTypeData.webhooks && nodeTypeData.webhooks.length) {
					newNodeData.webhookId = uuidv4();
				}

				await this.addNodes([newNodeData]);

				this.$store.commit('setStateDirty', true);

				if (nodeTypeName === STICKY_NODE_TYPE) {
					this.$telemetry.trackNodesPanel('nodeView.addSticky', { workflow_id: this.$store.getters.workflowId });
				} else {
					this.$externalHooks().run('nodeView.addNodeButton', { nodeTypeName });
					this.$telemetry.trackNodesPanel('nodeView.addNodeButton', {
						node_type: nodeTypeName,
						workflow_id: this.$store.getters.workflowId,
						drag_and_drop: options.dragAndDrop,
					} as IDataObject);
				}

				// Automatically deselect all nodes and select the current one and also active
				// current node
				this.deselectAllNodes();
				setTimeout(() => {
					this.nodeSelectedByName(newNodeData.name, nodeTypeName !== STICKY_NODE_TYPE);
				});

				return newNodeData;
			},
			getConnection(sourceNodeName: string, sourceNodeOutputIndex: number, targetNodeName: string, targetNodeOuputIndex: number): IConnection | undefined {
				const nodeConnections = (this.$store.getters.outgoingConnectionsByNodeName(sourceNodeName) as INodeConnections).main;
				if (nodeConnections) {
					const connections: IConnection[] | null = nodeConnections[sourceNodeOutputIndex];

					if (connections) {
						return connections.find((connection: IConnection) => connection.node === targetNodeName && connection.index === targetNodeOuputIndex);
					}
				}

				return undefined;
			},
			connectTwoNodes(sourceNodeName: string, sourceNodeOutputIndex: number, targetNodeName: string, targetNodeOuputIndex: number) {
				if (this.getConnection(sourceNodeName, sourceNodeOutputIndex, targetNodeName, targetNodeOuputIndex)) {
					return;
				}

				const connectionData = [
					{
						node: sourceNodeName,
						type: 'main',
						index: sourceNodeOutputIndex,
					},
					{
						node: targetNodeName,
						type: 'main',
						index: targetNodeOuputIndex,
					},
				] as [IConnection, IConnection];

				this.__addConnection(connectionData, true);
			},
			async addNodeButton(nodeTypeName: string, options: AddNodeOptions = {}) {
				if (this.editAllowedCheck() === false) {
					return;
				}

				const lastSelectedConnection = this.lastSelectedConnection;
				const lastSelectedNode = this.lastSelectedNode;
				const lastSelectedNodeOutputIndex = this.$store.getters.lastSelectedNodeOutputIndex;

				const newNodeData = await this.injectNode(nodeTypeName, options);
				if (!newNodeData) {
					return;
				}

				const outputIndex = lastSelectedNodeOutputIndex || 0;

				// If a node is last selected then connect between the active and its child ones
				if (lastSelectedNode) {
					await Vue.nextTick();

					if (lastSelectedConnection && lastSelectedConnection.__meta) {
						this.__deleteJSPlumbConnection(lastSelectedConnection);

						const targetNodeName = lastSelectedConnection.__meta.targetNodeName;
						const targetOutputIndex = lastSelectedConnection.__meta.targetOutputIndex;
						this.connectTwoNodes(newNodeData.name, 0, targetNodeName, targetOutputIndex);
					}

					// Connect active node to the newly created one
					this.connectTwoNodes(lastSelectedNode.name, outputIndex, newNodeData.name, 0);
				}
			},
			initNodeView() {
				this.instance.importDefaults({
					Connector: CanvasHelpers.CONNECTOR_FLOWCHART_TYPE,
					Endpoint: ['Dot', {radius: 5}],
					DragOptions: {cursor: 'pointer', zIndex: 5000},
					PaintStyle: CanvasHelpers.CONNECTOR_PAINT_STYLE_DEFAULT,
					HoverPaintStyle: CanvasHelpers.CONNECTOR_PAINT_STYLE_PRIMARY,
					ConnectionOverlays: CanvasHelpers.CONNECTOR_ARROW_OVERLAYS,
					Container: '#node-view',
				});

				const insertNodeAfterSelected = (info: { sourceId: string, index: number, eventSource: string, connection?: Connection }) => {
					// Get the node and set it as active that new nodes
					// which get created get automatically connected
					// to it.
					const sourceNodeName = this.$store.getters.getNodeNameByIndex(info.sourceId.slice(NODE_NAME_PREFIX.length));
					this.$store.commit('setLastSelectedNode', sourceNodeName);
					this.$store.commit('setLastSelectedNodeOutputIndex', info.index);
					this.newNodeInsertPosition = null;

					if (info.connection) {
						this.lastSelectedConnection = info.connection;
					}

					this.openNodeCreator(info.eventSource);
				};

				this.instance.bind('connectionAborted', (connection) => {
					try {
						if (this.dropPrevented) {
							this.dropPrevented = false;
							return;
						}

						if (this.pullConnActiveNodeName) {
							const sourceNodeName = this.$store.getters.getNodeNameByIndex(connection.sourceId.slice(NODE_NAME_PREFIX.length));
							const outputIndex = connection.getParameters().index;

							this.connectTwoNodes(sourceNodeName, outputIndex, this.pullConnActiveNodeName, 0);
							this.pullConnActiveNodeName = null;
							return;
						}

						insertNodeAfterSelected({
							sourceId: connection.sourceId,
							index: connection.getParameters().index,
							eventSource: 'node_connection_drop',
						});
					} catch (e) {
						console.error(e);  // eslint-disable-line no-console
					}
				});

				this.instance.bind('beforeDrop', (info) => {
					try {
						const sourceInfo = info.connection.endpoints[0].getParameters();
						// @ts-ignore
						const targetInfo = info.dropEndpoint.getParameters();

						const sourceNodeName = this.$store.getters.getNodeNameByIndex(sourceInfo.nodeIndex);
						const targetNodeName = this.$store.getters.getNodeNameByIndex(targetInfo.nodeIndex);

						// check for duplicates
						if (this.getConnection(sourceNodeName, sourceInfo.index, targetNodeName, targetInfo.index)) {
							this.dropPrevented = true;
							this.pullConnActiveNodeName = null;
							return false;
						}

						return true;
					} catch (e) {
						console.error(e);  // eslint-disable-line no-console
						return true;
					}
				});

				// only one set of visible actions should be visible at the same time
				let activeConnection: null | Connection = null;

				this.instance.bind('connection', (info: OnConnectionBindInfo) => {
					try {
						const sourceInfo = info.sourceEndpoint.getParameters();
						const targetInfo = info.targetEndpoint.getParameters();

						const sourceNodeName = this.$store.getters.getNodeNameByIndex(sourceInfo.nodeIndex);
						const targetNodeName = this.$store.getters.getNodeNameByIndex(targetInfo.nodeIndex);

						info.connection.__meta = {
							sourceNodeName,
							sourceOutputIndex: sourceInfo.index,
							targetNodeName,
							targetOutputIndex: targetInfo.index,
						};

						CanvasHelpers.resetConnection(info.connection);

						if (this.isReadOnly === false) {
							let exitTimer: NodeJS.Timeout | undefined;
							let enterTimer: NodeJS.Timeout | undefined;
							info.connection.bind('mouseover', (connection: Connection) => {
								try {
									if (exitTimer !== undefined) {
										clearTimeout(exitTimer);
										exitTimer = undefined;
									}

									if (enterTimer) {
										return;
									}

									if (!info.connection || info.connection === activeConnection) {
										return;
									}

									CanvasHelpers.hideConnectionActions(activeConnection);


									enterTimer = setTimeout(() => {
										enterTimer = undefined;
										if (info.connection) {
											activeConnection = info.connection;
											// Free users are not allowed to delete connections
											if (this.isImbracePaidUser) {
												CanvasHelpers.showConectionActions(info.connection);
											}
										}
									}, 150);
								} catch (e) {
									console.error(e); // eslint-disable-line no-console
								}
							});

							info.connection.bind('mouseout', (connection: Connection) => {
								try {
									if (exitTimer) {
										return;
									}

									if (enterTimer) {
										clearTimeout(enterTimer);
										enterTimer = undefined;
									}

									if (!info.connection || activeConnection !== info.connection) {
										return;
									}

									exitTimer = setTimeout(() => {
										exitTimer = undefined;

										if (info.connection && activeConnection === info.connection) {
											CanvasHelpers.hideConnectionActions(activeConnection);
											activeConnection = null;
										}
									}, 500);
								} catch (e) {
									console.error(e); // eslint-disable-line no-console
								}
							});

							CanvasHelpers.addConnectionActionsOverlay(info.connection,
								() => {
									activeConnection = null;
									this.__deleteJSPlumbConnection(info.connection);
								},
								() => {
									setTimeout(() => {
										insertNodeAfterSelected({
											sourceId: info.sourceId,
											index: sourceInfo.index,
											connection: info.connection,
											eventSource: 'node_connection_action',
										});
									}, 150);
								});
						}

						CanvasHelpers.moveBackInputLabelPosition(info.targetEndpoint);

						this.$store.commit('addConnection', {
							connection: [
								{
									node: sourceNodeName,
									type: sourceInfo.type,
									index: sourceInfo.index,
								},
								{
									node: targetNodeName,
									type: targetInfo.type,
									index: targetInfo.index,
								},
							],
							setStateDirty: true,
						});
					} catch (e) {
						console.error(e); // eslint-disable-line no-console
					}
				});

				this.instance.bind('connectionMoved', (info) => {
					try {
						// When a connection gets moved from one node to another it for some reason
						// calls the "connection" event but not the "connectionDetached" one. So we listen
						// additionally to the "connectionMoved" event and then only delete the existing connection.

						CanvasHelpers.resetInputLabelPosition(info.originalTargetEndpoint);

						// @ts-ignore
						const sourceInfo = info.originalSourceEndpoint.getParameters();
						// @ts-ignore
						const targetInfo = info.originalTargetEndpoint.getParameters();

						const connectionInfo = [
							{
								node: this.$store.getters.getNodeNameByIndex(sourceInfo.nodeIndex),
								type: sourceInfo.type,
								index: sourceInfo.index,
							},
							{
								node: this.$store.getters.getNodeNameByIndex(targetInfo.nodeIndex),
								type: targetInfo.type,
								index: targetInfo.index,
							},
						] as [IConnection, IConnection];

						this.__removeConnection(connectionInfo, false);
					} catch (e) {
						console.error(e); // eslint-disable-line no-console
					}
				});

				this.instance.bind('connectionDetached', (info) => {
					try {
						CanvasHelpers.resetInputLabelPosition(info.targetEndpoint);
						info.connection.removeOverlays();
						this.__removeConnectionByConnectionInfo(info, false);

						if (this.pullConnActiveNodeName) { // establish new connection when dragging connection from one node to another
							const sourceNodeName = this.$store.getters.getNodeNameByIndex(info.connection.sourceId.slice(NODE_NAME_PREFIX.length));
							const outputIndex = info.connection.getParameters().index;

							this.connectTwoNodes(sourceNodeName, outputIndex, this.pullConnActiveNodeName, 0);
							this.pullConnActiveNodeName = null;
						}
					} catch (e) {
						console.error(e); // eslint-disable-line no-console
					}
				});

				// @ts-ignore
				this.instance.bind('connectionDrag', (connection: Connection) => {
					try {
						this.pullConnActiveNodeName = null;
						this.pullConnActive = true;
						this.newNodeInsertPosition = null;
						CanvasHelpers.resetConnection(connection);

						const nodes = [...document.querySelectorAll('.node-default')];

						const onMouseMove = (e: MouseEvent | TouchEvent) => {
							if (!connection) {
								return;
							}

							const element = document.querySelector('.jtk-endpoint.dropHover');
							if (element) {
								// @ts-ignore
								CanvasHelpers.showDropConnectionState(connection, element._jsPlumb);
								return;
							}

							const inputMargin = 24;
							const intersecting = nodes.find((element: Element) => {
								const {top, left, right, bottom} = element.getBoundingClientRect();
								const [x, y] = CanvasHelpers.getMousePosition(e);
								if (top <= y && bottom >= y && (left - inputMargin) <= x && right >= x) {
									const nodeName = (element as HTMLElement).dataset['name'] as string;
									const node = this.$store.getters.getNodeByName(nodeName) as INodeUi | null;
									if (node) {
										const nodeType = this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;
										if (nodeType && nodeType.inputs && nodeType.inputs.length === 1) {
											this.pullConnActiveNodeName = node.name;
											const endpoint = this.instance.getEndpoint(this.getInputEndpointUUID(nodeName, 0));

											CanvasHelpers.showDropConnectionState(connection, endpoint);

											return true;
										}
									}
								}

								return false;
							});

							if (!intersecting) {
								CanvasHelpers.showPullConnectionState(connection);
								this.pullConnActiveNodeName = null;
							}
						};

						const onMouseUp = (e: MouseEvent | TouchEvent) => {
							this.pullConnActive = false;
							this.newNodeInsertPosition = this.getMousePositionWithinNodeView(e);
							CanvasHelpers.resetConnectionAfterPull(connection);
							window.removeEventListener('mousemove', onMouseMove);
							window.removeEventListener('mouseup', onMouseUp);
						};

						window.addEventListener('mousemove', onMouseMove);
						window.addEventListener('touchmove', onMouseMove);
						window.addEventListener('mouseup', onMouseUp);
						window.addEventListener('touchend', onMouseMove);
					} catch (e) {
						console.error(e); // eslint-disable-line no-console
					}
				});

				// @ts-ignore
				this.instance.bind(('plusEndpointClick'), (endpoint: Endpoint) => {
					if (endpoint && endpoint.__meta) {
						insertNodeAfterSelected({
							sourceId: endpoint.__meta.nodeId,
							index: endpoint.__meta.index,
							eventSource: 'plus_endpoint',
						});
					}
				});
			},
			async newWorkflow(): Promise<void> {
				await this.resetWorkspace();

				this.checkTypeWhenCreateWorkflow();

				await this.$store.dispatch('workflows/setNewWorkflowName');
				this.$store.commit('setStateDirty', false);

				await this.addNodes([{...CanvasHelpers.DEFAULT_START_NODE}]);

				// Add "Webhook" node when create a new channel automation WF or board automation workflow
				if (this.currentChannelType || this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
					this.addNodeButton(WEBHOOK_NODE_TYPE, {
						position: [CanvasHelpers.DEFAULT_START_POSITION_X, CanvasHelpers.DEFAULT_START_POSITION_Y],
					});
					this.$store.commit('imbrace/addNewNode', true);
				}

				this.nodeSelectedByName(CanvasHelpers.DEFAULT_START_NODE.name, false);

				this.$store.commit('setStateDirty', false);

				this.setZoomLevel(1);
				setTimeout(() => {
					this.$store.commit('setNodeViewOffsetPosition', {newOffset: [0, 0]});
				}, 0);
			},

			async initView(): Promise<void> {
				if (this.$route.params.action === 'workflowSave' && !this.reloadWorkflow) {
					// In case the workflow got saved we do not have to run init
					// as only the route changed but all the needed data is already loaded
					this.$store.commit('setStateDirty', false);
					return Promise.resolve();
				}

				if (this.blankRedirect) {
					this.blankRedirect = false;
				} else if (this.$route.name === VIEWS.TEMPLATE_IMPORT) {
					const templateId = this.$route.params.id;
					await this.openWorkflowTemplate(templateId);
				} else if (this.$route.name === VIEWS.EXECUTION) {
					// Load an execution
					const executionId = this.$route.params.id;
					await this.openExecution(executionId);
				} else if (this.$route.name === VIEWS.IDEA_PREVIEW) {
					const ideaId = this.$route.params.id;
					await this.openIdeaPreview(ideaId);
				} else {

					const result = this.$store.getters.getStateIsDirty;
					if (result && !this.reloadWorkflow) {
						const confirmModal = await this.confirmModal(
							this.$locale.baseText('nodeView.confirmMessage.initView.message'),
							this.$locale.baseText('nodeView.confirmMessage.initView.headline'),
							'warning',
							this.$locale.baseText('nodeView.confirmMessage.initView.confirmButtonText'),
							this.$locale.baseText('nodeView.confirmMessage.initView.cancelButtonText'),
							true,
						);

						if (confirmModal === MODAL_CONFIRMED) {
							const saved = await this.saveCurrentWorkflow();
							if (saved) this.$store.dispatch('settings/fetchPromptsData');
						} else if (confirmModal === MODAL_CLOSE) {
							return Promise.resolve();
						}
					}

					// Load a workflow
					let workflowId = null as string | null;
					if (this.$route.params.name) {
						workflowId = this.$route.params.name;
					}
					if (workflowId !== null) {
						const workflow = await this.restApi().getWorkflow(workflowId);
						if (!workflow) {
							// for new workflow, set the latest node style version
							const nodeStyleVersion = this.determineNodeStyleVersionByWorkflow('newWorkflow');
							this.$store.commit('imbrace/setNodeStyleVersion', nodeStyleVersion);
							this.$router.push({
								name: VIEWS.NEW_WORKFLOW,
							});
							this.$showMessage({
								title: 'Error',
								message: this.$locale.baseText('openWorkflow.workflowNotFoundError'),
								type: 'error',
							});
						} else {
							this.$titleSet(workflow.name, 'IDLE');
							// Open existing workflow
							await this.openWorkflow(workflowId);

							// Whether the workflow ID in the Execution Workflow node exists
							const { nodes } = workflow;
							this.isValidWorkflowIdInNodes(nodes);
						}
					} else {
						// for new workflow, set the latest node style version
						const nodeStyleVersion = this.determineNodeStyleVersionByWorkflow('newWorkflow');
						this.$store.commit('imbrace/setNodeStyleVersion', nodeStyleVersion);
						// Create new workflow
						await this.newWorkflow();
					}
				}

				document.addEventListener('keydown', this.keyDown);
				document.addEventListener('keyup', this.keyUp);

				window.addEventListener("beforeunload", (e) => {
					if (this.isDemo || this.isPreview) {
						return;
					} else if (this.$store.getters.getStateIsDirty === true) {
						const confirmationMessage = this.$locale.baseText('nodeView.itLooksLikeYouHaveBeenEditingSomething');
						(e || window.event).returnValue = confirmationMessage; //Gecko + IE
						return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
					} else {
						this.startLoading(
							this.$locale.baseText('nodeView.redirecting'),
						);

						return;
					}
				});
			},
			getOutputEndpointUUID(nodeName: string, index: number) {
				return CanvasHelpers.getOutputEndpointUUID(this.getNodeIndex(nodeName), index);
			},
			getInputEndpointUUID(nodeName: string, index: number) {
				return CanvasHelpers.getInputEndpointUUID(this.getNodeIndex(nodeName), index);
			},
			__addConnection(connection: [IConnection, IConnection], addVisualConnection = false) {
				if (addVisualConnection === true) {
					const uuid: [string, string] = [
						this.getOutputEndpointUUID(connection[0].node, connection[0].index),
						this.getInputEndpointUUID(connection[1].node, connection[1].index),
					];

					// Create connections in DOM
					// @ts-ignore
					this.instance.connect({
						uuids: uuid,
						detachable: !this.isReadOnly,
					});
				} else {
					const connectionProperties = {connection, setStateDirty: false};
					// When nodes get connected it gets saved automatically to the storage
					// so if we do not connect we have to save the connection manually
					this.$store.commit('addConnection', connectionProperties);
				}
			},
			__removeConnection(connection: [IConnection, IConnection], removeVisualConnection = false) {
				if (removeVisualConnection === true) {
					// @ts-ignore
					const connections = this.instance.getConnections({
						source: NODE_NAME_PREFIX + this.getNodeIndex(connection[0].node),
						target: NODE_NAME_PREFIX + this.getNodeIndex(connection[1].node),
					});

					// @ts-ignore
					connections.forEach((connectionInstance) => {
						this.__deleteJSPlumbConnection(connectionInstance);
					});
				}

				this.$store.commit('removeConnection', {connection});
			},
			__deleteJSPlumbConnection(connection: Connection) {
				// Make sure to remove the overlay else after the second move
				// it visibly stays behind free floating without a connection.
				connection.removeOverlays();

				const sourceEndpoint = connection.endpoints && connection.endpoints[0];
				this.pullConnActiveNodeName = null; // prevent new connections when connectionDetached is triggered
				this.instance.deleteConnection(connection); // on delete, triggers connectionDetached event which applies mutation to store
				if (sourceEndpoint) {
					const endpoints = this.instance.getEndpoints(sourceEndpoint.elementId);
					endpoints.forEach((endpoint: Endpoint) => endpoint.repaint()); // repaint both circle and plus endpoint
				}
			},
			__removeConnectionByConnectionInfo(info: OnConnectionBindInfo, removeVisualConnection = false) {
				// @ts-ignore
				const sourceInfo = info.sourceEndpoint.getParameters();
				// @ts-ignore
				const targetInfo = info.targetEndpoint.getParameters();

				const connectionInfo = [
					{
						node: this.$store.getters.getNodeNameByIndex(sourceInfo.nodeIndex),
						type: sourceInfo.type,
						index: sourceInfo.index,
					},
					{
						node: this.$store.getters.getNodeNameByIndex(targetInfo.nodeIndex),
						type: targetInfo.type,
						index: targetInfo.index,
					},
				] as [IConnection, IConnection];

				if (removeVisualConnection) {
					this.__deleteJSPlumbConnection(info.connection);
				}

				this.$store.commit('removeConnection', {connection: connectionInfo});
			},
			async duplicateNode(nodeName: string) {
				if (this.editAllowedCheck() === false) {
					return;
				}

				const node = this.$store.getters.getNodeByName(nodeName);

				if (this.verifyLockedConnector(node.type)) {
					if (this.parentOrigin) {
						window.parent.postMessage({
							type: "lockAction",
						}, this.parentOrigin);
					}
					return;
				}

				const nodeTypeData: INodeTypeDescription | null = this.$store.getters.nodeType(node.type, node.typeVersion);
				if ((nodeTypeData && nodeTypeData.maxNodes !== undefined && this.getNodeTypeCount(node.type) >= nodeTypeData.maxNodes) || (nodeTypeData && this.startingFlow.includes(nodeTypeData.name))) {
					this.showMaxNodeTypeError(nodeTypeData);
					return;
				}

				// Deep copy the data so that data on lower levels of the node-properties do
				// not share objects
				const newNodeData = JSON.parse(JSON.stringify(this.getNodeDataToSave(node)));

				// Check if node-name is unique else find one that is
				newNodeData.name = this.getUniqueNodeName({
					originalName: newNodeData.name,
					type: newNodeData.type,
				});

				newNodeData.position = CanvasHelpers.getNewNodePosition(
					this.nodes,
					[node.position[0], node.position[1] + 140],
					[0, 140],
				);

				if (newNodeData.webhookId) {
					// Make sure that the node gets a new unique webhook-ID
					newNodeData.webhookId = uuidv4();
				}

				await this.addNodes([newNodeData]);

				this.$store.commit('setStateDirty', true);

				// Automatically deselect all nodes and select the current one and also active
				// current node
				this.deselectAllNodes();
				setTimeout(() => {
					this.nodeSelectedByName(newNodeData.name, false);
				});

				this.$telemetry.track('User duplicated node', {node_type: node.type, workflow_id: this.$store.getters.workflowId});
			},
			getJSPlumbConnection(sourceNodeName: string, sourceOutputIndex: number, targetNodeName: string, targetInputIndex: number): Connection | undefined {
				const sourceIndex = this.getNodeIndex(sourceNodeName);
				const sourceId = `${NODE_NAME_PREFIX}${sourceIndex}`;

				const targetIndex = this.getNodeIndex(targetNodeName);
				const targetId = `${NODE_NAME_PREFIX}${targetIndex}`;

				const sourceEndpoint = CanvasHelpers.getOutputEndpointUUID(sourceIndex, sourceOutputIndex);
				const targetEndpoint = CanvasHelpers.getInputEndpointUUID(targetIndex, targetInputIndex);

				// @ts-ignore
				const connections = this.instance.getConnections({
					source: sourceId,
					target: targetId,
				}) as Connection[];

				return connections.find((connection: Connection) => {
					const uuids = connection.getUuids();
					return uuids[0] === sourceEndpoint && uuids[1] === targetEndpoint;
				});
			},
			getJSPlumbEndpoints(nodeName: string): Endpoint[] {
				const nodeIndex = this.getNodeIndex(nodeName);
				const nodeId = `${NODE_NAME_PREFIX}${nodeIndex}`;
				return this.instance.getEndpoints(nodeId);
			},
			getPlusEndpoint(nodeName: string, outputIndex: number): Endpoint | undefined {
				const endpoints = this.getJSPlumbEndpoints(nodeName);
				// @ts-ignore
				return endpoints.find((endpoint: Endpoint) => endpoint.type === 'N8nPlus' && endpoint.__meta && endpoint.__meta.index === outputIndex);
			},
			getIncomingOutgoingConnections(nodeName: string): { incoming: Connection[], outgoing: Connection[] } {
				const name = `${NODE_NAME_PREFIX}${this.$store.getters.getNodeIndex(nodeName)}`;
				// @ts-ignore
				const outgoing = this.instance.getConnections({
					source: name,
				}) as Connection[];

				// @ts-ignore
				const incoming = this.instance.getConnections({
					target: name,
				}) as Connection[];

				return {
					incoming,
					outgoing,
				};
			},
			onNodeMoved(node: INodeUi) {
				const {incoming, outgoing} = this.getIncomingOutgoingConnections(node.name);

				[...incoming, ...outgoing].forEach((connection: Connection) => {
					CanvasHelpers.showOrHideMidpointArrow(connection);
					CanvasHelpers.showOrHideItemsLabel(connection);
				});
			},
			onNodeRun({name, data, waiting}: { name: string, data: ITaskData[] | null, waiting: boolean }) {
				const sourceNodeName = name;
				const sourceIndex = this.$store.getters.getNodeIndex(sourceNodeName);
				const sourceId = `${NODE_NAME_PREFIX}${sourceIndex}`;

				if (data === null || data.length === 0 || waiting) {
					// @ts-ignore
					const outgoing = this.instance.getConnections({
						source: sourceId,
					}) as Connection[];

					outgoing.forEach((connection: Connection) => {
						CanvasHelpers.resetConnection(connection);
					});
					const endpoints = this.getJSPlumbEndpoints(sourceNodeName);
					endpoints.forEach((endpoint: Endpoint) => {
						// @ts-ignore
						if (endpoint.type === 'N8nPlus') {
							(endpoint.endpoint as N8nPlusEndpoint).clearSuccessOutput();
						}
					});

					return;
				}

				const nodeConnections = (this.$store.getters.outgoingConnectionsByNodeName(sourceNodeName) as INodeConnections).main;
				const outputMap = CanvasHelpers.getOutputSummary(data, nodeConnections || []);

				Object.keys(outputMap).forEach((sourceOutputIndex: string) => {
					Object.keys(outputMap[sourceOutputIndex]).forEach((targetNodeName: string) => {
						Object.keys(outputMap[sourceOutputIndex][targetNodeName]).forEach((targetInputIndex: string) => {
							if (targetNodeName) {
								const connection = this.getJSPlumbConnection(sourceNodeName, parseInt(sourceOutputIndex, 10), targetNodeName, parseInt(targetInputIndex, 10));

								if (connection) {
									const output = outputMap[sourceOutputIndex][targetNodeName][targetInputIndex];
									if (!output || !output.total) {
										CanvasHelpers.resetConnection(connection);
									} else {
										CanvasHelpers.addConnectionOutputSuccess(connection, output);
									}
								}
							}

							const endpoint = this.getPlusEndpoint(sourceNodeName, parseInt(sourceOutputIndex, 10));
							if (endpoint && endpoint.endpoint) {
								const output = outputMap[sourceOutputIndex][NODE_OUTPUT_DEFAULT_KEY][0];
								if (output && output.total > 0) {
									(endpoint.endpoint as N8nPlusEndpoint).setSuccessOutput(CanvasHelpers.getRunItemsLabel(output));
								} else {
									(endpoint.endpoint as N8nPlusEndpoint).clearSuccessOutput();
								}
							}
						});
					});
				});
			},
			removeNode(nodeName: string) {
				if (this.editAllowedCheck() === false) {
					return;
				}

				const node = this.$store.getters.getNodeByName(nodeName) as INodeUi | null;
				if (!node) {
					return;
				}

				// "requiredNodeTypes" are also defined in cli/commands/run.ts
				// All starting flow (other than hint) can’t be delete or removed.
				const requiredNodeTypes = [START_NODE_TYPE, ...this.startingFlow];

				if (requiredNodeTypes.includes(node.type)) {
					return;

					// The node is of the required type so check first
					// if any node of that type would be left when the
					// current one would get deleted.
					// let deleteAllowed = false;
					// for (const checkNode of this.nodes) {
					// 	if (checkNode.name === node.name) {
					// 		continue;
					// 	}
					// 	if (requiredNodeTypes.includes(checkNode.type)) {
					// 		deleteAllowed = true;
					// 		break;
					// 	}
					// }

					// if (deleteAllowed === false) {
					// 	return;
					// }
				}

				if(node.type === STICKY_NODE_TYPE) {
					this.$telemetry.track('User deleted workflow note', { workflow_id: this.$store.getters.workflowId });
				} else {
					this.$externalHooks().run('node.deleteNode', { node });
					this.$telemetry.track('User deleted node', { node_type: node.type, workflow_id: this.$store.getters.workflowId });
				}

				let waitForNewConnection = false;
				// connect nodes before/after deleted node
				const nodeType: INodeTypeDescription | null = this.$store.getters.nodeType(node.type, node.typeVersion);
				if (nodeType && nodeType.outputs.length === 1
					&& nodeType.inputs.length === 1) {
					const {incoming, outgoing} = this.getIncomingOutgoingConnections(node.name);
					if (incoming.length === 1 && outgoing.length === 1) {
						const conn1 = incoming[0];
						const conn2 = outgoing[0];
						if (conn1.__meta && conn2.__meta) {
							waitForNewConnection = true;
							const sourceNodeName = conn1.__meta.sourceNodeName;
							const sourceNodeOutputIndex = conn1.__meta.sourceOutputIndex;
							const targetNodeName = conn2.__meta.targetNodeName;
							const targetNodeOuputIndex = conn2.__meta.targetOutputIndex;

							setTimeout(() => {
								this.connectTwoNodes(sourceNodeName, sourceNodeOutputIndex, targetNodeName, targetNodeOuputIndex);

								if (waitForNewConnection) {
									this.instance.setSuspendDrawing(false, true);
									waitForNewConnection = false;
								}
							}, 100); // just to make it clear to users that this is a new connection
						}
					}
				}

				setTimeout(() => {
					const nodeIndex = this.$store.getters.getNodeIndex(nodeName);
					const nodeIdName = `node-${nodeIndex}`;

					// Suspend drawing
					this.instance.setSuspendDrawing(true);

					// Remove all endpoints and the connections in jsplumb
					this.instance.removeAllEndpoints(nodeIdName);

					// Remove the draggable
					// @ts-ignore
					this.instance.destroyDraggable(nodeIdName);

					// Remove the connections in data
					this.$store.commit('removeAllNodeConnection', node);

					this.$store.commit('removeNode', node);
					this.$store.commit('clearNodeExecutionData', node.name);

					if (!waitForNewConnection) {
						// Now it can draw again
						this.instance.setSuspendDrawing(false, true);
					}

					// Remove node from selected index if found in it
					this.$store.commit('removeNodeFromSelection', node);

					// Remove from node index
					if (nodeIndex !== -1) {
						this.$store.commit('setNodeIndex', {index: nodeIndex, name: null});
					}
				}, 0); // allow other events to finish like drag stop
			},
			valueChanged(parameterData: IUpdateInformation) {
				if (parameterData.name === 'name' && parameterData.oldValue) {
					// The name changed so we have to take care that
					// the connections get changed.
					this.renameNode(parameterData.oldValue as string, parameterData.value as string);
				}
			},
			async renameNodePrompt(currentName: string) {
				try {
					const promptResponsePromise = this.$prompt(
						this.$locale.baseText('nodeView.prompt.newName') + ':',
						this.$locale.baseText('nodeView.prompt.renameNode') + `: ${currentName}`,
						{
							customClass: 'rename-prompt',
							confirmButtonText: this.$locale.baseText('nodeView.prompt.rename'),
							cancelButtonText: this.$locale.baseText('nodeView.prompt.cancel'),
							inputErrorMessage: this.$locale.baseText('nodeView.prompt.invalidName'),
							inputValue: currentName,
						},
					);

					// Wait till it had time to display
					await Vue.nextTick();

					// Get the input and select the text in it
					const nameInput = document.querySelector('.rename-prompt .el-input__inner') as HTMLInputElement | undefined;
					if (nameInput) {
						nameInput.focus();
						nameInput.select();
					}

					const promptResponse = await promptResponsePromise as MessageBoxInputData;

					this.renameNode(currentName, promptResponse.value);
				} catch (e) {
				}
			},
			async renameNode(currentName: string, newName: string) {
				if (currentName === newName) {
					return;
				}

				const activeNodeName = this.activeNode && this.activeNode.name;
				const isActive = activeNodeName === currentName;
				if (isActive) {
					this.renamingActive = true;
				}

				// Check if node-name is unique else find one that is
				newName = this.getUniqueNodeName({
					originalName: newName,
				});

				// Rename the node and update the connections
				const workflow = this.getWorkflow(undefined, undefined, true);
				workflow.renameNode(currentName, newName);

				// Update also last selected node and exeuction data
				this.$store.commit('renameNodeSelectedAndExecution', {old: currentName, new: newName});

				// Reset all nodes and connections to load the new ones
				this.deleteEveryEndpoint();

				this.$store.commit('removeAllConnections');
				this.$store.commit('removeAllNodes', {setStateDirty: true});

				// Wait a tick that the old nodes had time to get removed
				await Vue.nextTick();

				// Add the new updated nodes
				await this.addNodes(Object.values(workflow.nodes), workflow.connectionsBySourceNode);

				// Make sure that the node is selected again
				this.deselectAllNodes();
				this.nodeSelectedByName(newName);

				if (isActive) {
					this.$store.commit('setActiveNode', newName);
					this.renamingActive = false;
				}
			},
			deleteEveryEndpoint() {
				// Check as it does not exist on first load
				if (this.instance) {
					try {
						const nodes = this.$store.getters.allNodes as INodeUi[];
						// @ts-ignore
						nodes.forEach((node: INodeUi) => this.instance.destroyDraggable(`${NODE_NAME_PREFIX}${this.$store.getters.getNodeIndex(node.name)}`));

						this.instance.deleteEveryEndpoint();
					} catch (e) {
					}
				}
			},
			matchCredentials(node: INodeUi) {
				if (!node.credentials) {
					return;
				}
				Object.entries(node.credentials).forEach(([nodeCredentialType, nodeCredentials]: [string, INodeCredentialsDetails]) => {
					const credentialOptions = this.$store.getters['credentials/getCredentialsByType'](nodeCredentialType) as ICredentialsResponse[];

					// Check if workflows applies old credentials style
					if (typeof nodeCredentials === 'string') {
						nodeCredentials = {
							id: null,
							name: nodeCredentials,
						};
						this.credentialsUpdated = true;
					}

					if (nodeCredentials.id) {
						// Check whether the id is matching with a credential
						const credentialsId = nodeCredentials.id.toString(); // due to a fixed bug in the migration UpdateWorkflowCredentials (just sqlite) we have to cast to string and check later if it has been a number
						const credentialsForId = credentialOptions.find((optionData: ICredentialsResponse) =>
							optionData.id === credentialsId,
						);
						if (credentialsForId) {
							if (credentialsForId.name !== nodeCredentials.name || typeof nodeCredentials.id === 'number') {
								node.credentials![nodeCredentialType] = {id: credentialsForId.id, name: credentialsForId.name};
								this.credentialsUpdated = true;
							}
							return;
						}
					}

					// No match for id found or old credentials type used
					node.credentials![nodeCredentialType] = nodeCredentials;

					// check if only one option with the name would exist
					const credentialsForName = credentialOptions.filter((optionData: ICredentialsResponse) => optionData.name === nodeCredentials.name);

					// only one option exists for the name, take it
					if (credentialsForName.length === 1) {
						node.credentials![nodeCredentialType].id = credentialsForName[0].id;
						this.credentialsUpdated = true;
					}
				});
			},
			async addNodes(nodes: INodeUi[], connections?: IConnections) {
				if (!nodes || !nodes.length) {
					return;
				}

				// Before proceeding we must check if all nodes contain the `properties` attribute.
				// Nodes are loaded without this information so we must make sure that all nodes
				// being added have this information.
				await this.loadNodesProperties(nodes.map(node => ({name: node.type, version: node.typeVersion})));

				// Add the node to the node-list
				let nodeType: INodeTypeDescription | null;
				let foundNodeIssues: INodeIssues | null;
				nodes.forEach((node) => {
					nodeType = this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;

					// Make sure that some properties always exist
					if (!node.hasOwnProperty('disabled')) {
						node.disabled = false;
					}

					if (!node.hasOwnProperty('parameters')) {
						node.parameters = {};
					}

					// Load the defaul parameter values because only values which differ
					// from the defaults get saved
					if (nodeType !== null) {
						let nodeParameters = null;
						try {
							nodeParameters = NodeHelpers.getNodeParameters(nodeType.properties, node.parameters, true, false, node);
						} catch (e) {
							console.error(this.$locale.baseText('nodeView.thereWasAProblemLoadingTheNodeParametersOfNode') + `: "${node.name}"`); // eslint-disable-line no-console
							console.error(e); // eslint-disable-line no-console
						}
						node.parameters =
							nodeParameters !== null
								? {
									...nodeParameters,
									icsTitle: !node.parameters.icsTitle ? node.name : node.parameters.icsTitle,
									...node.parameters,
								}
								: {
									icsTitle: !node.parameters.icsTitle ? node.name : node.parameters.icsTitle,
									...node.parameters,
								};

						// if it's a webhook and the path is empty set the UUID as the default path
						if (node.type === WEBHOOK_NODE_TYPE && node.parameters.path === '') {
							node.parameters.path = node.webhookId as string;
						}
					}

					// check and match credentials, apply new format if old is used
					this.matchCredentials(node);

					foundNodeIssues = this.getNodeIssues(nodeType, node);

					if (foundNodeIssues !== null) {
						node.issues = foundNodeIssues;
					}

					// Check node style version
					// (1) If this workflow (global scope) does not have nodeStyleVersion
					//     (In other words, it is not a new workflow)
					// check whether there is a nodeStyleVersion value in node. 
					// If there is, use it to set the value. If not, use the default value.
					if (this.nodeStyleVersion === null) {
						const nodeStyleVersionInNode = node.hasOwnProperty('nodeStyleVersion') ? node.nodeStyleVersion : DEFAULT_NODE_STYLE_VERSION;
						this.$store.commit('imbrace/setNodeStyleVersion', nodeStyleVersionInNode);
					}
					// (2) If this workflow has nodeStyleVersion, but not in each node
					// then use this value to set it in each node.
					if (!node.hasOwnProperty('nodeStyleVersion')) {
						node.nodeStyleVersion = this.nodeStyleVersion;
					}

					this.$store.commit('addNode', node);
				});

				// Wait for the node to be rendered
				await Vue.nextTick();

				// Suspend drawing
				this.instance.setSuspendDrawing(true);

				// Load the connections
				if (connections !== undefined) {
					let connectionData;
					for (const sourceNode of Object.keys(connections)) {
						for (const type of Object.keys(connections[sourceNode])) {
							for (let sourceIndex = 0; sourceIndex < connections[sourceNode][type].length; sourceIndex++) {
								const outwardConnections = connections[sourceNode][type][sourceIndex];
								if (!outwardConnections) {
									continue;
								}
								outwardConnections.forEach((
									targetData,
								) => {
									connectionData = [
										{
											node: sourceNode,
											type,
											index: sourceIndex,
										},
										{
											node: targetData.node,
											type: targetData.type,
											index: targetData.index,
										},
									] as [IConnection, IConnection];

									this.__addConnection(connectionData, true);
								});
							}
						}
					}
				}

				// Now it can draw again
				this.instance.setSuspendDrawing(false, true);
			},
			async addNodesToWorkflow(data: IWorkflowDataUpdate): Promise<IWorkflowDataUpdate> {
				// Because nodes with the same name maybe already exist, it could
				// be needed that they have to be renamed. Also could it be possible
				// that nodes are not allowd to be created because they have a create
				// limit set. So we would then link the new nodes with the already existing ones.
				// In this object all that nodes get saved in the format:
				//   old-name -> new-name
				const nodeNameTable: {
					[key: string]: string;
				} = {};
				const newNodeNames: string[] = [];

				if (!data.nodes) {
					// No nodes to add
					throw new Error(
						this.$locale.baseText('nodeView.noNodesGivenToAdd'),
					);
				}

				// Get how many of the nodes of the types which have
				// a max limit set already exist
				const nodeTypesCount = this.getNodeTypesMaxCount();

				let oldName: string;
				let newName: string;
				const createNodes: INode[] = [];

				await this.loadNodesProperties(data.nodes.map(node => ({name: node.type, version: node.typeVersion})));

				data.nodes.forEach(node => {
					if (this.verifyLockedConnector(node.type)) {
						if (this.parentOrigin) {
							window.parent.postMessage({
								type: "lockAction",
							}, this.parentOrigin);
						}
						return;
					}

					if (nodeTypesCount[node.type] !== undefined) {
						if (nodeTypesCount[node.type].exist >= nodeTypesCount[node.type].max) {
							// Node is not allowed to be created so
							// do not add it to the create list but
							// add the name of the existing node
							// that this one gets linked up instead.
							nodeNameTable[node.name] = nodeTypesCount[node.type].nodeNames[0];

							const nodeTypeData: INodeTypeDescription | null = this.$store.getters.nodeType(node.type);
							if ((nodeTypeData && nodeTypeData.maxNodes !== undefined && this.getNodeTypeCount(node.type) >= nodeTypeData.maxNodes) || (nodeTypeData && this.startingFlow.includes(nodeTypeData.name))) {
								this.showMaxNodeTypeError(nodeTypeData);
							}

							return;
						} else {
							// Node can be created but increment the
							// counter in case multiple ones are
							// supposed to be created
							nodeTypesCount[node.type].exist += 1;
						}
					}

					oldName = node.name;
					newName = this.getUniqueNodeName({
						originalName: node.name,
						additionalUsedNames: newNodeNames,
						type: node.type,
					});

					newNodeNames.push(newName);
					nodeNameTable[oldName] = newName;

					createNodes.push(node);
				});

				// Get only the connections of the nodes that get created
				const newConnections: IConnections = {};
				const currentConnections = data.connections!;
				const createNodeNames = createNodes.map((node) => node.name);
				let sourceNode, type, sourceIndex, connectionIndex, connectionData;
				for (sourceNode of Object.keys(currentConnections)) {
					if (!createNodeNames.includes(sourceNode)) {
						// Node does not get created so skip output connections
						continue;
					}

					const connection: INodeConnections = {};

					for (type of Object.keys(currentConnections[sourceNode])) {
						connection[type] = [];
						for (sourceIndex = 0; sourceIndex < currentConnections[sourceNode][type].length; sourceIndex++) {
							const nodeSourceConnections = [];
							if (currentConnections[sourceNode][type][sourceIndex]) {
								for (connectionIndex = 0; connectionIndex < currentConnections[sourceNode][type][sourceIndex].length; connectionIndex++) {
									connectionData = currentConnections[sourceNode][type][sourceIndex][connectionIndex];
									if (!createNodeNames.includes(connectionData.node)) {
										// Node does not get created so skip input connection
										continue;
									}

									nodeSourceConnections.push(connectionData);
									// Add connection
								}
							}
							connection[type].push(nodeSourceConnections);
						}
					}

					newConnections[sourceNode] = connection;
				}

				// Create a workflow with the new nodes and connections that we can use
				// the rename method
				const tempWorkflow: Workflow = this.getWorkflow(createNodes, newConnections);

				// Rename all the nodes of which the name changed
				for (oldName in nodeNameTable) {
					if (oldName === nodeNameTable[oldName]) {
						// Name did not change so skip
						continue;
					}
					tempWorkflow.renameNode(oldName, nodeNameTable[oldName]);
				}

				// Add the nodes with the changed node names, expressions and connections
				await this.addNodes(Object.values(tempWorkflow.nodes), tempWorkflow.connectionsBySourceNode);

				this.$store.commit('setStateDirty', true);

				return {
					nodes: Object.values(tempWorkflow.nodes),
					connections: tempWorkflow.connectionsBySourceNode,
				};
			},
			getSelectedNodesToSave(): Promise<IWorkflowData> {
				const data: IWorkflowData = {
					nodes: [],
					connections: {},
				};

				// Get data of all the selected noes
				let nodeData;
				const exportNodeNames: string[] = [];

				for (const node of this.$store.getters.getSelectedNodes) {
					try {
						nodeData = this.getNodeDataToSave(node);
						exportNodeNames.push(node.name);
					} catch (e) {
						return Promise.reject(e);
					}

					data.nodes.push(nodeData);
				}

				// Get only connections of exported nodes and ignore all other ones
				let connectionToKeep,
					connections: INodeConnections,
					type: string,
					connectionIndex: number,
					sourceIndex: number,
					connectionData: IConnection,
					typeConnections: INodeConnections;

				data.nodes.forEach((node) => {
					connections = this.$store.getters.outgoingConnectionsByNodeName(node.name);
					if (Object.keys(connections).length === 0) {
						return;
					}

					// Keep only the connection to node which get also exported
					// @ts-ignore
					typeConnections = {};
					for (type of Object.keys(connections)) {
						for (sourceIndex = 0; sourceIndex < connections[type].length; sourceIndex++) {
							connectionToKeep = [];
							for (connectionIndex = 0; connectionIndex < connections[type][sourceIndex].length; connectionIndex++) {
								connectionData = connections[type][sourceIndex][connectionIndex];
								if (exportNodeNames.indexOf(connectionData.node) !== -1) {
									connectionToKeep.push(connectionData);
								}
							}

							if (connectionToKeep.length) {
								if (!typeConnections.hasOwnProperty(type)) {
									typeConnections[type] = [];
								}
								typeConnections[type][sourceIndex] = connectionToKeep;
							}
						}
					}

					if (Object.keys(typeConnections).length) {
						data.connections[node.name] = typeConnections;
					}
				});

				return Promise.resolve(data);
			},
			resetWorkspace() {
				// Reset nodes
				this.deleteEveryEndpoint();

				if (this.executionWaitingForWebhook === true) {
					// Make sure that if there is a waiting test-webhook that
					// it gets removed
					this.restApi().removeTestWebhook(this.$store.getters.workflowId)
						.catch(() => {
							// Ignore all errors
						});
				}

				this.$store.commit('removeAllConnections', {setStateDirty: false});
				this.$store.commit('removeAllNodes', {setStateDirty: false});

				// Reset workflow execution data
				this.$store.commit('setWorkflowExecutionData', null);
				this.$store.commit('resetAllNodesIssues');
				// vm.$forceUpdate();

				this.$store.commit('setActive', false);
				this.$store.commit('setWorkflowId', PLACEHOLDER_EMPTY_WORKFLOW_ID);
				this.$store.commit('setWorkflowName', {newName: '', setStateDirty: false});
				this.$store.commit('setWorkflowSettings', {});
				this.$store.commit('setWorkflowTagIds', []);


				this.$store.commit('setActiveExecutionId', null);
				this.$store.commit('setExecutingNode', null);
				this.$store.commit('removeActiveAction', 'workflowRunning');
				this.$store.commit('setExecutionWaitingForWebhook', false);

				this.$store.commit('resetNodeIndex');
				this.$store.commit('resetSelectedNodes');

				this.$store.commit('setNodeViewOffsetPosition', {newOffset: [0, 0], setStateDirty: false});

				return Promise.resolve();
			},
			async loadActiveWorkflows(): Promise<void> {
				const activeWorkflows = await this.restApi().getActiveWorkflows();
				this.$store.commit('setActiveWorkflows', activeWorkflows);
			},
			async loadAllWorkflows(): Promise<void> {
				const allWorkflows = await this.restApi().getWorkflows();
				this.$store.commit('setAllWorkflow', allWorkflows);
			},
			async loadNodeTypes(): Promise<void> {
				const nodeTypes = await this.restApi().getNodeTypes();
				this.$store.commit('setNodeTypes', nodeTypes);
			},
			async loadCredentialTypes(): Promise<void> {
				await this.$store.dispatch('credentials/fetchCredentialTypes');
			},
			async loadCredentials(): Promise<void> {
				await this.$store.dispatch('credentials/fetchAllCredentials');
			},
			async loadNodesProperties(nodeInfos: INodeTypeNameVersion[]): Promise<void> {
				const allNodes: INodeTypeDescription[] = this.$store.getters.allNodeTypes;

				const nodesToBeFetched: INodeTypeNameVersion[] = [];
				allNodes.forEach(node => {
					const nodeVersions = Array.isArray(node.version) ? node.version : [node.version];
					if(!!nodeInfos.find(n => n.name === node.name && nodeVersions.includes(n.version)) && !node.hasOwnProperty('properties')) {
						nodesToBeFetched.push({
							name: node.name,
							version: Array.isArray(node.version)
								? node.version.slice(-1)[0]
								: node.version,
						});
					}
				});

				if (nodesToBeFetched.length > 0) {
					// Only call API if node information is actually missing
					this.startLoading();

					const nodesInfo = await this.restApi().getNodesInformation(nodesToBeFetched);

					nodesInfo.forEach(nodeInfo => {
						if (nodeInfo.translation) {
							const nodeType = this.$locale.shortNodeType(nodeInfo.name);

							addNodeTranslation(
								{[nodeType]: nodeInfo.translation},
								this.$store.getters.defaultLocale,
							);
						}
					});

					this.$store.commit('updateNodeTypes', nodesInfo);
					this.stopLoading();
				}
			},
			async loadTags(): Promise<void> {
				await this.$store.dispatch("tags/fetchAll", {force: true, withUsageCount: false});
			},
			async loadImbraceChannel(): Promise<void> {
				const access_token = this.$store.getters['imbrace/accessToken'];
				const workflowId = this.$route.params.name;

				if (access_token && workflowId) {
					const headers = {'x-access-token': `${access_token}`};
					const response = await this.restApi().getChannelData(this.currentChannelType, headers);

					if (response && response.length > 0) {
						const channel = response.find((channel) => channel.workflow_id === workflowId);
						if (channel) {
							this.$store.commit('imbrace/setImbraceChannel', channel);
						}
					}
				}
			},
			async loadBoards() {
				const access_token = this.$store.getters['imbrace/accessToken'] || process.env.VUE_APP_TEMPORARY_TOKEN;
				if (!access_token) return;

				const headers = {'x-access-token': `${access_token}`};
				const boards = await this.restApi().getBoards(headers);
				if (!boards) return;

				this.$store.commit('imbrace/setAllBoards', boards);
			},
			async loadMembers () {
				const access_token = this.$store.getters['imbrace/accessToken'] || process.env.VUE_APP_TEMPORARY_TOKEN;
				if (!access_token) return;

				const headers = {'x-access-token': `${access_token}`};
				const allMembers = await this.restApi().getAllMembers(headers);
				if (!allMembers) return;

				allMembers.sort((memberA, memberB) => memberA.display_name.localeCompare(memberB.display_name));
				this.$store.commit('imbrace/setAllMembers', allMembers);
			},
			async loadImbraceAccount() {
				const access_token = this.$store.getters['imbrace/accessToken'] || process.env.VUE_APP_TEMPORARY_TOKEN;
				if (!access_token) return;

				const headers = {'x-access-token': `${access_token}`};
				const imbraceAccountData = await this.restApi().getImbraceAccountData(headers);
				if (!imbraceAccountData) return;

				this.$store.commit('imbrace/setImbraceAccountData', imbraceAccountData);
			},
			async loadImbracePayStatus() {
				const access_token = this.$store.getters['imbrace/accessToken'] || process.env.VUE_APP_TEMPORARY_TOKEN;
				if (!access_token) return;

				const headers = {'x-access-token': `${access_token}`};
				const imbracePayStatus = await this.restApi().getImbracePayStatus(headers);
				if (!imbracePayStatus) return;

				this.$store.commit('imbrace/setImbracePayStatus', imbracePayStatus);
			},
			async onPostMessageReceived(message: MessageEvent) {
				try {
					// for Workflow to Execute iframe
					if ('type' in message.data && message.data.type === 'saveAction') {
						if (message.data.data.shouldClose) {
							this.$store.commit('imbrace/setShouldModalClose', true);
						}
						// In channel WF, channel automation WF and board automation WF
						// every time user save WF, we will automatically active WF
						if (this.currentChannelType || this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
							this.$store.commit('setActive', true);
							this.$store.commit('imbrace/setUpdateActiveChanged', true);
						} else {
							const saved = await this.saveCurrentWorkflow({}, false);

							// when workflow is successfully saved, postMessage back to parent
							if (saved && this.parentOrigin) {
								window.parent.postMessage({
									type: "saveCompletedAction",
								}, this.parentOrigin);
							}
						}

					}
					const json = JSON.parse(message.data);
					if (json && json.command === 'openWorkflow') {
						try {
							await this.importWorkflowExact(json);
						} catch (e) {
							// if (window.top) {
							// 	window.top.postMessage(JSON.stringify({
							// 		command: 'error',
							// 		message: this.$locale.baseText('openWorkflow.workflowImportError'),
							// 	}), '*');
							// }
							this.$showMessage({
								title: this.$locale.baseText('openWorkflow.workflowImportError'),
								message: (e as Error).message,
								type: 'error',
							});
						}
					}
				} catch (e) {
				}
			},
			async onImportWorkflowDataEvent(data: IDataObject) {
				await this.importWorkflowData(data.data as IWorkflowDataUpdate);
			},
			async onImportWorkflowUrlEvent(data: IDataObject) {
				const workflowData = await this.getWorkflowDataFromUrl(data.url as string);
				if (workflowData !== undefined) {
					await this.importWorkflowData(workflowData);
				}
			},
			async makeTagExist(tagNames: string[]) {
				// Check if tag exist in all tag list. If not, create a tag.
				let reloadTags = false;
				if (!this.allTags || this.allTags.length === 0) {
					tagNames.forEach((tagName) => {
						this.$store.dispatch("tags/create", tagName);
					});
					reloadTags = true;
				} else {
					const tagsNotFound = tagNames.filter((tagName: string) => {
						return this.allTags.findIndex((tag: ITag) => tag.name === tagName) === -1;
					});
					if (tagsNotFound.length > 0) {
						tagsNotFound.forEach((tagName: string) => {
							this.$store.dispatch("tags/create", tagName);
						});
						reloadTags = true;
					};
				}
				if (reloadTags) await this.loadTags();
			},
			async addTagToWorkflow (newTagNames: string[]) {
				await this.makeTagExist(newTagNames);
				const newTagIds = this.allTags.filter((tag: ITag) => newTagNames.includes(tag.name)).map((tag: ITag) => tag.id);
				if (newTagIds.length > 0) {
					this.$store.commit('setWorkflowTagIds', newTagIds);
				};
			},
			checkTagsToSetType(inputTags: string[], openExistWorkflow?: boolean) {
				const tags = inputTags.filter((tag) => VALID_TAGS.includes(tag));
				const hasPresetTag = tags.includes(PRESET_WORKFLOW_TYPE);
				const hasAutomationTag = tags.includes(AUTOMATION_WORKFLOW_TYPE);
				const hasBoardTag = tags.includes(BOARD_WORKFLOW_TYPE);
				const findChannelTag = CHANNEL_TYPE.find((channel) => tags.includes(channel.tag));

				if (hasPresetTag) {
					this.$store.commit('imbrace/setCurrentWorkflowType', PRESET_WORKFLOW_TYPE);
				}
				if (hasAutomationTag && !findChannelTag) {
					this.$store.commit('imbrace/setCurrentWorkflowType', AUTOMATION_WORKFLOW_TYPE);
				}
				if (hasAutomationTag && hasBoardTag) {
					this.$store.commit('imbrace/setCurrentWorkflowType', BOARD_AUTOMATION_WORKFLOW_TYPE);
				}
				if (hasAutomationTag && findChannelTag) {
					this.$store.commit('imbrace/setCurrentWorkflowType', CHANNEL_AUTOMATION_WORKFLOW_TYPE);
					this.$store.commit('imbrace/setCurrentChannelType', findChannelTag.tag);
				}
				// "channel" workflow type won't be set from iframe url
				if (openExistWorkflow && findChannelTag && !hasAutomationTag) {
					this.$store.commit('imbrace/setCurrentWorkflowType', CHANNEL_WORKFLOW_TYPE);
					this.$store.commit('imbrace/setCurrentChannelType', findChannelTag.tag);
				}

				this.addTagToWorkflow(tags);
			},
			checkTagsToSetSource(inputTags: string[]) {
				if (inputTags.includes(JOURNEY_WORKFLOW_SOURCE)) {
					this.$store.commit('imbrace/setCurrentWorkflowSource', JOURNEY_WORKFLOW_SOURCE);
				}
				if (inputTags.includes(IDEA_WORKFLOW_SOURCE)) {
					this.$store.commit('imbrace/setCurrentWorkflowSource', IDEA_WORKFLOW_SOURCE);
				}
			},
			checkTypeWhenCreateWorkflow() {
				// There are two ways to create new workflow
				// (1) From iframe url: Parse query tag to check and set workflow/channel type
				// (2) From n8n main list "Create new" button: Check currentWorkflowType to add tag

				const queryTagString = this.$route.query.tag;
				if (queryTagString) {
					const queryTags = (queryTagString as string).split(',');
					this.checkTagsToSetType(queryTags);
				} else {
					if (this.currentWorkflowType !== DEFAULT_WORKFLOW_TYPE) {
						this.addTagToWorkflow([this.currentWorkflowType]);
					}
				}
			},
			checkTypeWhenOpenWorkflow(data: IWorkflowDb | IWorkflowData) {
				// Distinguish workflow/channel type from workflow data
				const tags = (data.tags || []) as ITag[];
				if (tags.length > 0) {
					const tagNames = tags.map((tag: ITag) => tag.name);
					this.checkTagsToSetType(tagNames, true);
					this.checkTagsToSetSource(tagNames);
				}
			},
			isValidInCurrentWorkflowType (nodeName: string): boolean {
				// Check is this node match currentWorkflowType
				const nodeType = this.allNodeTypes.find((nodeType: INodeTypeDescription) => nodeType.name === nodeName);
				if (!nodeType) return false;

				// Hide triggers
				if (this.excludeCategories.includes(TRIGGERS_CATEGORY)) {
					const isTrigger = nodeType.group.includes('trigger');
					if (isTrigger) return false;
				}
				// Hide other nodes in excludeCategories
				if (nodeType.codex && nodeType.codex.categories) {
					const excludeNode = !!nodeType.codex.categories.find((category: string) => this.excludeCategories.includes(category));
					if (excludeNode) return false;
				}
				return true;
			},
			isValidInCurrentChannelType (nodeName: string): boolean {
				// Check is this node match currentChannelType
				const nodeType = this.allNodeTypes.find((nodeType: INodeTypeDescription) => nodeType.name === nodeName);
				if (!nodeType) return false;

				// For example, can not see "LINE Multiple Choices" in "WeChat" channel workflow
				if (nodeType.codex && nodeType.codex.categories) {
					if (this.currentChannelType && nodeType.codex.subcategories && nodeType.codex.subcategories[CHANNEL_ACTIONS_CATEGORY]) {
						const excludeNode = !nodeType.codex.subcategories[CHANNEL_ACTIONS_CATEGORY].includes(this.currentChannelType);
						if (excludeNode) return false;
					}
				}
				return true;
			},
			isValidWorkflowIdInNodes(nodes: INodeUi[]) {
				const invalidNodes: INodeUi[] = [];

				// Whether the workflow ID in the Execution Workflow node exists
				const executeWorkflowNodes = nodes.filter((node) => node.type === EXECUTE_WORKFLOW_NODE_TYPE);
				if (executeWorkflowNodes.length > 0) {
					const allWorkflowIds = this.$store.getters['allWorkflowIds'];
					executeWorkflowNodes.forEach((node) => {
						if (node.parameters.workflowId !== '' && !allWorkflowIds.includes(node.parameters.workflowId)) {
							invalidNodes.push(node);
						}
					});
				}

				if (invalidNodes.length > 0) {
					const nodeNames = invalidNodes.map((node) => node.parameters.icsTitle).join(', ');
					this.$showMessage({
						title: this.$locale.baseText('nodeView.showMessage.invalidNodes.title'),
						message: this.$locale.baseText(
							'nodeView.showMessage.invalidNodes.message',
							{interpolate: {nodeNames}},
						),
						type: 'error',
					});
				}
			},
			verifyLockedConnector (nodeName: string): boolean {
				// if the connector should be locked,
				// send a postMessage to the dashboard to trigger unlock flow
				// and the connector is disallow new connectors
				const activeConnector = {};

				// @ts-ignore
				activeConnector[nodeName as string] = this.getNodeTypeCount(nodeName);

				return this.$store.getters['imbrace/isFeatureLock']({activeConnector});
			},
			determineNodeStyleVersionByWorkflow (key: string): number | null {
				const nodeStyleVersionMapping: Record<string, number> = {
					newWorkflow: LATEST_NODE_STYLE_VERSION,
				};
				return nodeStyleVersionMapping[key] ?? null;
			},
		},
		async mounted() {
			const access_token = this.$route.query.token;
			if (access_token) {
				this.$store.commit('imbrace/setAccessToken', access_token);
			}

			this.$titleReset();
			window.addEventListener('message', this.onPostMessageReceived);
			this.$root.$on('importWorkflowData', this.onImportWorkflowDataEvent);
			this.$root.$on('newWorkflow', this.newWorkflow);
			this.$root.$on('importWorkflowUrl', this.onImportWorkflowUrlEvent);

			this.startLoading();

			const loadPromises = [
				this.loadActiveWorkflows(),
				this.loadAllWorkflows(),
				this.loadCredentials(),
				this.loadCredentialTypes(),
				this.loadNodeTypes(),
				this.loadTags(),
			];

			try {
				await Promise.all(loadPromises);
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('nodeView.showError.mounted1.title'),
					this.$locale.baseText('nodeView.showError.mounted1.message') + ':',
				);
				return;
			}

			const loadImbracePromises = [
				this.loadBoards(),
				this.loadMembers(),
				this.loadImbraceAccount(),
				this.loadImbracePayStatus(),
			];

			try {
				await Promise.all(loadImbracePromises);
			} catch (error) {
				console.log(error);
			}

			this.instance.ready(async () => {
				try {
					this.initNodeView();
					await this.initView();
					// if (window.top) {
					// 	window.top.postMessage(JSON.stringify({command: 'n8nReady', version: this.$store.getters.versionCli}), '*');
					// }
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('nodeView.showError.mounted2.title'),
						this.$locale.baseText('nodeView.showError.mounted2.message') + ':',
					);
				}
				this.stopLoading();

				setTimeout(() => {
					// this.$store.dispatch('users/showPersonalizationSurvey');
					this.checkForNewVersions();
					this.finishInitialRender = true;
				}, 0);
			});

			this.$externalHooks().run('nodeView.mount');
		},

		destroyed() {
			this.resetWorkspace();
			this.$store.commit('setStateDirty', false);
			window.removeEventListener('message', this.onPostMessageReceived);
			this.$root.$off('newWorkflow', this.newWorkflow);
			this.$root.$off('importWorkflowData', this.onImportWorkflowDataEvent);
			this.$root.$off('importWorkflowUrl', this.onImportWorkflowUrlEvent);
		},
	});
