
//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import {
	GenericValue,
	IDataObject,
	INodeExecutionData,
	INodeTypeDescription,
	IRunData,
	IRunExecutionData,
	Workflow,
} from 'n8n-workflow';

import {
	IExecutionResponse,
	INodeUi,
	ITableData,
} from '@/Interface';

import WarningTooltip from '@/components/WarningTooltip.vue';
import NodeErrorView from '@/components/Error/NodeErrorView.vue';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';
import NodeExecuteButton from './NodeExecuteButton.vue';

// A path that does not exist so that nothing is selected by default
const deselectedPlaceholder = '_!^&*';

export default mixins(
	genericHelpers,
	nodeHelpers,
	workflowHelpers,
)
	.extend({
		name: 'DataDisplayToUseExpression',
		components: {
			NodeErrorView,
			NodeExecuteButton,
			VueJsonPretty,
			WarningTooltip,
		},
		props: [
			'dialogVisible',
			'parameter',
			'path',
			'value',
			'parameterInputType',
		],
		data () {
			return {
				dataSize: 0,
				deselectedPlaceholder,
				displayMode: 'table',
				state: {
					value: '' as object | number | string,
					path: deselectedPlaceholder,
				},
				runIndex: 0,
				showData: false,
				outputIndex: 0,

				tempValue: '',
				currentValue: '',
			};
		},
		mounted() {
			this.init();
		},
		computed: {
			nodeType (): INodeTypeDescription | null {
				if (this.node) {
					return this.$store.getters.nodeType(this.node.type, this.node.typeVersion);
				}
				return null;
			},
			hasNodeRun(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData.hasOwnProperty(this.node.name));
			},
			hasRunError(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData[this.node.name] && this.workflowRunData[this.node.name][this.runIndex] && this.workflowRunData[this.node.name][this.runIndex].error);
			},
			workflowRunning (): boolean {
				return this.$store.getters.isActionActive('workflowRunning');
			},
			workflowExecution (): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowRunData (): IRunData | null {
				if (this.workflowExecution === null) {
					return null;
				}
				const executionData: IRunExecutionData = this.workflowExecution.data;
				if (executionData && executionData.resultData) {
					return executionData.resultData.runData;
				}
				return null;
			},
			activeNode(): INodeUi {
				return this.$store.getters.activeNode;
			},
			workflow(): Workflow {
				return this.getWorkflow();
			},
			parentNodes(): string[] {
				if (this.activeNode) {
					return (
						this.workflow.getParentNodesByDepth(this.activeNode.name, 1).map(({ name }) => name) || []
					);
				}
				return [];
			},
			node (): INodeUi | null {
				return this.$store.getters.getNodeByName(this.parentNodes[0]);
			},
			maxRunIndex (): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length) {
					return runData[this.node.name].length - 1;
				}

				return 0;
			},
			inputData (): INodeExecutionData[] {
				let inputData = this.getNodeInputData(this.node, this.runIndex, this.outputIndex);
				if (inputData.length === 0 || !Array.isArray(inputData)) {
					return [];
				}

				return inputData;
			},
			tableData (): ITableData | undefined {
				return this.convertToTable(this.inputData);
			},
			latestValue (): string {

				// First connector would not have data from last connector
				// so we remove the '=' symble
				if (!this.node && this.currentValue.charAt(0) === '=') {
					if (this.parameterInputType === 'number' || this.parameterInputType === 'boolean'){
						const innerValue = this.currentValue.match(/\{\{(.+)\}\}/);
						if (innerValue !== null) {
							return innerValue[1];
						}
					}
					return this.currentValue.slice(1);
				}

				// For values that not match the `={{$json["XXXX"]}}`,
				// remain the default value
				if (this.currentValue.charAt(0) === '=') {
					return this.currentValue;
				}

				if (this.currentValue === '') {
					return this.currentValue;
				}

				// For now, we just need one value from last connector
				return `={{$json["${this.currentValue}"]}}`;
			},
			disableApply (): boolean {
				if (!this.tempValue) {
					return true;
				}
				if (this.currentValue === this.tempValue) {
					return true;
				}

				return false;
			},
		},
		methods: {
			init() {
				// Reset the selected output index every time another node gets selected
				this.outputIndex = 0;
			},
			initValue() {
				// Input value might be these format:
				// * For number or boolean: `={{${this.value}}}`
				// * For other parameter types: `=${this.value}`
				// * For expression: value could be `={{$json["Last Name"]}}` 
				// or something like `={{false}} {{$node["Google Sheets Trigger"].parameter["authentication"]}}`
				
				if (!this.value) {
					return;
				}

				if (this.value.charAt(0) === '=') {
					const matchValue = this.value.match(/\$json\[\"(.+)\"\]/);
					if (!!matchValue) {
						// If "this.value" is something like `={{$json["Last Name"]}}`
						// then extract the "Last Name" to match the table data
						this.tempValue = matchValue[1];
						this.currentValue = matchValue[1];
						return;
					}
				}

				// For values that not match the above, remain the default value
				this.tempValue = this.value;
				this.currentValue = this.value;
			},
			onNodeExecute() {
				this.$emit('execute');
			},
			selectValue(value: string) {
				this.tempValue = value;
			},
			applyValue() {
				if (!this.tempValue) {
					return;
				}

				// Apply input data from last connector
				// for multi-value, only one choice is fine
				this.currentValue = this.tempValue;
				this.valueChanged(this.currentValue, true);
			},
			clearValue() {
				this.tempValue = '';
				this.currentValue = '';
			},
			valueChanged(value: string, forceUpdate = false) {
				if (forceUpdate === true) {
					this.$emit('valueChanged', this.latestValue);
				} else {
					this.callDebounced('updateDisplayValue', { debounceTime: 500 });
				}
			},
			closeDialog () {
				// Handle the close externally as the visible parameter is an external prop
				// and is so not allowed to be changed here.
				this.$emit('valueChanged', this.latestValue);
				this.$emit('closeDialog');
				return false;
			},
			convertToTable (inputData: INodeExecutionData[]): ITableData | undefined {
				const tableData: GenericValue[][] = [];
				const tableColumns: string[] = [];
				let leftEntryColumns: string[], entryRows: GenericValue[];
				// Go over all entries
				let entry: IDataObject;
				inputData.forEach((data) => {
					if (!data.hasOwnProperty('json')) {
						return;
					}
					entry = data.json;

					// Go over all keys of entry
					entryRows = [];
					leftEntryColumns = Object.keys(entry);

					// Go over all the already existing column-keys
					tableColumns.forEach((key) => {
						if (entry.hasOwnProperty(key)) {
							// Entry does have key so add its value
							entryRows.push(entry[key]);
							// Remove key so that we know that it got added
							leftEntryColumns.splice(leftEntryColumns.indexOf(key), 1);
						} else {
							// Entry does not have key so add null
							entryRows.push(null);
						}
					});

					// Go over all the columns the entry has but did not exist yet
					leftEntryColumns.forEach((key) => {
						// Add the key for all runs in the future
						tableColumns.push(key);
						// Add the value
						entryRows.push(entry[key]);
					});

					// Add the data of the entry
					tableData.push(entryRows);
				});

				// Make sure that all entry-rows have the same length
				tableData.forEach((entryRows) => {
					if (tableColumns.length > entryRows.length) {
						// Has to less entries so add the missing ones
						entryRows.push.apply(entryRows, new Array(tableColumns.length - entryRows.length));
					}
				});

				return {
					columns: tableColumns,
					data: tableData,
				};
			},
		},
		watch: {
			maxRunIndex () {
				this.runIndex = Math.min(this.runIndex, this.maxRunIndex);
			},
			dialogVisible (newValue) {
				this.initValue();
			},
		},
	});
