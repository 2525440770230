import { render, staticRenderFns } from "./DataDisplay.vue?vue&type=template&id=1ff9516d"
import script from "./DataDisplay.vue?vue&type=script&lang=ts"
export * from "./DataDisplay.vue?vue&type=script&lang=ts"
import style0 from "./DataDisplay.vue?vue&type=style&index=0&id=1ff9516d&prod&lang=scss"
import style1 from "./DataDisplay.vue?vue&type=style&index=1&id=1ff9516d&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports