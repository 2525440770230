
import Vue from 'vue';
import { camelCase } from 'lodash-es';
import { CategoryName } from '@/plugins/i18n';

export default Vue.extend({
	props: ['item'],
	computed: {
		categoryName() {
			return camelCase(this.item.category);
		},
		isCategoryLocked(): boolean {
			// TODO: check what would be the value in string array? ('Channel Actions' or 'channelActions')
			// 'this.categoryName' for 'channelActions', 'this.item.category' for 'Channel Actions'
			// TODO: probably need to update logic, for example if all connectors under this category is locked, then lock this category
			return this.$store.getters['imbrace/isFeatureLock']({activeConnectorGroups: this.categoryName});
		},
	},
	methods: {
		renderCategoryName(categoryName: CategoryName) {
			const key = `nodeCreator.categoryNames.${categoryName}` as const;

			return this.$locale.exists(key) ? this.$locale.baseText(key) : categoryName;
		},
	},
});
