import { render, staticRenderFns } from "./WorkflowList.vue?vue&type=template&id=f25c7b6a&scoped=true"
import script from "./WorkflowList.vue?vue&type=script&lang=ts"
export * from "./WorkflowList.vue?vue&type=script&lang=ts"
import style0 from "./WorkflowList.vue?vue&type=style&index=0&id=f25c7b6a&prod&lang=scss&module=true"
import style1 from "./WorkflowList.vue?vue&type=style&index=1&id=f25c7b6a&prod&scoped=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f25c7b6a",
  null
  
)

export default component.exports