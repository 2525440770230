
import { INodeUi } from '@/Interface';

import Search from '@/components/Search/Search.vue';
import Typography from '@/components/Typography/Typography.vue';

import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';

import * as CanvasHelpers from '../../views/canvasHelpers';
import NodeIcon from '../NodeIcon.vue';
import { genericHelpers } from '../mixins/genericHelpers';
import SlideTransition from '../transitions/SlideTransition.vue';

export default mixins(genericHelpers).extend({
	name: 'NodeList',
	components: {
		NodeIcon,
		SlideTransition,
		Search,
		Typography,
	},
	props: ['active', 'nodes'],
	data: function () {
		return {
			// instance: jsPlumb.getInstance(),
			selectedNode: undefined as INodeUi | undefined,
			hoverNode: undefined as INodeUi | undefined,
			searchValue: '',
		};
	},
	computed: {
		...mapGetters(['allNodes']),
		...mapGetters('imbrace', ['showSidebarMenu']),
		displayNodes(): INodeUi[] {
			return this.nodes.filter(
				(node: INodeUi) =>
					(node?.parameters?.icsTitle as string)?.toLowerCase()?.indexOf(this.searchValue) !== -1,
			);
		},
	},
	watch: {
		active(val) {
			if (!val) {
				this.selectedNode = undefined;
				this.hoverNode = undefined;
				this.searchValue = '';
			}
		},
	},
	methods: {
		onClickOutside(e: Event) {
			if (e.type === 'click') {
				this.$emit('closeNodeList');
			}
		},
		onNodeClick(node: INodeUi) {
			const { offset, zoomLevel } = CanvasHelpers.getZoomToFit([node], true);
			this.selectedNode = node;
			this.$emit('nodeSelectedByName', node.name);
			this.$emit('setZoomLevel', zoomLevel);
			this.$store.commit('setNodeViewOffsetPosition', { newOffset: offset });
		},
		selectNode() {
			if (this.hoverNode) {
				this.$emit('deselectAllNodes');
				this.$emit('nodeSelectedByName', this.hoverNode.name);
			}
		},
		onNodeHover(node: INodeUi) {
			this.hoverNode = node;
			this.callDebounced('selectNode', { debounceTime: 500 });
		},
		onSearch(e: Event) {
			const el = e.target as HTMLInputElement;
			this.searchValue = el.value;
		},
		getNodeType(node: INodeUi) {
			return this.$store.getters.nodeType(node.type, node.typeVersion);
		},
	},
});
