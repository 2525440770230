
import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import { genericHelpers } from '../mixins/genericHelpers';

export default mixins(genericHelpers).extend({
	name: 'Search',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		onChange: {
			type: Function as PropType<(e: Event) => void>,
			default: undefined,
		},
		onClear: {
			type: Function as PropType<() => void>,
			default: undefined,
		},
		value: {
			type: String,
			default: undefined,
		},
	},
	methods: {
		handleInput(event: Event) {
			this.$emit('onChange', event);
		},
		clear() {
			this.$emit('onClear');
		},
	},
});
