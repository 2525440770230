var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-xs",attrs:{"data-test-id":"mapping-fields-container"}},[_c('n8n-input-label',{attrs:{"label":_vm.valuesLabel,"underline":true,"size":_vm.labelSize,"showOptions":true,"showExpressionSelector":false,"inputName":"columns"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('parameter-options',{attrs:{"parameter":_vm.parameter,"customActions":_vm.parameterActions,"loading":_vm.refreshInProgress,"loadingMessage":_vm.fetchingFieldsLabel},on:{"optionSelected":_vm.onParameterActionSelected}})]},proxy:true}])}),(_vm.orderedFields.length === 0)?_c('div',{staticClass:"mt-3xs mb-xs"},[_c('n8n-text',{attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.$locale.baseText('fixedCollectionParameter.currentlyNoItemsExist')))])],1):_vm._e(),_vm._l((_vm.orderedFields),function(field){return _c('div',{key:field.name,class:{
			['parameter-item']: true,
			[_vm.$style.parameterItem]: true,
			// @ts-ignore
			[_vm.$style.hasIssues]: _vm.getFieldIssues(field).length > 0,
		}},[(_vm.resourceMapperMode === 'add' && field.required)?_c('div',{class:['delete-option', 'mt-5xs', _vm.$style.parameterTooltipIcon]},[_c('n8n-tooltip',{attrs:{"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$locale.baseText('resourceMapper.mandatoryField.title', { interpolate: { fieldWord: _vm.singularFieldWord }, })))])]},proxy:true}],null,true)},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1)],1):(
				!_vm.isMatchingField(field.name, _vm.paramValue.matchingColumns, _vm.showMatchingColumnsSelector)
			)?_c('div',{class:['delete-option', 'clickable', 'mt-5xs']},[_c('font-awesome-icon',{attrs:{"icon":"trash","title":_vm.$locale.baseText('resourceMapper.removeField', {
						interpolate: {
							fieldWord: _vm.singularFieldWordCapitalized,
						},
					}),"data-test-id":`remove-field-button-${_vm.getParsedFieldName(field.name)}`},on:{"click":function($event){return _vm.removeField(field.name)}}})],1):_vm._e(),_c('div',{class:_vm.$style.parameterInput},[_c('parameter-input-full',{class:_vm.$style.parameterInputFull,attrs:{"parameter":field,"value":_vm.getParameterValue(field.name),"displayOptions":true,"path":`${_vm.path}.${field.name}`,"isReadOnly":_vm.refreshInProgress || field.readOnly,"hideIssues":true,"nodeValues":_vm.nodeValues},on:{"valueChanged":_vm.onValueChanged}})],1),(_vm.getFieldIssues(field).length > 0)?_c('parameter-issues',{class:[_vm.$style.parameterIssues, 'ml-5xs'],attrs:{"issues":_vm.getFieldIssues(field)}}):_vm._e()],1)}),_c('div',{class:['add-option', _vm.$style.addOption],attrs:{"data-test-id":"add-fields-select"}},[_c('n8n-select',{attrs:{"placeholder":_vm.$locale.baseText('resourceMapper.addFieldToSend', {
					interpolate: { fieldWord: _vm.singularFieldWordCapitalized },
				}),"size":"small","teleported":_vm.teleported,"disabled":_vm.addFieldOptions.length == 0},on:{"change":_vm.addField}},_vm._l((_vm.addFieldOptions),function(item){return _c('n8n-option',{key:item.value,attrs:{"label":item.name,"value":item.value,"disabled":item.disabled}})}),1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }