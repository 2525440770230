import {
	AUTOMATION_WORKFLOW_TYPE,
	BOARD_AUTOMATION_WORKFLOW_TYPE,
	CHANNEL_ACTIONS_CATEGORY,
	CHANNEL_AUTOMATION_WORKFLOW_TYPE,
	CHANNEL_TRIGGERS_CATEGORY,
	CHANNEL_TYPE,
	CHANNEL_WORKFLOW_TYPE,
	DEFAULT_WORKFLOW_SOURCE,
	DEFAULT_WORKFLOW_TYPE,
	PRESET_WORKFLOW_TYPE,
	TRIGGERS_CATEGORY,
	WEBHOOK_NODE_TYPE,
} from '@/constants';
import { INodeTypeDescription } from 'n8n-workflow';
import { Module } from 'vuex';
import {
	ChannelType,
	IBoard,
	IImbraceAccount,
	IImbraceChannel,
	IImbracePayStatus,
	IImbraceState,
	ILockWorkflowFeature,
	IMember,
	INodeStyleVersion,
	IRootState,
	IWorkflowShortResponse,
	WorkflowSource,
	WorkflowType,
	workflowOperations,
} from '../Interface';

const module: Module<IImbraceState, IRootState> = {
	namespaced: true,
	state: {
		isProduction: false,
		showDebugElements: true,
		showSidebarMenu: false,
		access_token: '',
		shouldModalClose: false,
		// Account
		imbraceAccountData: null,
		imbracePayStatus: null,
		// Type
		currentWorkflowType: DEFAULT_WORKFLOW_TYPE,
		currentChannelType: null,
		// Source
		currentWorkflowSource: DEFAULT_WORKFLOW_SOURCE,
		// Name
		needToSetDefaultName: false,
		// Channel
		imbraceChannel: null,
		// Active
		isActiveLoading: false,
		updateActiveChanged: false,
		hideActiveSwitch: false,
		isActiveDisabled: false,
		// Preset
		presetData: {},
		isAddingPreset: false,
		isAddingNewNode: false,
		presetWorkflows: [],
		// Node
		nodeStyleVersion: null,
		availableNodeTypes: [],
		// Team Member
		allMembers: null,
		// Board
		allBoards: null,
	},
	mutations: {
		checkEnv(state: IImbraceState, value: boolean) {
			state.isProduction = value;
		},
		setShowDebugElements(state: IImbraceState, value: boolean) {
			state.showDebugElements = value;
		},
		setShowSidebarMenu(state: IImbraceState, value: boolean) {
			state.showSidebarMenu = value;
		},
		setAccessToken(state: IImbraceState, value: string | (string | null)[]) {
			state.access_token = value;
		},
		setShouldModalClose(state: IImbraceState, value: boolean) {
			state.shouldModalClose = value;
		},
		// Account
		setImbraceAccountData(state: IImbraceState, data: IImbraceAccount) {
			state.imbraceAccountData = data;
		},
		setImbracePayStatus(state: IImbraceState, data: IImbracePayStatus) {
			state.imbracePayStatus = data;
		},
		// Type
		setCurrentWorkflowType(state: IImbraceState, type: WorkflowType) {
			state.currentWorkflowType = type;
		},
		setCurrentChannelType(state: IImbraceState, value: ChannelType) {
			state.currentChannelType = value;
		},
		// Source
		setCurrentWorkflowSource(state: IImbraceState, source: WorkflowSource) {
			state.currentWorkflowSource = source;
		},
		// Name
		setNeedToSetDefaultName(state: IImbraceState, value: boolean) {
			state.needToSetDefaultName = value;
		},
		// Channel
		setImbraceChannel(state: IImbraceState, data: IImbraceChannel) {
			state.imbraceChannel = data;
		},
		// Active
		setIsActiveLoading(state: IImbraceState, value: boolean) {
			state.isActiveLoading = value;
		},
		setUpdateActiveChanged(state: IImbraceState, value: boolean) {
			state.updateActiveChanged = value;
		},
		setHideActiveSwitch(state: IImbraceState, value: boolean) {
			state.hideActiveSwitch = value;
		},
		setIsActiveDisabled(state: IImbraceState, value: boolean) {
			state.isActiveDisabled = value;
		},
		// Preset
		setPresetData(state: IImbraceState, data: object) {
			state.presetData = data;
		},
		addNewPreset(state: IImbraceState, status: boolean) {
			state.isAddingPreset = status;
		},
		addNewNode(state: IImbraceState, value: boolean) {
			state.isAddingNewNode = value;
		},
		setPresetWorkflows(state: IImbraceState, data: IWorkflowShortResponse[]) {
			state.presetWorkflows = data;
		},
		// Node
		setNodeStyleVersion (state: IImbraceState, version: INodeStyleVersion | null) {
			state.nodeStyleVersion = version;
		},
		setAvailableNodeTypes(state: IImbraceState, nodeTypes: INodeTypeDescription[]) {
			state.availableNodeTypes = nodeTypes;
		},
		// Team Member
		setAllMembers(state: IImbraceState, data: IMember[]) {
			state.allMembers = data;
		},
		// Board
		setAllBoards(state: IImbraceState, data: IBoard[]) {
			state.allBoards = data;
		},
	},
	getters: {
		isProduction: (state: IImbraceState): boolean => {
			return state.isProduction;
		},
		showDebugElements: (state: IImbraceState): boolean => {
			return state.showDebugElements;
		},
		showSidebarMenu: (state: IImbraceState): boolean => {
			return state.showSidebarMenu;
		},
		accessToken: (state: IImbraceState): string | (string | null)[] => {
			return state.access_token;
		},
		shouldModalClose: (state: IImbraceState): boolean => {
			return state.shouldModalClose;
		},
		parentOrigin: (state: IImbraceState): string | undefined | null => {
			if (!state.access_token) return null;
			if (state.isProduction) {
				return process.env.VUE_APP_PARENTS_DOMAIN;
			}
			if (location.ancestorOrigins['0'] === 'http://localhost:3000') {
				return 'http://localhost:3000';
			}
			return null;
		},
		// imbrace account data
		organizationID: (state: IImbraceState) => {
			return state.imbraceAccountData?.organization_id;
		},
		isFeatureLock: (state: IImbraceState) => {
			return ({
				automationWorkflowOperation,
				activeConnectorGroups,
				activeConnector,
			}: {
				automationWorkflowOperation: workflowOperations;
				activeConnectorGroups: string;
				activeConnector: Record<string, number>;
			}) => {
				// TODO: data used for development testing should be deleted after the connector list is finalized.

				// === local - fake test data
				// const lockFeatures: ILockWorkflowFeature = {
				// 	"channel_workflow": {
				// 		"count": 1,
				// 	},
				// 	"connector_groups": ['integrations', 'actions'],
				// 	"connectors": {
				// 		'n8n-nodes-base.askAQuestion': {
				// 			count: 1,
				// 		},
				// 		'n8n-nodes-base.asana': {
				// 			count: 0,
				// 		},
				// 		'n8n-nodes-base.function': {
				// 			count: 2,
				// 		},
				// 	},
				// 	"automation_workflow": {
				// 		"operation": [
				// 			"active",
				// 		],
				// 	},
				// 	"data_boards_automation": {
				// 		"operation": [
				// 			"create",
				// 		],
				// 	},
				// };

				// === dev - imbrace
				// const lockFeatures: ILockWorkflowFeature = {
				// 	"channel_workflow": {
				// 		"count": 1,
				// 	},
				// 	"connector_groups": [],
				// 	"connectors": {},
				// 	"automation_workflow": {
				// 		"operation": [
				// 			"",
				// 		],
				// 	},
				// 	"data_boards_automation": {
				// 		"operation": [
				// 			"create",
				// 		],
				// 	},
				// };

				// === dev - new org
				// const lockFeatures: ILockWorkflowFeature =  {
				// 	"channel_workflow": {
				// 		"count": 1,
				// 	},
				// 	"automation_workflow": {
				// 		"operation": [
				// 			"active",
				// 		],
				// 	},
				// 	"data_boards_automation": {
				// 		"operation": [
				// 			"create",
				// 		],
				// 	},
				// 	"connector_groups": [],
				// };

				// =====================
				// ***** IMPORTANT *****
				// When local, for development purposes we do not enable unlock checks.
				// If you want to test the unlocking function, please comment out the following line
				// And please uncomment the code before going online.
				if (!state.isProduction) return false;
				// =====================

				const lockFeatures: ILockWorkflowFeature | null =
					state.imbraceAccountData?.organization_lock_features?.workflows ?? null;

				if (!lockFeatures) return !state.imbracePayStatus?.is_paid_user;

				if (automationWorkflowOperation && lockFeatures.automation_workflow) {
					return (
						state.currentWorkflowType === AUTOMATION_WORKFLOW_TYPE &&
						lockFeatures.automation_workflow.operation?.includes(automationWorkflowOperation)
					);
				}

				if (
					activeConnectorGroups &&
					lockFeatures.connector_groups &&
					Array.isArray(lockFeatures.connector_groups)
				) {
					return lockFeatures.connector_groups.includes(activeConnectorGroups);
				}

				if (activeConnector) {
					// At present, we only decide whether users can use all nodes based on whether they pay or not.
					// So I'll comment out the following code for now.
					// if (lockFeatures.connectors) {
					// 	const { connectors } = lockFeatures;
					// 	const lockedConnectorNames = Object.keys(connectors);
					// 	const activeConnectorName = Object.keys(activeConnector)[0];
					// 	const activeConnectorCount = activeConnector[activeConnectorName];

					// 	// if connectorCount is -1, we just want to know if the connector is locked
					// 	if (activeConnectorCount < 0) {
					// 		return lockedConnectorNames.includes(activeConnectorName);
					// 	}

					// 	if ((connectors as {[key: string]: {count: number}})[activeConnectorName]?.count >= 0) {
					// 		return activeConnectorCount >= (connectors as {[key: string]: {count: number}})[activeConnectorName]?.count;
					// 	}
					// }

					// According to the latest requirements:
					// Free users: need to unlock to add any node
					// Paid users: can use nodes without restrictions
					return state.imbracePayStatus?.is_paid_user ? false : true;
				}

				return false;
			};
		},
		isImbracePaidUser: (state: IImbraceState): boolean => {
			// When local, for development purposes we do not enable paid user checks.
			// If you want to test related functions, please comment out the following line
			// And please uncomment the code before going online.
			if (!state.isProduction) {
				return true;
			}

			return !!state.imbracePayStatus?.is_paid_user;
		},
		// Type
		currentWorkflowType: (state: IImbraceState): WorkflowType => {
			return state.currentWorkflowType;
		},
		currentChannelType: (state: IImbraceState): ChannelType | null => {
			return state.currentChannelType;
		},
		// Source
		currentWorkflowSource: (state: IImbraceState): WorkflowSource => {
			return state.currentWorkflowSource;
		},
		// Name
		needToSetDefaultName: (state: IImbraceState): boolean => {
			return state.needToSetDefaultName;
		},
		imbraceChannel: (state: IImbraceState): IImbraceChannel | null => {
			return state.imbraceChannel;
		},
		// Active
		isActiveLoading: (state: IImbraceState): boolean => {
			return state.isActiveLoading;
		},
		updateActiveChanged: (state: IImbraceState): boolean => {
			return state.updateActiveChanged;
		},
		hideActiveSwitch: (state: IImbraceState): boolean => {
			return state.hideActiveSwitch;
		},
		isActiveDisabled: (state: IImbraceState): boolean => {
			return state.isActiveDisabled;
		},
		// Preset
		presetData: (state: IImbraceState): object => {
			return state.presetData;
		},
		isAddingPreset: (state: IImbraceState): boolean => {
			return state.isAddingPreset;
		},
		isAddingNewNode: (state: IImbraceState): boolean => {
			return state.isAddingNewNode;
		},
		presetWorkflows: (state: IImbraceState): IWorkflowShortResponse[] => {
			return state.presetWorkflows;
		},
		// Starting flow
		hideStartNode: (state: IImbraceState): boolean => {
			const hideStartNodeWorkflowType = [
				AUTOMATION_WORKFLOW_TYPE,
				CHANNEL_WORKFLOW_TYPE,
				CHANNEL_AUTOMATION_WORKFLOW_TYPE,
				BOARD_AUTOMATION_WORKFLOW_TYPE,
			];
			if (hideStartNodeWorkflowType.includes(state.currentWorkflowType)) {
				return true;
			}
			return false;
		},
		startingFlow: (state: IImbraceState): string[] => {
			// All starting flow (other than hint) can’t be delete or removed.
			if (state.currentWorkflowType === CHANNEL_WORKFLOW_TYPE && state.currentChannelType) {
				return [WEBHOOK_NODE_TYPE];
			}
			if (state.currentWorkflowType === CHANNEL_AUTOMATION_WORKFLOW_TYPE) {
				return [WEBHOOK_NODE_TYPE];
			}
			if (state.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
				return [WEBHOOK_NODE_TYPE];
			}
			return [];
		},
		starterNodeTitle: (state: IImbraceState): string | null => {
			if (state.currentChannelType) {
				return CHANNEL_TYPE.find((channel) => channel.tag === state.currentChannelType)!.title;
			}
			return null;
		},
		// Node
		nodeStyleVersion: (state: IImbraceState): null | INodeStyleVersion => {
			return state.nodeStyleVersion;
		},
		getColorByCategory: (state: IImbraceState) => {
			return (categories: string[]) => {
				const categoryColors: {
					[key: string]: string;
				} = {
					'Actions': '#ECFAEC',
					'Channel Actions': '#ECFAEC',
					'Integrations': '#FEF5E8',
					'Functions': '#F3EFF9',
					'Logics': '#F3EFF9',
				};
				
				for (const category of categories) {
					if (categoryColors[category]) {
						return categoryColors[category];
					}
				}
				return null;
			};
		},
		availableNodeTypes: (state: IImbraceState): INodeTypeDescription[] => {
			return state.availableNodeTypes;
		},
		excludeCategories: (state: IImbraceState): string[] => {
			const categories: string[] = [];
			switch (state.currentWorkflowType) {
				case AUTOMATION_WORKFLOW_TYPE:
					categories.push(CHANNEL_TRIGGERS_CATEGORY, CHANNEL_ACTIONS_CATEGORY);
					break;
				case BOARD_AUTOMATION_WORKFLOW_TYPE:
				case PRESET_WORKFLOW_TYPE:
					categories.push(CHANNEL_TRIGGERS_CATEGORY, TRIGGERS_CATEGORY, CHANNEL_ACTIONS_CATEGORY);
					break;
				case CHANNEL_WORKFLOW_TYPE:
					categories.push(CHANNEL_TRIGGERS_CATEGORY, TRIGGERS_CATEGORY);
					break;
				case CHANNEL_AUTOMATION_WORKFLOW_TYPE:
					categories.push(CHANNEL_TRIGGERS_CATEGORY, TRIGGERS_CATEGORY);
					break;
				default:
					break;
			}
			return categories;
		},
		parameterInputType: (state: IImbraceState) => {
			return (valueType: string) => {
				const typeMapping: Record<string, string> = {
					Number: 'number',
					Date: 'date',
					Time: 'time',
					Datetime: 'dateTime',
					MultipleSelection: 'multiOptions',
					SingleSelection: 'options',
					Assignee: 'options',
					Priority: 'options',
					Country: 'options',
				};
				
				return typeMapping[valueType] || 'string';
			};
		},
		// Team Member
		allMembers: (state: IImbraceState): IMember[] | null => {
			return state.allMembers;
		},
		// Board
		allBoards: (state: IImbraceState): IBoard[] | null => {
			return state.allBoards;
		},
	},
	actions: {},
};

export default module;
