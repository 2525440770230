import { render, staticRenderFns } from "./NodeCreator.vue?vue&type=template&id=60b74579&scoped=true"
import script from "./NodeCreator.vue?vue&type=script&lang=ts"
export * from "./NodeCreator.vue?vue&type=script&lang=ts"
import style0 from "./NodeCreator.vue?vue&type=style&index=0&id=60b74579&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b74579",
  null
  
)

export default component.exports