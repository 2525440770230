
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { showMessage } from '@/components/mixins/showMessage';

export default mixins(showMessage).extend({
	name: 'Layer',
	props: ['errorMessage'],
	methods: {
		showMessage() {
			if (!this.errorMessage) return;

			let title = '';
			let message = '';

			if (this.errorMessage === 'needToSetDefaultName') {
				title = this.$locale.baseText('workflowDetails.showMessage.nameMissing.title');
				message = this.$locale.baseText('workflowDetails.showMessage.nameMissing.message.enterName');
			}

			this.$showMessage({
				title: title,
				message: message,
				type: 'error',
			});
		},
	},
});
