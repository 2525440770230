
import type {
	FieldType,
	INodeIssues,
	INodeParameters,
	INodeProperties,
	NodePropertyTypes,
	ResourceMapperField,
	ResourceMapperValue,
	ResourceMapperTypeOptions,
} from 'n8n-workflow';
import mixins from 'vue-typed-mixins';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ParameterInputFull from '@/components/ParameterInputFull.vue';
import ParameterIssues from '@/components//ParameterIssues.vue';
import ParameterOptions from '@/components//ParameterOptions.vue';
import { fieldCannotBeDeleted, isMatchingField, parseResourceMapperFieldName } from '@/utils';
import type { IUpdateInformation } from '@/Interface';

const FORCE_TEXT_INPUT_FOR_TYPES: FieldType[] = [ 'object', 'array'];

export default mixins(nodeHelpers, workflowHelpers, showMessage, externalHooks).extend({
	name: 'MappingFields',
	props: {
		parameter: Object as () => INodeProperties,
		path: String,
		nodeValues: Object as () => INodeParameters,
		fieldsToMap: Array as () => ResourceMapperField[],
		paramValue: Object as () => ResourceMapperValue,
		labelSize: String,
		showMatchingColumnsSelector: Boolean,
		showMappingModeSelect: Boolean,
		loading: Boolean,
		refreshInProgress: Boolean,
		teleported: Boolean,
	},
	components: {
		ParameterInputFull,
		ParameterIssues,
		ParameterOptions,
	},

	computed: {
		resourceMapperTypeOptions(): ResourceMapperTypeOptions | undefined {
			return this.parameter.typeOptions?.resourceMapper;
		},

		singularFieldWord(): string {
			const singularFieldWord =
				this.resourceMapperTypeOptions?.fieldWords?.singular ||
				this.$locale.baseText('generic.field');
			return singularFieldWord;
		},

		singularFieldWordCapitalized(): string {
			return this.singularFieldWord.charAt(0).toUpperCase() + this.singularFieldWord.slice(1);
		},

		pluralFieldWord(): string {
			return (
				this.resourceMapperTypeOptions?.fieldWords?.plural ||
				this.$locale.baseText('generic.fields')
			);
		},

		pluralFieldWordCapitalized(): string {
			return this.pluralFieldWord.charAt(0).toUpperCase() + this.pluralFieldWord.slice(1);
		},

		fieldsUi(): Array<Partial<INodeProperties> & { readOnly?: boolean }> {
			return this.fieldsToMap
				.filter((field) => field.display !== false && field.removed !== true)
				.map((field) => {
					return {
						displayName: this.getFieldLabel(field),
						// Set part of the path to each param name so value can be fetched properly by input parameter list component
						name: `value["${field.id}"]`,
						type: this.getParamType(field),
						default: field.type === 'boolean' ? false : '',
						required: field.required,
						description: this.getFieldDescription(field),
						options: field.options,
						readOnly: this.markAsReadOnly(field),
					};
				});
		},

		orderedFields(): Array<Partial<INodeProperties> & { readOnly?: boolean }> {
			// Sort so that matching columns are first
			if (this.paramValue.matchingColumns) {
				this.fieldsUi.forEach((field, i) => {
					const fieldName = field.name && parseResourceMapperFieldName(field.name);
					if (fieldName) {
						if (this.paramValue.matchingColumns.includes(fieldName)) {
							this.fieldsUi.splice(i, 1);
							this.fieldsUi.unshift(field);
						}
					}
				});
			}

			return this.fieldsUi;
		},

		removedFields(): ResourceMapperField[] {
			return this.fieldsToMap.filter((field) => field.removed === true && field.display !== false);
		},

		addFieldOptions(): Array<{ name: string; value: string; disabled?: boolean }> {
			return this.removedFields.map((field) => {
				return {
					name: field.displayName,
					value: field.id,
					disabled: false,
				};
			});
		},

		parameterActions(): Array<{ label: string; value: string; disabled?: boolean }> {
			return [
				{
					label: this.$locale.baseText('resourceMapper.refreshFieldList', {
						interpolate: { fieldWord: this.singularFieldWordCapitalized },
					}),
					value: 'refreshFieldList',
				},
				{
					label: this.$locale.baseText('resourceMapper.addAllFields', {
						interpolate: { fieldWord: this.pluralFieldWordCapitalized },
					}),
					value: 'addAllFields',
					disabled: this.removedFields.length === 0,
				},
				{
					label: this.$locale.baseText('resourceMapper.removeAllFields', {
						interpolate: { fieldWord: this.pluralFieldWordCapitalized },
					}),
					value: 'removeAllFields',
					disabled: this.isRemoveAllAvailable === false,
				},
			];
		},

		isRemoveAllAvailable(): boolean {
			return (
				this.removedFields.length !== this.fieldsToMap.length &&
				this.fieldsToMap.some((field) => {
					return (
						field.removed !== true &&
						!fieldCannotBeDeleted(
							field,
							this.showMatchingColumnsSelector,
							this.resourceMapperMode,
							this.paramValue.matchingColumns,
						)
					);
				})
			);
		},

		resourceMapperMode(): string | undefined {
			return this.resourceMapperTypeOptions?.mode;
		},

		resourceMapperValuesLabel(): string | undefined {
			return this.resourceMapperTypeOptions?.valuesLabel;
		},

		valuesLabel(): string {
			if (this.resourceMapperValuesLabel) {
				return this.resourceMapperValuesLabel;
			}
			if (this.resourceMapperMode && this.resourceMapperMode === 'update') {
				return this.$locale.baseText('resourceMapper.valuesToUpdate.label');
			}
			return this.$locale.baseText('resourceMapper.valuesToSend.label');
		},

		fetchingFieldsLabel(): string {
			return this.$locale.baseText('resourceMapper.fetchingFields.message', {
				interpolate: {
					fieldWord: this.pluralFieldWord,
				},
			});
		},
	},

	// Methods
	methods: {
		isMatchingField(
			field: string,
			matchingFields: string[],
			showMatchingColumnsSelector: boolean,
		): boolean {
			return isMatchingField(field, matchingFields, showMatchingColumnsSelector);
		},

		getFieldLabel(field: ResourceMapperField): string {
			if (
				isMatchingField(field.id, this.paramValue.matchingColumns, this.showMatchingColumnsSelector)
			) {
				const suffix = this.$locale.baseText('resourceMapper.usingToMatch') || '';
				return `${field.displayName} ${suffix}`;
			}
			return field.displayName;
		},

		getFieldDescription(field: ResourceMapperField): string {
			if (
				isMatchingField(field.id, this.paramValue.matchingColumns, this.showMatchingColumnsSelector)
			) {
				return (
					this.$locale.baseText('resourceMapper.usingToMatch.description', {
						interpolate: {
							fieldWord: this.singularFieldWord,
						},
					}) || ''
				);
			}
			return '';
		},

		getParameterValue(parameterName: string): string[] | string | number | boolean | null {
			const fieldName = parseResourceMapperFieldName(parameterName);
			if (fieldName && this.paramValue.value) {
				if (
					this.paramValue.value[fieldName] === undefined ||
					this.paramValue.value[fieldName] === null
				) {
					return '';
				}
				return this.paramValue.value[fieldName];
			}
			return null;
		},

		// TODO: think about how to migrate field issues and parameter issues
		getFieldIssues(field: INodeProperties): string[] {
			if (!this.$store.getters.activeNode) return [];

			const nodeIssues = this.$store.getters.activeNode.issues || ({} as INodeIssues);
			const fieldName = parseResourceMapperFieldName(field.name);
			if (!fieldName) return [];

			let fieldIssues: string[] = [];
			const key = `${this.parameter.name}.${fieldName}`;
			if (nodeIssues['parameters'] && key in nodeIssues['parameters']) {
				fieldIssues = fieldIssues.concat(nodeIssues['parameters'][key]);
			}
			return fieldIssues;
		},

		getParamType(field: ResourceMapperField): NodePropertyTypes {
			if (field.type && !FORCE_TEXT_INPUT_FOR_TYPES.includes(field.type)) {
				return field.type as NodePropertyTypes;
			}
			return 'string';
		},

		getParsedFieldName(fullName: string): string {
			return parseResourceMapperFieldName(fullName) ?? fullName;
		},

		onValueChanged(value: IUpdateInformation): void {
			this.$emit('fieldValueChanged', value);
		},

		removeField(fieldName: string) {
			this.$emit('removeField', fieldName);
		},

		addField(fieldName: string) {
			this.$emit('addField', fieldName);
		},

		onParameterActionSelected(action: string): void {
			switch (action) {
				case 'addAllFields':
					this.$emit('addField', action);
					break;
				case 'removeAllFields':
					this.$emit('removeField', action);
					break;
				case 'refreshFieldList':
					this.$emit('refreshFieldList');
					break;
				default:
					break;
			}
		},

		markAsReadOnly(field: ResourceMapperField): boolean {
			if (
				isMatchingField(field.id, this.paramValue.matchingColumns, this.showMatchingColumnsSelector)
			) {
				return false;
			}
			return field.readOnly || false;
		},
	},
});
