

import { externalHooks } from '@/components/mixins/externalHooks';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';
import { mapGetters } from "vuex";

import {
	WORKFLOW_ACTIVE_MODAL_KEY,
	LOCAL_STORAGE_ACTIVATION_FLAG,CHANNEL_WORKFLOW_TYPE, DEFAULT_WORKFLOW_TYPE, ACTIVE_ERROR_MODAL_KEY,
} from '@/constants';
import { getActivatableTriggerNodes } from './helpers';


export default mixins(
	externalHooks,
	genericHelpers,
	restApi,
	showMessage,
	workflowHelpers,
)
	.extend(
		{
			name: 'WorkflowActivator',
			props: [
				'workflowActive',
				'workflowId',
				'isActiveLocked',
			],
			data () {
				return {
					loading: false,
					access_token: '' as string | (string | null)[],
				};
			},
			computed: {
				...mapGetters({
					dirtyState: "getStateIsDirty",
				}),
				...mapGetters('imbrace', ['currentWorkflowType', 'currentChannelType', 'parentOrigin']),
				nodesIssuesExist (): boolean {
					return this.$store.getters.nodesIssuesExist;
				},
				isWorkflowActive (): boolean {
					const activeWorkflows = this.$store.getters.getActiveWorkflows;
					return activeWorkflows.includes(this.workflowId);
				},
				couldNotBeStarted (): boolean {
					return this.workflowActive === true && this.isWorkflowActive !== this.workflowActive;
				},
				getActiveColor (): string {
					if (this.couldNotBeStarted === true) {
						return '#ff4949';
					}
					return '#3399fc';
				},
				isCurrentWorkflow(): boolean {
					return this.$store.getters['workflowId'] === this.workflowId;
				},
				disabled(): boolean {
					const isNewWorkflow = !this.workflowId;
					if (isNewWorkflow || this.isCurrentWorkflow) {
						const returnValue = !this.workflowActive && !this.containsTrigger;
						this.$store.commit('imbrace/setIsActiveDisabled', returnValue);
						return returnValue;
					}

					this.$store.commit('imbrace/setIsActiveDisabled', false);
					return false;
				},
				containsTrigger(): boolean {
					const foundTriggers = getActivatableTriggerNodes(this.$store.getters.workflowTriggerNodes);
					return foundTriggers.length > 0;
				},
				isActive(): boolean {
					return this.$store.getters.isActive;
				},
				updateActiveChanged(): boolean {
					return this.$store.getters['imbrace/updateActiveChanged'];
				},
			},
			watch: {
				updateActiveChanged() {
					// In channel WF, channel automation WF and board automation WF 
					// every time user save WF, we will automatically active WF
					if (this.updateActiveChanged) {
						this.activeChanged(this.isActive);
						this.$store.commit('imbrace/setUpdateActiveChanged', false);
					}

					if (!this.updateActiveChanged) {
						if (this.nodesIssuesExist) {
						// when workflow activated with error, postMessage back to parent
							if (this.parentOrigin) {
								window.parent.postMessage({
									type: "saveWithErrorAction",
								}, this.parentOrigin);
							}
						}
					}
				},
			},
			methods: {
				async activeChanged (newActiveState: boolean) {
					this.loading = true;
					this.$store.commit('imbrace/setIsActiveLoading', true);

					if (!this.workflowId) {
						const saved = await this.saveCurrentWorkflow();
						if (!saved) {
							this.loading = false;
							this.$store.commit('imbrace/setIsActiveLoading', false);
							return;
						}
					}

					try {
						// if active is locked, send a postMessage to the dashboard and the workflow does not allow active 
						if (this.isActiveLocked) {
							if (this.parentOrigin) {
								window.parent.postMessage({
									type: "lockAction",
								}, this.parentOrigin);
							}

							this.loading = false;
							this.$store.commit('imbrace/setIsActiveLoading', false);
							return;
						}

						if (this.isCurrentWorkflow && this.nodesIssuesExist) {
							this.$store.dispatch('ui/openModal', ACTIVE_ERROR_MODAL_KEY);
							// this.$showMessage({
							// 	title: this.$locale.baseText('workflowActivator.showMessage.activeChangedNodesIssuesExistTrue.title'),
							// 	message: this.$locale.baseText('workflowActivator.showMessage.activeChangedNodesIssuesExistTrue.message'),
							// 	type: 'error',
							// });

							this.loading = false;
							this.$store.commit('imbrace/setIsActiveLoading', false);
							return;
						}

						await this.updateWorkflow({workflowId: this.workflowId, active: newActiveState});

						// when workflow is successfully saved, postMessage back to parent
						if (this.parentOrigin) {
							window.parent.postMessage({
								type: "saveCompletedAction",
							}, this.parentOrigin);

							const shouldModalClose = this.$store.getters['imbrace/shouldModalClose'];
							if (shouldModalClose) {
								window.parent.postMessage({
									type: "closeAction",
								}, this.parentOrigin);
								this.$store.commit('imbrace/setShouldModalClose', false);
							}
						}

						// In channel WF, old logic need to call backend API to update and sync channel active status
						// if (this.currentWorkflowType === CHANNEL_WORKFLOW_TYPE && !!this.access_token) {
						// 	await this.updateWorkflow({workflowId: this.workflowId, active: newActiveState, token: this.access_token});
						// } else {
						// 	await this.updateWorkflow({workflowId: this.workflowId, active: newActiveState});
						// }
					} catch (error) {
						const newStateName = newActiveState === true ? 'activated' : 'deactivated';
						this.$showError(
							error,
							this.$locale.baseText(
								'workflowActivator.showError.title',
								{ interpolate: { newStateName } },
							) + ':',
						);
						this.loading = false;
						this.$store.commit('imbrace/setIsActiveLoading', false);
						return;
					}

					const activationEventName = this.isCurrentWorkflow ? 'workflow.activeChangeCurrent' : 'workflow.activeChange';
					this.$externalHooks().run(activationEventName, { workflowId: this.workflowId, active: newActiveState });
					//this.$telemetry.track('User set workflow active status', { workflow_id: this.workflowId, is_active: newActiveState });

					this.$emit('workflowActiveChanged', { id: this.workflowId, active: newActiveState });
					this.loading = false;
					this.$store.commit('imbrace/setIsActiveLoading', false);

					// Hide "workflow activated" modal except default workflow type
					if (this.isCurrentWorkflow && this.currentWorkflowType === DEFAULT_WORKFLOW_TYPE) {
						if (newActiveState && window.localStorage.getItem(LOCAL_STORAGE_ACTIVATION_FLAG) !== 'true') {
							this.$store.dispatch('ui/openModal', WORKFLOW_ACTIVE_MODAL_KEY);
						}
						else {
							this.$store.dispatch('settings/fetchPromptsData');
						}
					}
				},
				async displayActivationError () {
					let errorMessage: string;
					try {
						const errorData = await this.restApi().getActivationError(this.workflowId);

						if (errorData === undefined) {
							errorMessage = this.$locale.baseText('workflowActivator.showMessage.displayActivationError.message.errorDataUndefined');
						} else {
							let message = errorData.error.message;

							// If error message is "The node-type \"n8n-nodes-base.xxx\" is not known!"
							// Rewrite this string to be "The node \"xxx\" is not known!"
							const regex = /\".*?\"/;
							if (message.includes('The node-type') && message.includes('is not known!')) {
								const nodeType = message.match(regex);
								if (nodeType) {
									const nodeName = nodeType[0].substring(16, nodeType[0].length-1);
									message = `The node ${nodeName} is not known!`;
								}
							};

							errorMessage = this.$locale.baseText(
								'workflowActivator.showMessage.displayActivationError.message.errorDataNotUndefined',
								{ interpolate: { message: message } },
							);
						}
					} catch (error) {
						errorMessage = this.$locale.baseText('workflowActivator.showMessage.displayActivationError.message.catchBlock');
					}

					this.$showMessage({
						title: this.$locale.baseText('workflowActivator.showMessage.displayActivationError.title'),
						message: errorMessage,
						type: 'warning',
						duration: 0,
					});
				},
			},
			mounted() {
				this.access_token = this.$route.query.token;
			},
		},
	);
