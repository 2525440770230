
import Vue from 'vue';

import ImbraceModal from './ImbraceModal.vue';
import { BOARD_AUTOMATION_WORKFLOW_TYPE, DEACTIVATE_WORKFLOW_MODAL_KEY } from '../constants';

export default Vue.extend({
	name: 'DeactivateWorkflowModal',
	components: {
		ImbraceModal,
	},
	data() {
		return {
			modalBus: new Vue(),
			DEACTIVATE_WORKFLOW_MODAL_KEY,
		};
	},
	computed: {
		currentWorkflowType(): string {
			return this.$store.getters['imbrace/currentWorkflowType'];
		},
		hideActiveSwitch(): boolean {
			return this.$store.getters['imbrace/hideActiveSwitch'];
		},
		modalTitle(): string {
			if (this.hideActiveSwitch) {
				return this.$locale.baseText('deactivateWorkflowModal.title.editVer');
			}
			return this.$locale.baseText('deactivateWorkflowModal.title');
		},
		modalDescription(): string {
			if (this.hideActiveSwitch) {
				if (this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
					return this.$locale.baseText('deactivateWorkflowModal.description.autoVer');
				}
				return this.$locale.baseText('deactivateWorkflowModal.description.editVer');
			}
			return this.$locale.baseText('deactivateWorkflowModal.description');
		},
		buttonCancel(): string {
			return this.$locale.baseText('deactivateWorkflowModal.label.cancel');
		},
		buttonAction(): string {
			if (this.hideActiveSwitch) {
				return this.$locale.baseText('deactivateWorkflowModal.label.edit');
			}
			return this.$locale.baseText('deactivateWorkflowModal.label.deactivate');
		},
	},
	methods: {
		deactivateWorkflow () {
			this.$store.commit('setActive', false);
			this.modalBus.$emit('close');
		},
		onCancel() {
			this.$store.commit('setReloadWorkflow', true);
			this.modalBus.$emit('close');
		},
	},
});
