
import { get } from 'lodash-es';

import {
	IBoardField,
	INodeUi,
	INodeUpdatePropertiesInformation,
	IMember,
} from '@/Interface';
import {
	NodeHelpers,
	NodeParameterValue,
	IHttpRequestOptions,
	ILoadOptions,
	INodeParameters,
	INodePropertyOptions,
	Workflow,
} from 'n8n-workflow';

import CodeEdit from '@/components/CodeEdit.vue';
import CredentialsSelect from '@/components/CredentialsSelect.vue';
import DataDisplayToUseExpression from '@/components/DataDisplayToUseExpression.vue';
import ExpressionEdit from '@/components/ExpressionEdit.vue';
import ParameterOptions from '@/components/ParameterOptions.vue';
import ParameterIssues from '@/components/ParameterIssues.vue';
// @ts-ignore
import PrismEditor from 'vue-prism-editor';
import TextEdit from '@/components/TextEdit.vue';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';
import {
	ASK_ADD_CONTACT_INFO_NODE_TYPE,
	AUTOMATE_DATA_BOARD_NODE_TYPE,
	COUNTRIES,
	CUSTOM_API_CALL_KEY,
	EXECUTE_WORKFLOW_NODE_TYPE,
	LINE_MULTIPLE_CHOICE_NODE_TYPE,
	WEBHOOK_NODE_TYPE,
	WHATSAPP_CHOICE_LIST_NODE_TYPE,
	WHATSAPP_MULTIPLE_CHOICE_NODE_TYPE,
} from '@/constants';
import { mapGetters } from 'vuex';

export default mixins(
	externalHooks,
	nodeHelpers,
	showMessage,
	workflowHelpers,
)
	.extend({
		name: 'ParameterInput',
		components: {
			CodeEdit,
			CredentialsSelect,
			DataDisplayToUseExpression,
			ExpressionEdit,
			ParameterOptions,
			ParameterIssues,
			PrismEditor,
			TextEdit,
		},
		props: [
			'displayOptions', // boolean
			'inputSize',
			'isReadOnly',
			'documentationUrl',
			'parameter', // NodeProperties
			'path', // string
			'value',
			'hideIssues', // boolean
			'errorHighlight',
			'isForCredential', // boolean
			'eventSource', // string
			'id',
			'dynamicMappingData', // object
			'dynamicDependsOnParameterValue', // string
		],
		data () {
			return {
				codeEditDialogVisible: false,
				nodeName: '',
				expressionAddOperation: 'set' as 'add' | 'set',
				expressionEditDialogVisible: false,
				dataDisplayToUseExpressionDialogVisible: false,
				remoteParameterOptions: [] as INodePropertyOptions[],
				remoteParameterOptionsLoading: false,
				remoteParameterOptionsLoadingIssues: null as string | null,
				textEditDialogVisible: false,
				tempValue: '', //  el-date-picker, el-time-picker and el-input does not seem to work without v-model so add one
				CUSTOM_API_CALL_KEY,
				activeCredentialType: '',
				dateTimePickerOptions: {
					// iMBrace: no need shortcut
					/* shortcuts: [
						{
							text: 'Today', // TODO
							// tslint:disable-next-line:no-any
							onClick (picker: any) {
								picker.$emit('pick', new Date());
							},
						},
						{
							text: 'Yesterday', // TODO
							// tslint:disable-next-line:no-any
							onClick (picker: any) {
								const date = new Date();
								date.setTime(date.getTime() - 3600 * 1000 * 24);
								picker.$emit('pick', date);
							},
						},
						{
							text: 'A week ago', // TODO
							// tslint:disable-next-line:no-any
							onClick (picker: any) {
								const date = new Date();
								date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', date);
							},
						},
					], */
				},
				showEditWindow: true,
				expressionSource: 'expressionEditor', // 'expressionEditor' or 'dataDisplayDialog'
				dynamicParameterOptionsLoading: false,
				dynamicParameterOptions: [] as INodePropertyOptions[],
				boardFieldType: null,
			};
		},
		watch: {
			dependentParametersValues () {
				// Reload the remote parameters whenever a parameter
				// on which the current field depends on changes
				this.loadRemoteParameterOptions();
			},
			value () {
				if (this.parameterInputType === 'color' && this.getArgument('showAlpha') === true) {
					// Do not set for color with alpha else wrong value gets displayed in field
					return;
				}
				this.tempValue = this.displayValue as string;
			},
		},
		computed: {
			...mapGetters('credentials', ['allCredentialTypes']),
			...mapGetters('imbrace', ['currentChannelType']),
			areExpressionsDisabled(): boolean {
				return this.$store.getters['ui/areExpressionsDisabled'];
			},
			codeAutocomplete (): string | undefined {
				return this.getArgument('codeAutocomplete') as string | undefined;
			},
			showExpressionAsTextInput(): boolean {
				const types = ['number', 'boolean', 'dateTime', 'dateTimeRange', 'date', 'dateRange', 'time', 'timeRange', 'options', 'multiOptions', 'dynamic'];

				return types.includes(this.parameter.type);
			},
			dependentParametersValues (): string | null {
				const loadOptionsDependsOn = this.getArgument('loadOptionsDependsOn') as string[] | undefined;

				if (loadOptionsDependsOn === undefined) {
					return null;
				}

				// Get the resolved parameter values of the current node
				const currentNodeParameters = this.$store.getters.activeNode.parameters;
				const resolvedNodeParameters = this.resolveParameter(currentNodeParameters);

				const returnValues: string[] = [];
				for (const parameterPath of loadOptionsDependsOn) {
					returnValues.push(get(resolvedNodeParameters, parameterPath) as string);
				}

				return returnValues.join('|');
			},
			node (): INodeUi | null {
				return this.$store.getters.activeNode;
			},
			displayTitle (): string {
				const interpolation = { interpolate: { shortPath: this.shortPath } };

				if (this.getIssues.length && this.isValueExpression) {
					return this.$locale.baseText(
						'parameterInput.parameterHasIssuesAndExpression',
						interpolation,
					);
				} else if (this.getIssues.length && !this.isValueExpression) {
					return this.$locale.baseText(
						'parameterInput.parameterHasIssues',
						interpolation,
					);
				} else if (!this.getIssues.length && this.isValueExpression) {
					return this.$locale.baseText(
						'parameterInput.parameterHasExpression',
						interpolation,
					);
				}

				return this.$locale.baseText('parameterInput.parameter', interpolation);
			},
			displayValue (): string | number | boolean | null {
				if (this.remoteParameterOptionsLoading === true) {
					// If it is loading options from server display
					// to user that the data is loading. If not it would
					// display the user the key instead of the value it
					// represents
					return this.$locale.baseText('parameterInput.loadingOptions');
				}

				let returnValue;
				if (this.isValueExpression === false) {
					returnValue = this.value;
				} else {
					returnValue = this.expressionValueComputed;
				}

				if (this.parameter.type === 'credentialsSelect') {
					const credType = this.$store.getters['credentials/getCredentialTypeByName'](this.value);
					if (credType) {
						returnValue = credType.displayName;
					}
				}

				if (this.parameterInputType === 'color' && this.getArgument('showAlpha') === true && returnValue.charAt(0) === '#') {
					// Convert the value to rgba that el-color-picker can display it correctly
					const bigint = parseInt(returnValue.slice(1), 16);
					const h = [];
					h.push((bigint >> 24) & 255);
					h.push((bigint >> 16) & 255);
					h.push((bigint >> 8) & 255);
					h.push((255 - bigint & 255) / 255);

					returnValue = 'rgba('+h.join()+')';
				}

				// Don't know why n8n set this, just hide this for now
				// if (returnValue !== undefined && returnValue !== null && this.parameterInputType === 'string') {
				// 	const rows = this.getArgument('rows');
				// 	if (rows === undefined || rows === 1) {
				// 		returnValue = returnValue.toString().replace(/\n/, '|');
				// 	}
				// }

				return returnValue;
			},
			expressionDisplayValue (): string {
				const value = this.displayValue;

				// address type errors for text input
				if (typeof value === 'number' || typeof value === 'boolean') {
					return JSON.stringify(value);
				}

				if (value === null) {
					return '';
				}

				return value;
			},
			displayOptionsComputed (): boolean {
				if (this.isReadOnly === true) {
					return false;
				}
				if (this.parameter.type === 'collection') {
					return false;
				}

				if (this.displayOptions === true) {
					return true;
				}

				return false;
			},
			expressionValueComputed (): NodeParameterValue | string[] | null {
				if (this.areExpressionsDisabled) {
					return this.value;
				}

				if (this.node === null) {
					return null;
				}

				let computedValue: NodeParameterValue;

				try {
					computedValue = this.resolveExpression(this.value) as NodeParameterValue;
				} catch (error) {
					computedValue = `[${this.$locale.baseText('parameterInput.error')}}: ${error.message}]`;
				}

				// Try to convert it into the correct type
				if (this.parameterInputType === 'number') {
					computedValue = parseInt(computedValue as string, 10);
					if (isNaN(computedValue)) {
						return null;
					}
				}

				return computedValue;
			},
			getStringInputType (): string {
				if (this.getArgument('password') === true) {
					return 'password';
				}

				const rows = this.getArgument('rows');
				if (rows !== undefined && rows >= 1) {
					return 'textarea';
				}

				return 'text';
			},
			getIssues (): string[] {
				if (this.hideIssues === true || this.node === null) {
					return [];
				}

				const newPath = this.shortPath.split('.');
				newPath.pop();

				const issues = NodeHelpers.getParameterIssues(this.parameter, this.node.parameters, newPath.join('.'), this.node);
				
				// Check if the workflow ID in the Execute Workflow node is valid
				// TODO: It is best to put the check in the 'NodeHelpers.getParameterIssues', 
				// but we must first be able to get all the workflows before we can compare whether the ID is valid
				if (this.node.type === EXECUTE_WORKFLOW_NODE_TYPE && this.path === "parameters.workflowId") {
					const allWorkflowIds = this.$store.getters['allWorkflowIds'];
					if (issues && this.tempValue!=='' && !allWorkflowIds.includes(this.tempValue)) {
						// Parameter is required but empty
						if (issues.parameters === undefined) {
							issues.parameters = {};
						}
						if (issues.parameters[this.parameter.name] === undefined) {
							issues.parameters[this.parameter.name] = [];
						}
						issues.parameters[this.parameter.name].push(`Invalid ${this.parameter.displayName}.`);
					}
				}

				if (this.parameter.type === 'credentialsSelect' && this.displayValue === '') {
					issues.parameters = issues.parameters || {};
					const issue = this.$locale.baseText('parameterInput.selectACredentialTypeFromTheDropdown');
					issues.parameters[this.parameter.name] = [issue];
				} else if (
					['options', 'multiOptions'].includes(this.parameterInputType) &&
					this.remoteParameterOptionsLoading === false &&
					this.remoteParameterOptionsLoadingIssues === null
				) {
					// Check if the value resolves to a valid option
					// Currently it only displays an error in the node itself in
					// case the value is not valid. The workflow can still be executed
					// and the error is not displayed on the node in the workflow

					const validOptions = this.parameterOptions!.map((options: INodePropertyOptions) => options.value);

					const checkValues: string[] = [];
					if (!this.skipCheck(this.displayValue)) {
						if (Array.isArray(this.displayValue)) {
							checkValues.push.apply(checkValues, this.displayValue);
						} else {
							checkValues.push(this.displayValue as string);
						}
					}
					for (const checkValue of checkValues) {
						// when type is options/multiOptions and not required will not check empty string value
						if (['options', 'multiOptions'].includes(this.parameterInputType) && !this.parameter.required && checkValue === '' ) {
							continue;
						}
						if (checkValue === null || !validOptions.includes(checkValue)) {
							if (issues.parameters === undefined) {
								issues.parameters = {};
							}

							const issue = this.$locale.baseText(
								'parameterInput.theValueIsNotSupported',
								{ interpolate: { checkValue } },
							);
							issues.parameters[this.parameter.name] = [issue];
						}
					}
				} else if (this.remoteParameterOptionsLoadingIssues !== null) {
					if (issues.parameters === undefined) {
						issues.parameters = {};
					}
					issues.parameters[this.parameter.name] = [`There was a problem loading the parameter options from server: "${this.remoteParameterOptionsLoadingIssues}"`];
				}

				if (issues !== undefined &&
					issues.parameters !== undefined &&
					issues.parameters[this.parameter.name] !== undefined) {
					return issues.parameters[this.parameter.name];
				}

				return [];
			},
			isDefault (): boolean {
				return this.parameter.default === this.value;
			},
			isEditor (): boolean {
				return ['code', 'json'].includes(this.editorType);
			},
			isValueExpression (): boolean {
				if (this.parameter.noDataExpression === true) {
					return false;
				}
				if (typeof this.value === 'string' && this.value.charAt(0) === '=') {
					return true;
				}
				return false;
			},
			editorType (): string {
				return this.getArgument('editor') as string;
			},
			parameterOptions (): INodePropertyOptions[] {
				// update dynamic input options
				if (this.dynamicParameterOptionsLoading) {
					this.dynamicParameterOptionsLoading = false;
					return this.dynamicParameterOptions;
				}

				if (this.hasRemoteMethod === false) {
					// Options are already given
					return this.parameter.options;
				}

				// Options get loaded from server
				return this.remoteParameterOptions;
			},
			parameterInputClasses (): string[] {
				const classes = [];
				const rows = this.getArgument('rows');
				const isTextarea = this.parameterInputType === 'string' && rows !== undefined;
				const isSwitch = this.parameterInputType === 'boolean' && !this.isValueExpression;

				if (!isTextarea && !isSwitch) {
					classes.push('parameter-value-container');
				}
				if (this.isValueExpression) {
					classes.push('expression');
				}
				if (this.getIssues.length || this.errorHighlight) {
					classes.push('has-issues');
				}
				return classes;
			},
			parameterInputWrapperStyle (): object {
				let deductWidth = 0;
				const styles = {
					width: '100%',
				};
				if (this.parameter.type === 'credentialsSelect') {
					return styles;
				}
				//TODO 2: Remove
				if (this.displayOptionsComputed === true) {
				 	deductWidth += 25;
				}
				//TODO2 End
				if (this.getIssues.length) {
					deductWidth += 20;
				}

				if (deductWidth !== 0) {
					styles.width = `calc(100% - ${deductWidth}px)`;
				}

				return styles;
			},
			hasRemoteMethod (): boolean {
				return !!this.getArgument('loadOptionsMethod') || !!this.getArgument('loadOptions');
			},
			shortPath (): string {
				const shortPath = this.path.split('.');
				shortPath.shift();
				return shortPath.join('.');
			},
			workflow (): Workflow {
				return this.getWorkflow();
			},
			getToken (): string | undefined {
				return this.$route.query.token as string || undefined;
			},
			controllable (): boolean {
				// This is for Webhook starter, most fields is readonly,
				// but we need to enable re-trigger for user to choose whether the workflow allows retrigger
				if (this.currentChannelType && this.node && this.node.type === WEBHOOK_NODE_TYPE && this.shortPath === 'retrigger') {
					return true;
				}
				return false;
			},
			maxLength(): number {
				// if we limit the maxlength, then don't show the edit window
				// to prevent user type and enter more than the character limit
				if (this.node) {
					// LINE limitation
					// https://developers.line.biz/en/reference/messaging-api/#action-object-label-spec
					if (this.node.type.includes(LINE_MULTIPLE_CHOICE_NODE_TYPE)) {
						if (this.parameter.name.toLowerCase().includes('option')) {
							this.showEditWindow = false;
							return 20;
						}
					}
					
					// WhatsApp limitation
					// https://developers.facebook.com/docs/whatsapp/on-premises/reference/messages
					if (this.node.type.includes(WHATSAPP_MULTIPLE_CHOICE_NODE_TYPE)) {
						if (this.parameter.name.toLowerCase().includes('option')) {
							this.showEditWindow = false;
							return 20;
						}
					}
					if (this.node.type.includes(WHATSAPP_CHOICE_LIST_NODE_TYPE)) {
						if (this.parameter.name.toLowerCase().includes('title')) {
							this.showEditWindow = false;
							return 60;
						}
						if (this.parameter.name.toLowerCase().includes('option')) {
							this.showEditWindow = false;
							return 24;
						}
						if (this.parameter.name.toLowerCase().includes('description')) {
							this.showEditWindow = false;
							return 72;
						}

					}
				}
				return 1024;
			},
			parameterInputType(): string {
				// if parameter.type is 'dynamic', update input type when the 'dynamicDependsOnParameterValue' changes
				if (this.parameter.type === 'dynamic') {
					const boardField = this.getBoardField(this.dynamicDependsOnParameterValue);
					if (boardField) {
						this.boardFieldType = boardField.type;
						this.setDynamicParameterOptions(boardField);
						return this.$store.getters['imbrace/parameterInputType'](this.boardFieldType);
					}
					
					// if can't match and get new type, then return the 'fieldType' or 'string'
					return this.parameter?.typeOptions?.dynamicConfigs?.fieldType || 'string';
				}

				// if is not 'dynamic' type, return default parameter type
				return this.parameter.type;
			},
			isAskContactInfoSelection(): boolean {
				return this.node?.type === ASK_ADD_CONTACT_INFO_NODE_TYPE && this.parameter.name === 'selection';
			},
			iconType(): string | null {
				if (this.boardFieldType === 'Country') return 'country';
				if (this.isAskContactInfoSelection) return 'askContactInfoCountry';
				return null;
			},
			insertIcon(): boolean {
				return this.iconType !== null;
			},
		},
		methods: {
			onNodeExecute() {
				this.$emit('execute');
			},
			credentialSelected (updateInformation: INodeUpdatePropertiesInformation) {
				// Update the values on the node
				this.$store.commit('updateNodeProperties', updateInformation);
				const node = this.$store.getters.getNodeByName(updateInformation.name);
				// Update the issues
				this.updateNodeCredentialIssues(node);
				this.$externalHooks().run('nodeSettings.credentialSelected', { updateInformation });
			},
			/**
			 * Check whether a param value must be skipped when collecting node param issues for validation.
			 */
			skipCheck(value: string | number | boolean | null) {
				return typeof value === 'string' && value.includes(CUSTOM_API_CALL_KEY);
			},
			getPlaceholder(): string {
				return this.isForCredential
					? this.$locale.credText().placeholder(this.parameter)
					: this.$locale.nodeText().placeholder(this.parameter, this.path);
			},
			getOptionsOptionDisplayName(option: { value: string; name: string }): string {
				return this.isForCredential
					? this.$locale.credText().optionsOptionDisplayName(this.parameter, option)
					: this.$locale.nodeText().optionsOptionDisplayName(this.parameter, option, this.path);
			},
			getOptionsOptionDescription(option: { value: string; description: string }): string {
				return this.isForCredential
					? this.$locale.credText().optionsOptionDescription(this.parameter, option)
					: this.$locale.nodeText().optionsOptionDescription(this.parameter, option, this.path);
			},
			getOptionsOptionIconSrc(value: string): string {
				switch (this.iconType) {
					case 'country':
						return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`;
					case 'askContactInfoCountry':
						const abbr = COUNTRIES.find((country: any) => country.cca3 === value).cca2;
						return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${abbr}.svg`;
					default:
						return '';
				}
			},
			getOptionsOptionIconAlt(value: string): string {
				switch (this.iconType) {
					case 'country':
						return COUNTRIES.find((country: any) => country.cca2 === value).flag || value;
					case 'askContactInfoCountry':
						return COUNTRIES.find((country: any) => country.cca3 === value).flag || value;
					default:
						return value;
				}
			},

			async loadRemoteParameterOptions () {
				if (this.node === null || this.hasRemoteMethod === false || this.remoteParameterOptionsLoading) {
					return;
				}
				this.remoteParameterOptionsLoadingIssues = null;
				this.remoteParameterOptionsLoading = true;
				this.remoteParameterOptions.length = 0;

				// Get the resolved parameter values of the current node
				const currentNodeParameters = this.$store.getters.activeNode.parameters;
				const resolvedNodeParameters = this.resolveParameter(currentNodeParameters) as INodeParameters;

				try {
					const loadOptionsMethod = this.getArgument('loadOptionsMethod') as string | undefined;
					const loadOptions = this.getArgument('loadOptions') as ILoadOptions | undefined;

					const options = await this.restApi().getNodeParameterOptions({ 
						nodeTypeAndVersion: { name: this.node.type, version: this.node.typeVersion }, 
						path: this.path, 
						methodName: loadOptionsMethod, 
						loadOptions, 
						currentNodeParameters: resolvedNodeParameters, 
						credentials: this.node.credentials, 
						token: this.getToken,
					});
					this.remoteParameterOptions.push.apply(this.remoteParameterOptions, options);
				} catch (error) {
					this.remoteParameterOptionsLoadingIssues = error.message;
				}

				this.remoteParameterOptionsLoading = false;
			},
			closeCodeEditDialog () {
				this.codeEditDialogVisible = false;
			},
			closeExpressionEditDialog () {
				this.expressionEditDialogVisible = false;
				this.expressionSource = 'expressionEditor';
			},
			closeDataDisplayToUseExpressionDialog () {
				this.dataDisplayToUseExpressionDialogVisible = false;
				this.expressionSource = 'dataDisplayDialog';
			},
			trackExpressionEditOpen () {
				if(!this.node) {
					return;
				}

				if((this.node.type as string).startsWith('n8n-nodes-base')) {
					this.$telemetry.track('User opened Expression Editor', {
						node_type: this.node.type,
						parameter_name: this.parameter.displayName,
						parameter_field_type: this.parameter.type,
						new_expression: !this.isValueExpression,
						workflow_id: this.$store.getters.workflowId,
						session_id: this.$store.getters['ui/ndvSessionId'],
						source: this.eventSource || 'ndv',
					});
				}
			},
			closeTextEditDialog () {
				this.textEditDialogVisible = false;
			},
			displayEditDialog () {
				if (this.isEditor) {
					this.codeEditDialogVisible = true;
				} else {
					this.textEditDialogVisible = true;
				}
			},
			getArgument (argumentName: string): string | number | boolean | undefined {
				if (this.parameter.typeOptions === undefined) {
					return undefined;
				}

				if (this.parameter.typeOptions[argumentName] === undefined) {
					return undefined;
				}

				return this.parameter.typeOptions[argumentName];
			},
			expressionUpdated (value: string) {
				this.valueChanged(value);
			},
			openDialogToEditExpression() {
				if (this.expressionSource === 'dataDisplayDialog') {
					this.openDataDisplayToUseExpressionDialog();
					return;
				}
				this.openExpressionEdit();
			},
			openDataDisplayToUseExpressionDialog() {
				if (this.areExpressionsDisabled) {
					return;
				}

				if (this.isValueExpression) {
					this.dataDisplayToUseExpressionDialogVisible = true;
					return;
				}
			},
			openExpressionEdit() {
				if (this.areExpressionsDisabled) {
					return;
				}

				if (this.isValueExpression) {
					this.expressionEditDialogVisible = true;
					return;
				}
			},
			onBlur () {
				this.$emit('blur');
			},
			setFocus () {
				if (this.isValueExpression) {
					this.openDialogToEditExpression();
					return;
				}

				if (['json', 'string'].includes(this.parameterInputType) && this.getArgument('alwaysOpenEditWindow')) {
					this.displayEditDialog();
					return;
				}

				if (this.node !== null) {
					// When an event like mouse-click removes the active node while
					// editing is active it does not know where to save the value to.
					// For that reason do we save the node-name here. We could probably
					// also just do that once on load but if Vue decides for some reason to
					// reuse the input it could have the wrong value so lets set it everytime
					// just to be sure
					this.nodeName = this.node.name;
				}

				// Set focus on field
				setTimeout(() => {
					// @ts-ignore
					if (this.$refs.inputField.$el) {
						// @ts-ignore
						(this.$refs.inputField.$el.querySelector(this.getStringInputType === 'textarea' ? 'textarea' : 'input') as HTMLInputElement).focus();
					}
				});

				this.$emit('focus');
			},
			rgbaToHex (value: string): string | null {
				// Convert rgba to hex from: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
				const valueMatch = (value as string).match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)$/);
				if (valueMatch === null) {
					// TODO: Display something if value is not valid
					return null;
				}
				const [r, g, b, a] = valueMatch.splice(1, 4).map(v => Number(v));
				return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) + ((1 << 8) + Math.floor((1-a)*255)).toString(16).slice(1);
			},
			onTextInputChange (value: string) {
				const parameterData = {
					node: this.node !== null ? this.node.name : this.nodeName,
					name: this.path,
					value,
				};

				this.$emit('textInput', parameterData);
			},
			valueChanged (value: string[] | string | number | boolean | Date | null) {
				if (this.parameter.name === 'nodeCredentialType') {
					this.activeCredentialType = value as string;
				}

				if (value instanceof Date) {
					// For data board, convert HH:mm to valid ISO date string (1970-01-01)
					if (this.node && this.node.type === AUTOMATE_DATA_BOARD_NODE_TYPE && this.parameterInputType === 'time') {
						const hours = value.getHours();
						const minutes = value.getMinutes();
						value = new Date(1970, 0, 1, hours, minutes).toISOString();
					} else {
						value = value.toISOString();
					}
				}

				if (this.parameterInputType === 'color' && this.getArgument('showAlpha') === true && value !== null && value.toString().charAt(0) !== '#') {
					const newValue = this.rgbaToHex(value as string);
					if (newValue !== null) {
						this.tempValue = newValue;
						value = newValue;
					}
				}

				const parameterData = {
					node: this.node !== null ? this.node.name : this.nodeName,
					name: this.path,
					value,
				};

				if (this.node !== null && this.node.type === 'n8n-nodes-base.assignTeamAndEndFlow' && this.path === 'parameters.teamId') {
					// any changes on assign team dropdown should clear assign agent dropdown
					const agentParameterData = {
						node: this.node !== null ? this.node.name : this.nodeName,
						name: 'parameters.userId',
						value: '',
					};
					this.$emit('valueChanged', agentParameterData);
				}

				if (this.node !== null && this.node.type === AUTOMATE_DATA_BOARD_NODE_TYPE) {
					if (this.path.includes('field')) {
						// clear value when related field changed, to avoid invalid date issue
						const boardParameterData = {
							node: this.node !== null ? this.node.name : this.nodeName,
							name: this.path.replace('field', 'value'),
							value: '',
						};
						this.$emit('valueChanged', boardParameterData);
					}

					if (this.path.includes('value')) {
						// save field type
						const boardParameterType = {
							node: this.node !== null ? this.node.name : this.nodeName,
							name: this.path.replace('value', 'type'),
							value: this.parameterInputType,
						};
						this.$emit('valueChanged', boardParameterType);
					}
				}

				this.$emit('valueChanged', parameterData);
			},
			optionSelected (command: string) {
				if (command === 'resetValue') {
					this.valueChanged(this.parameter.default);
				} else if (command === 'addExpression') {
					if (this.parameterInputType === 'number' || this.parameterInputType === 'boolean') {
						this.valueChanged(`={{${this.value}}}`);
					}
					else {
						this.valueChanged(`=${this.value}`);
					}

					this.expressionEditDialogVisible = true;
					this.trackExpressionEditOpen();
				} else if (command === 'removeExpression') {
					let value = this.expressionValueComputed;

					if (this.parameterInputType === 'multiOptions' && typeof value === 'string') {
						value = (value || '').split(',')
							.filter((value) => (this.parameterOptions || []).find((option) => option.value === value));
					}

					this.valueChanged(typeof value !== 'undefined' ? value : null);
				} else if (command === 'refreshOptions') {
					this.loadRemoteParameterOptions();
				} else if (command === 'dataFromLastConnector') {
					if (!this.isValueExpression) {
						// If we don't do this, number and boolean would be return when init value
						if (this.parameterInputType === 'number' || this.parameterInputType === 'boolean') {
							this.valueChanged(`={{${this.value}}}`);
						}
						else {
							this.valueChanged(`=${this.value}`);
						}
					}

					this.dataDisplayToUseExpressionDialogVisible = true;
				} else if (command === 'editExpression') {
					this.expressionEditDialogVisible = true;
				}
			},
			getBoardField (fieldId: string) {
				// find matching field in "dynamicMappingData" by using "dynamicDependsOnParameterValue"
				if (fieldId && this.dynamicMappingData) {
					return this.dynamicMappingData['boardFields']?.find((field: IBoardField) => field._id === fieldId) || null;
				}
				return null;
			},
			setDynamicParameterOptions (boardField: IBoardField) {
				if (!boardField || !this.boardFieldType) return;

				// get parameter options from board field
				if (['SingleSelection', 'MultipleSelection', 'Priority'].includes(this.boardFieldType)) {
					this.dynamicParameterOptions = boardField.data.map((item: {value: string, _id: string}) => {
						return {
							name: item.value,
							value: item._id,
						};
					});
					this.dynamicParameterOptionsLoading = true;
				};

				// get parameter option to show all active users
				if (this.boardFieldType === 'Assignee') {
					const allMembers = this.$store.getters['imbrace/allMembers'];
					this.dynamicParameterOptions = allMembers.map((member: IMember) => {
						return {
							name: member.display_name,
							value: member.id,
						};
					});
					this.dynamicParameterOptionsLoading = true;
				}

				// get parameter option to show all countries
				if (this.boardFieldType === 'Country') {
					this.dynamicParameterOptions = COUNTRIES
						.sort((a: any, b: any) => a.name.common > b.name.common ? 1 : -1)
						.map((country: any) => {
							return {
								name: country.name.common,
								value: country.cca2,
							};
						});
					this.dynamicParameterOptionsLoading = true;
				}
			},
		},
		mounted () {
			this.tempValue = this.displayValue as string;
			if (this.node !== null) {
				this.nodeName = this.node.name;
			}

			if (this.node && this.node.parameters.authentication === 'predefinedCredentialType') {
				this.activeCredentialType = this.node.parameters.nodeCredentialType as string;
			}

			if (this.parameterInputType === 'color' && this.getArgument('showAlpha') === true && this.displayValue !== null && this.displayValue.toString().charAt(0) !== '#') {
				const newValue = this.rgbaToHex(this.displayValue as string);
				if (newValue !== null) {
					this.tempValue = newValue;
				}
			}

			if (this.hasRemoteMethod === true && this.node !== null) {
				// Make sure to load the parameter options
				// directly and whenever the credentials change
				this.$watch(() => this.node!.credentials, () => {
					this.loadRemoteParameterOptions();
				}, { deep: true, immediate: true });

				// Reload function on change element from
				// displayOptions.typeOptions.reloadOnChange parameters
				if (this.parameter.typeOptions && this.parameter.typeOptions.reloadOnChange) {
					// Get all parameter in reloadOnChange property
					// This reload when parameters in reloadOnChange is updated
					const parametersOnChange : string[] = this.parameter.typeOptions.reloadOnChange;
					for (let i = 0; i < parametersOnChange.length; i++) {
						const parameter = parametersOnChange[i] as string;
						if (parameter in this.node.parameters) {
							this.$watch(() => {
								if (this.node && this.node.parameters && this.node.parameters[parameter]) {
									return this.node.parameters![parameter];
								} else {
									return null;
								}
							}, () => {
								this.loadRemoteParameterOptions();
							}, { deep: true, immediate: true });
						}
					}
				}
			}

			this.$externalHooks().run('parameterInput.mount', { parameter: this.parameter, inputFieldRef: this.$refs['inputField'] });
		},
	});
