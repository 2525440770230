
import Vue from 'vue';

export default Vue.extend({
	name: 'ParameterOptions',
	props: [
		'displayOptionsComputed',
		'optionSelected',
		'parameter',
		'isValueExpression',
		'isDefault',
		'hasRemoteMethod',
	],
});
