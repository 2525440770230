
import type {
	INodePropertyTypeOptions,
	ResourceMapperFields,
	ResourceMapperTypeOptions,
} from 'n8n-workflow';
import mixins from 'vue-typed-mixins';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

interface MappingModeOption {
	name: string;
	value: string;
	description: string;
}

export default mixins(externalHooks, nodeHelpers, showMessage, workflowHelpers).extend({
	name: 'MappingModeSelect',
	props: {
		initialValue: String,
		fieldsToMap: Array as () => ResourceMapperFields['fields'],
		inputSize: String,
		labelSize: String,
		typeOptions: Object as () => INodePropertyTypeOptions,
		serviceName: String,
		loading: Boolean,
		loadingError: Boolean,
		teleported: Boolean,
	},

	data() {
		return {
			selected: this.initialValue,
		};
	},

	computed: {
		resourceMapperTypeOptions(): ResourceMapperTypeOptions | undefined {
			return this.typeOptions?.resourceMapper;
		},

		singularFieldWord(): string {
			const singularFieldWord =
				this.resourceMapperTypeOptions?.fieldWords?.singular ||
				this.$locale.baseText('generic.field');
			return singularFieldWord;
		},

		singularFieldWordCapitalized(): string {
			return this.singularFieldWord.charAt(0).toUpperCase() + this.singularFieldWord.slice(1);
		},

		pluralFieldWord(): string {
			return (
				this.resourceMapperTypeOptions?.fieldWords?.plural ||
				this.$locale.baseText('generic.fields')
			);
		},

		pluralFieldWordCapitalized(): string {
			return this.pluralFieldWord.charAt(0).toUpperCase() + this.pluralFieldWord.slice(1);
		},
		// Mapping mode options: Labels here use field words defined in parameter type options
		mappingModeOptions(): MappingModeOption[] {
			return [
				{
					name: this.$locale.baseText('resourceMapper.mappingMode.defineBelow.name'),
					value: 'defineBelow',
					description: this.$locale.baseText('resourceMapper.mappingMode.defineBelow.description', {
						interpolate: {
							fieldWord: this.singularFieldWord,
						},
					}),
				},
				{
					name: this.$locale.baseText('resourceMapper.mappingMode.autoMapInputData.name'),
					value: 'autoMapInputData',
					description: this.$locale.baseText(
						'resourceMapper.mappingMode.autoMapInputData.description',
						{
							interpolate: {
								fieldWord: this.pluralFieldWord,
								serviceName: this.serviceName,
							},
						},
					),
				},
			];
		},

		errorMessage(): string {
			if (this.selected === 'defineBelow') {
				// Loading error message
				if (this.loadingError) {
					return this.$locale.baseText('resourceMapper.fetchingFields.errorMessage', {
						interpolate: {
							fieldWord: this.pluralFieldWord,
						},
					});
				}
				// No data error message
				if (this.fieldsToMap.length === 0) {
					return (
						// Use custom error message if defined
						this.resourceMapperTypeOptions?.noFieldsError ||
						this.$locale.baseText('resourceMapper.fetchingFields.noFieldsFound', {
							interpolate: {
								fieldWord: this.pluralFieldWord,
								serviceName: this.serviceName,
							},
						})
					);
				}
				return '';
			}
			return '';
		},
	},

	methods: {
		onRetryClick(): void {
			this.$emit('retryFetch');
		},

		onModeChanged(value: string): void {
			this.selected = value;
			this.$emit('modeChanged', value);
		},
	},

	watch: {
		initialValue(newVal) {
			this.selected = newVal;
		},
	},
});
