
import Modals from './components/Modals.vue';
import LoadingView from './views/LoadingView.vue';
import Telemetry from './components/Telemetry.vue';
import { VIEWS } from './constants';

import mixins from 'vue-typed-mixins';
import { showMessage } from './components/mixins/showMessage';
import { IUser } from './Interface';
import { mapGetters } from 'vuex';
import { userHelpers } from './components/mixins/userHelpers';
import { addHeaders, loadLanguage } from './plugins/i18n';
import { restApi } from '@/components/mixins/restApi';

export default mixins(showMessage, userHelpers, restApi).extend({
	name: 'App',
	components: {
		LoadingView,
		Telemetry,
		Modals,
	},
	computed: {
		...mapGetters('imbrace', ['showSidebarMenu']),
		...mapGetters('settings', [
			'isHiringBannerEnabled',
			'isTemplatesEnabled',
			'isTemplatesEndpointReachable',
			'isUserManagementEnabled',
			'showSetupPage',
		]),
		...mapGetters('users', ['currentUser']),
		defaultLocale(): string {
			return this.$store.getters.defaultLocale;
		},
	},
	data() {
		return {
			loading: true,
		};
	},
	methods: {
		async initSettings(): Promise<void> {
			try {
				await this.$store.dispatch('settings/getSettings');
			} catch (e) {
				this.$showToast({
					title: this.$locale.baseText('startupError'),
					message: this.$locale.baseText('startupError.message'),
					type: 'error',
					duration: 0,
				});

				throw e;
			}
		},
		async loginWithCookie(): Promise<void> {
			try {
				await this.$store.dispatch('users/loginWithCookie');
			} catch (e) {}
		},
		async initTemplates(): Promise<void> {
			if (!this.isTemplatesEnabled) {
				return;
			}

			try {
				await this.$store.dispatch('settings/testTemplatesEndpoint');
			} catch (e) {}
		},
		async initialize(): Promise<void> {
			await this.initSettings();
			await Promise.all([this.loginWithCookie()]);

			// we are not using template now, so don't need to execute 'initTemplates' function
			// await Promise.all([this.loginWithCookie(), this.initTemplates()]);
		},
		trackPage() {
			this.$store.commit('ui/setCurrentView', this.$route.name);
			// if (this.$route && this.$route.meta && this.$route.meta.templatesEnabled) {
			// 	this.$store.commit('templates/setSessionId');
			// } else {
			// 	this.$store.commit('templates/resetSessionId'); // reset telemetry session id when user leaves template pages
			// }

			//this.$telemetry.page(this.$route);
		},
		authenticate() {
			// redirect to setup page. user should be redirected to this only once
			if (this.isUserManagementEnabled && this.showSetupPage) {
				if (this.$route.name === VIEWS.SETUP) {
					return;
				}

				this.$router.replace({ name: VIEWS.SETUP });
				return;
			}

			if (this.canUserAccessCurrentRoute()) {
				return;
			}

			// if cannot access page and not logged in, ask to sign in
			const user = this.currentUser as IUser | null;
			if (!user) {
				const redirect =
					this.$route.query.redirect ||
					encodeURIComponent(`${window.location.pathname}${window.location.search}`);
				this.$router.replace({ name: VIEWS.SIGNIN, query: { redirect } });
				return;
			}

			// if cannot access page and is logged in, respect signin redirect
			if (this.$route.name === VIEWS.SIGNIN && typeof this.$route.query.redirect === 'string') {
				const redirect = decodeURIComponent(this.$route.query.redirect);
				if (redirect.startsWith('/')) {
					// protect against phishing
					this.$router.replace(redirect);
					return;
				}
			}

			// if cannot access page and is logged in
			this.$router.replace({ name: VIEWS.HOMEPAGE });
		},
		redirectIfNecessary() {
			const redirect =
				this.$route.meta &&
				typeof this.$route.meta.getRedirect === 'function' &&
				this.$route.meta.getRedirect(this.$store);
			if (redirect) {
				this.$router.replace(redirect);
			}
		},
	},
	async mounted() {
		await this.initialize();
		this.authenticate();
		this.redirectIfNecessary();

		this.loading = false;

		this.trackPage();
		this.$externalHooks().run('app.mount');

		if (this.defaultLocale !== 'en') {
			const headers = await this.restApi().getNodeTranslationHeaders();
			if (headers) addHeaders(headers, this.defaultLocale);
		}
		const queryLang = this.$route.query;
		if(!!queryLang && !!queryLang.lang) {
			loadLanguage(queryLang.lang as string);
		}

		// Check env
		this.$store.commit('imbrace/checkEnv', process.env.NODE_ENV === 'production');
		// this.$store.commit('imbrace/setShowDebugElements', process.env.NODE_ENV === 'development');
		this.$store.commit('imbrace/setShowSidebarMenu', process.env.NODE_ENV === 'development');
	},
	watch: {
		$route(route) {
			this.authenticate();
			this.redirectIfNecessary();

			this.trackPage();
		},
		defaultLocale(newLocale) {
			loadLanguage(newLocale);
		},
	},
});
