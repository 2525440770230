
import Vue from 'vue';
import { camelCase } from 'lodash-es';

export default Vue.extend({
	props: ['item'],
	computed: {
		subcategoryName() {
			return camelCase(this.item.properties.subcategory);
		},
	},
});
