
import Vue from 'vue';

import ImbraceModal from './ImbraceModal.vue';
import { ACTIVE_ERROR_MODAL_KEY } from '../constants';

export default Vue.extend({
	name: 'ActiveErrorModal',
	components: {
		ImbraceModal,
	},
	data() {
		return {
			modalBus: new Vue(),
			ACTIVE_ERROR_MODAL_KEY,
		};
	},
	computed: {
		parentOrigin(): string | undefined | null {
			return this.$store.getters['imbrace/parentOrigin'];
		},
		hideActiveSwitch(): boolean {
			return this.$store.getters['imbrace/hideActiveSwitch'];
		},
		modalTitle(): string {
			if (this.hideActiveSwitch) {
				return this.$locale.baseText('activeErrorModal.title.editVer');
			}
			return this.$locale.baseText('activeErrorModal.title');
		},
		modalDescription(): string {
			if (this.hideActiveSwitch) {
				return this.$locale.baseText('activeErrorModal.description.editVer');
			}
			return this.$locale.baseText('activeErrorModal.description');
		},
	},
	methods: {
		deactivateWorkflow () {
			this.$store.commit('setActive', false);
			this.modalBus.$emit('close');
		},
		onCancel() {
			// If user discard workflow editing, send message to parent to close
			if (this.parentOrigin) {
				// window.parent.postMessage({
				// 	type: "reloadWorkflowAction",
				// 	data: null,
				// }, this.parentOrigin);

				// for frontend save & exit modal to close
				window.parent.postMessage({
					type: "closeAction",
					data: null,
				}, this.parentOrigin);
			}
			this.$store.commit("setReloadWorkflow", true);
			this.modalBus.$emit("close");
		},
	},
});
