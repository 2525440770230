// tags
export const VALID_TAGS = [
	'preset',
	'web',
	'mail',
	'wechat',
	'automation',
	'line',
	'whatsapp',
	'facebook',
	'instagram',
	'board',
	'journey',
	'idea',
];

// workflow type
export const DEFAULT_WORKFLOW_TYPE = 'main';
export const CHANNEL_WORKFLOW_TYPE = 'channel';
export const AUTOMATION_WORKFLOW_TYPE = 'automation';
export const PRESET_WORKFLOW_TYPE = 'preset';
export const CHANNEL_AUTOMATION_WORKFLOW_TYPE = 'channelAutomation';
export const BOARD_WORKFLOW_TYPE = 'board';
export const BOARD_AUTOMATION_WORKFLOW_TYPE = 'boardAutomation';

// workflow source
export const DEFAULT_WORKFLOW_SOURCE = 'workflow';
export const JOURNEY_WORKFLOW_SOURCE = 'journey';
export const IDEA_WORKFLOW_SOURCE = 'idea';

// node style
export const DEFAULT_NODE_STYLE_VERSION = 1;
export const LATEST_NODE_STYLE_VERSION = 2;

// channel type
export const CHANNEL_TYPE = [
	{
		tag: 'email',
		title: 'Email Starter',
	},
	{
		tag: 'facebook',
		title: 'Facebook Starter',
	},
	{
		tag: 'instagram',
		title: 'Instagram Starter',
	},
	{
		tag: 'line',
		title: 'LINE Starter',
	},
	{
		tag: 'web',
		title: 'Web Widget Starter',
	},
	{
		tag: 'wechat',
		title: 'Wechat Starter',
	},
	{
		tag: 'whatsapp',
		title: 'WhatsApp Starter',
	},
];


