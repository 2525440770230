
import Vue from 'vue';
import {
	ASSISTANT_REQUEST_NODE_TYPE,
	CUSTOM_API_CALL_KEY,
	EXECUTE_WORKFLOW_NODE_TYPE,
	INTEGRATIONS_CATEGORY,
	JOURNEY_WORKFLOW_SOURCE,
	PRESET_WORKFLOW_TYPE,
	START_NODE_TYPE,
	VIEWS,
	WAIT_TIME_UNLIMITED,
	WEBHOOK_NODE_TYPE,
} from '@/constants';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeBase } from '@/components/mixins/nodeBase';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import { IConnections, INodeTypeDescription, ITaskData, NodeHelpers } from 'n8n-workflow';

import NodeIcon from '@/components/NodeIcon.vue';
import ShortenText from '@/components/ShortenText.vue';

import mixins from 'vue-typed-mixins';

import { get } from 'lodash-es';
import { getStyleTokenValue, getTriggerNodeServiceName } from './helpers';
import { INodeUi, ITag, IWorkflowShortResponse, XYPosition } from '@/Interface';
import { mapGetters } from 'vuex';

export default mixins(externalHooks, nodeBase, nodeHelpers, workflowHelpers).extend({
	name: 'Node',
	components: {
		NodeIcon,
		ShortenText,
	},
	computed: {
		...mapGetters('imbrace', [
			'currentWorkflowType',
			'currentChannelType',
			'currentWorkflowSource',
			'presetWorkflows',
			'isImbracePaidUser',
		]),
		nodeRunData(): ITaskData[] {
			return this.$store.getters.getWorkflowResultDataByNodeName(this.data.name);
		},
		hasIssues(): boolean {
			if (this.data.issues !== undefined && Object.keys(this.data.issues).length) {
				return true;
			}
			// TODO: It is best to check this issue in the 'NodeHelpers.getParameterIssues'
			// Check if the workflow ID in the Execute Workflow node is valid
			// If has issues, show the red-outline
			if (this.node?.type === EXECUTE_WORKFLOW_NODE_TYPE) {
				const allWorkflowIds = this.$store.getters['allWorkflowIds'];
				return !allWorkflowIds.includes(this.node.parameters.workflowId);
			}
			return false;
		},
		workflowDataItems(): number {
			const workflowResultDataNode = this.nodeRunData;
			if (workflowResultDataNode === null) {
				return 0;
			}

			return workflowResultDataNode.length;
		},
		canvasOffsetPosition(): XYPosition {
			return this.$store.getters.getNodeViewOffsetPosition;
		},
		getTriggerNodeTooltip(): string | undefined {
			if (this.nodeType !== null && this.nodeType.hasOwnProperty('eventTriggerDescription')) {
				const nodeName = this.$locale.shortNodeType(this.nodeType.name);
				const { eventTriggerDescription } = this.nodeType;
				return this.$locale
					.nodeText()
					.eventTriggerDescription(nodeName, eventTriggerDescription || '');
			} else {
				return this.$locale.baseText('node.waitingForYouToCreateAnEventIn', {
					interpolate: {
						nodeType: this.nodeType ? getTriggerNodeServiceName(this.nodeType.displayName) : '',
					},
				});
			}
		},
		isPollingTypeNode(): boolean {
			return !!(this.nodeType && this.nodeType.polling);
		},
		isExecuting(): boolean {
			return this.$store.getters.executingNode === this.data.name;
		},
		isSingleActiveTriggerNode(): boolean {
			const nodes = this.$store.getters.workflowTriggerNodes.filter((node: INodeUi) => {
				const nodeType = this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;
				return nodeType && nodeType.eventTriggerDescription !== '' && !node.disabled;
			});

			return nodes.length === 1;
		},
		isTriggerNode(): boolean {
			return !!(this.nodeType && this.nodeType.group.includes('trigger'));
		},
		isTriggerNodeTooltipEmpty(): boolean {
			return this.nodeType !== null ? this.nodeType.eventTriggerDescription === '' : false;
		},
		isNodeDisabled(): boolean | undefined {
			return this.node && this.node.disabled;
		},
		isDiamondShape(): boolean {
			return this.isTriggerNode;
		},
		isCircleShape(): boolean {
			return !!(
				this.nodeType &&
				this.nodeType.codex &&
				this.nodeType.codex.categories &&
				this.nodeType.codex.categories.includes(INTEGRATIONS_CATEGORY)
			);
		},
		isStartNode(): boolean {
			return !!(this.nodeType && this.nodeType.name === START_NODE_TYPE);
		},
		nodeType(): INodeTypeDescription | null {
			return this.data && this.$store.getters.nodeType(this.data.type, this.data.typeVersion);
		},
		node(): INodeUi | undefined {
			// same as this.data but reactive..
			return this.$store.getters.nodesByName[this.name] as INodeUi | undefined;
		},
		nodeBoxClass(): object {
			return {
				'node-box': true,
				disabled: this.data.disabled,
				executing: this.isExecuting,
				diamond: this.isDiamondShape || this.isStartNode,
				circle: this.isCircleShape,
				startStyle: this.isStartNode,
				'startStyle-connected': this.isStartNode && this.isConnected,
			};
		},
		nodeSelectBackgroundClass(): object {
			return {
				'select-background': true,
				diamond: this.isDiamondShape || this.isStartNode,
				circle: this.isCircleShape,
			};
		},
		nodeIconClass(): object {
			return {
				'node-icon': true,
				recover: this.isDiamondShape || this.isStartNode,
				startStyle: this.isStartNode,
				'startStyle-connected': this.isStartNode && this.isConnected,
			};
		},
		nodeDescriptionClass(): object {
			return {
				'node-description': true,
				diamond: this.isDiamondShape,
			};
		},
		nodeOptionsClass(): object {
			return {
				'node-options': true,
				'no-select-on-click': true,
				diamond: this.isDiamondShape || this.isStartNode,
				show: this.showActions,
			};
		},
		nodeIssues(): string {
			if (this.data.issues === undefined) {
				return '';
			}

			const nodeIssues = NodeHelpers.nodeIssuesToString(this.data.issues, this.data);

			return (
				`${this.$locale.baseText('node.issues')}:<br />&nbsp;&nbsp;- ` +
				nodeIssues.join('<br />&nbsp;&nbsp;- ')
			);
		},
		nodeDisabledIcon(): string {
			if (this.data.disabled === false) {
				return 'pause';
			} else {
				return 'play';
			}
		},
		position(): XYPosition {
			return this.node ? this.node.position : [0, 0];
		},
		showDisabledLinethrough(): boolean {
			return !!(
				this.data.disabled &&
				this.nodeType &&
				this.nodeType.inputs.length === 1 &&
				this.nodeType.outputs.length === 1
			);
		},
		nodePosition(): object {
			const returnStyles: {
				[key: string]: string;
			} = {
				left: this.position[0] + 'px',
				top: this.position[1] + 'px',
			};

			if (this.nodeType && this.nodeType.outputs.length > 5) {
				const scale = Number(this.nodeType.outputs.length - 5);
				returnStyles.height = scale * 25 + 100 + 'px';
			}

			return returnStyles;
		},
		shortNodeType(): string {
			return this.$locale.shortNodeType(this.data.type);
		},
		nodeTitle(): string {
			if (this.data.name === 'Start') {
				return this.$locale.headerText({
					key: `headers.start.displayName`,
					fallback: 'Start',
				});
			}

			// For channel type WF, change 'Webhook' node title to match current channel
			const starterNodeTitle = this.$store.getters['imbrace/starterNodeTitle'];
			if (starterNodeTitle && this.data.type === WEBHOOK_NODE_TYPE) {
				return starterNodeTitle;
			}

			return this.data.parameters.icsTitle as string;
		},
		waiting(): string | undefined {
			const workflowExecution = this.$store.getters.getWorkflowExecution;

			if (workflowExecution && workflowExecution.waitTill) {
				const lastNodeExecuted = get(workflowExecution, 'data.resultData.lastNodeExecuted');
				if (this.name === lastNodeExecuted) {
					const waitDate = new Date(workflowExecution.waitTill);
					if (waitDate.toISOString() === WAIT_TIME_UNLIMITED) {
						return this.$locale.baseText(
							'node.theNodeIsWaitingIndefinitelyForAnIncomingWebhookCall',
						);
					}
					return this.$locale.baseText('node.nodeIsWaitingTill', {
						interpolate: {
							date: waitDate.toLocaleDateString(),
							time: waitDate.toLocaleTimeString(),
						},
					});
				}
			}

			return;
		},
		workflowRunning(): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
		nodeBoxStyle(): object {
			let borderColor = getStyleTokenValue('--color-foreground-xdark');

			if (this.data.disabled) {
				borderColor = getStyleTokenValue('--color-foreground-base');
			} else if (!this.isExecuting) {
				if (this.hasIssues) {
					borderColor = getStyleTokenValue('--color-danger');
				} else if (this.waiting) {
					borderColor = getStyleTokenValue('--color-secondary');
				} else if (this.workflowDataItems) {
					borderColor = getStyleTokenValue('--color-success');
				}
			}

			const returnStyles: {
				[key: string]: string;
			} = {
				'border-color': borderColor,
			};

			return returnStyles;
		},
		isSelected(): boolean {
			return this.$store.getters.getSelectedNodes.find(
				(node: INodeUi) => node.name === this.data.name,
			);
		},
		showActions(): boolean {
			if (!this.isTouchDevice) {
				// only enable this logic for touch devices
				return false;
			}
			return this.$store.getters.lastSelectedNode?.name === this.data.name;
		},
		connectionList(): IConnections {
			return this.$store.getters.allConnections;
		},
		shiftOutputCount(): boolean {
			return !!(this.nodeType && this.nodeType.outputs.length > 2);
		},
		shouldShowTriggerTooltip(): boolean {
			return (
				!!this.node &&
				this.isTriggerNode &&
				!this.isPollingTypeNode &&
				!this.isNodeDisabled &&
				this.workflowRunning &&
				this.workflowDataItems === 0 &&
				this.isSingleActiveTriggerNode &&
				!this.isTriggerNodeTooltipEmpty &&
				!this.hasIssues &&
				!this.dragging
			);
		},
		isNodeViewMoveInProgress(): boolean {
			return this.$store.getters.isNodeViewMoveInProgress;
		},
		showNodeSettingsModal(): boolean {
			// Only deactivated in non-read-only mode so execution can be extended to see details
			if (!this.isReadOnly) {
				// Do not allow valid preset workflows to show settings modal
				// to prevent user change the default preset workflow id
				if (this.node?.type === EXECUTE_WORKFLOW_NODE_TYPE) {
					const { workflowId } = this.node.parameters;
					if (workflowId) {
						const presetWorkflowIndex = this.presetWorkflows.findIndex(
							(workflow: IWorkflowShortResponse) => workflow.id === workflowId,
						);
						return presetWorkflowIndex === -1 ? true : false;
					}
				}

				// if users're in an AI-assistant journey created preset,
				// disable the user's ability to open the AI-assistant connector settings modal.
				if (this.node?.type === ASSISTANT_REQUEST_NODE_TYPE) {
					const isPreset = this.currentWorkflowType === PRESET_WORKFLOW_TYPE;
					const fromJourney = this.currentWorkflowSource === JOURNEY_WORKFLOW_SOURCE;
					return !(isPreset && fromJourney);
				}
			}
			return true;
		},
	},
	watch: {
		isActive(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.setSubtitle();
			}
		},
		connectionList: {
			handler() {
				if (!this.connectionList[this.name]) {
					this.isConnected = false;
					return;
				}

				if (this.connectionList[this.name].main[0].length !== 0) {
					this.isConnected = true;
				} else {
					this.isConnected = false;
				}
			},
			deep: true,
		},
		canvasOffsetPosition() {
			if (this.showTriggerNodeTooltip) {
				this.showTriggerNodeTooltip = false;
				setTimeout(() => {
					this.showTriggerNodeTooltip = this.shouldShowTriggerTooltip;
				}, 200);
			}
		},
		shouldShowTriggerTooltip(shouldShowTriggerTooltip) {
			if (shouldShowTriggerTooltip) {
				setTimeout(() => {
					this.showTriggerNodeTooltip = this.shouldShowTriggerTooltip;
				}, 2500);
			} else {
				this.showTriggerNodeTooltip = false;
			}
		},
		nodeRunData(newValue) {
			this.$emit('run', { name: this.data.name, data: newValue, waiting: !!this.waiting });
		},
	},
	mounted() {
		this.setSubtitle();
		setTimeout(() => {
			this.$emit('run', {
				name: this.data && this.data.name,
				data: this.nodeRunData,
				waiting: !!this.waiting,
			});
		}, 0);
	},
	data() {
		return {
			isTouchActive: false,
			nodeSubtitle: '',
			showTriggerNodeTooltip: false,
			dragging: false,
			isConnected: false,
			lastTapTime: 0,
		};
	},
	methods: {
		setSubtitle() {
			// Hide subtitle in Execute Workflow node
			if (this.node?.type === EXECUTE_WORKFLOW_NODE_TYPE) {
				this.nodeSubtitle = '';
			} else {
				const nodeSubtitle = this.getNodeSubtitle(this.data, this.nodeType, this.getWorkflow()) || '';
				this.nodeSubtitle = nodeSubtitle.includes(CUSTOM_API_CALL_KEY)
					? ''
					: nodeSubtitle;
			}
		},
		disableNode() {
			this.disableNodes([this.data]);
			// this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'disable', workflow_id: this.$store.getters.workflowId });
		},
		executeNode() {
			this.$emit('runWorkflow', this.data.name, 'Node.executeNode');
			// this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'execute', workflow_id: this.$store.getters.workflowId });
		},
		deleteNode() {
			// this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'delete', workflow_id: this.$store.getters.workflowId });

			Vue.nextTick(() => {
				// Wait a tick else vue causes problems because the data is gone
				this.$emit('removeNode', this.data.name);
			});
		},
		duplicateNode() {
			// this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'duplicate', workflow_id: this.$store.getters.workflowId });
			Vue.nextTick(() => {
				// Wait a tick else vue causes problems because the data is gone
				this.$emit('duplicateNode', this.data.name);
			});
		},
		setNodeActive() {
			if (this.isPreview) return;
			if (!this.showNodeSettingsModal) {
				this.$showMessage({
					title: this.$locale.baseText('node.showMessage.readyToUseConnector.title'),
					message: this.$locale.baseText('node.showMessage.readyToUseConnector.message'),
					type: 'warning',
					duration: 8000,
				});
				return;
			}
			this.$store.commit('setActiveNode', this.data.name);
		},
		touchTap(e: MouseEvent | TouchEvent) {
			if (this.isTouchDevice) {
				const currentTime = new Date().getTime();
				const tapInterval = currentTime - this.lastTapTime;
				this.lastTapTime = currentTime;

				if (tapInterval < 300 && tapInterval > 0) {
					// 300ms as double tap threshold
					if (this.isPreview) return;
					this.$store.commit('setActiveNode', this.data.name);
				} else {
					this.mouseLeftClick(e as MouseEvent);
				}
			}
		},
		touchStart() {
			if (this.isTouchDevice === true && this.isMacOs === false && this.isTouchActive === false) {
				this.isTouchActive = true;
				setTimeout(() => {
					this.isTouchActive = false;
				}, 2000);
			}
		},
		mouseDown(e: MouseEvent) {
			if (!this.isPreview) return;
			this.$emit('mouseDown', e);
		},
	},
});
