
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';
import {
	CHANNEL_AUTOMATION_WORKFLOW_TYPE,
	PRESET_WORKFLOW_TYPE,
	CHANNEL_WORKFLOW_TYPE,
	MAX_WORKFLOW_NAME_LENGTH,
	AUTOMATION_WORKFLOW_TYPE,
	CHANNEL_TYPE,
	BOARD_AUTOMATION_WORKFLOW_TYPE,
	BOARD_WORKFLOW_TYPE,
} from '@/constants';

import ShortenName from '@/components/ShortenName.vue';
import TagsContainer from '@/components/TagsContainer.vue';
import PushConnectionTracker from '@/components/PushConnectionTracker.vue';
import WorkflowActivator from '@/components/WorkflowActivator.vue';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import SaveButton from '@/components/SaveButton.vue';
import TagsDropdown from '@/components/TagsDropdown.vue';
import InlineTextEdit from '@/components/InlineTextEdit.vue';
import BreakpointsObserver from '@/components/BreakpointsObserver.vue';
import { showMessage } from '@/components/mixins/showMessage';

const hasChanged = (prev: string[], curr: string[]) => {
	if (prev.length !== curr.length) {
		return true;
	}

	const set = new Set(prev);
	return curr.reduce((accu, val) => accu || !set.has(val), false);
};

export default mixins(workflowHelpers, showMessage).extend({
	name: 'WorkflowDetails',
	components: {
		TagsContainer,
		PushConnectionTracker,
		ShortenName,
		WorkflowActivator,
		SaveButton,
		TagsDropdown,
		InlineTextEdit,
		BreakpointsObserver,
	},
	data() {
		return {
			isTagsEditEnabled: false,
			isNameEditEnabled: false,
			isNameEditError: false,
			appliedTagIds: [],
			tagsEditBus: new Vue(),
			MAX_WORKFLOW_NAME_LENGTH,
			tagsSaving: false,
		};
	},
	computed: {
		...mapGetters({
			isWorkflowActive: 'isActive',
			workflowName: 'workflowName',
			isDirty: 'getStateIsDirty',
			currentWorkflowTagIds: 'workflowTags',
		}),
		...mapGetters('settings', ['areTagsEnabled']),
		...mapGetters('imbrace', [
			'currentWorkflowType',
			'currentChannelType',
			'imbraceChannel',
			'needToSetDefaultName',
			'isActiveDisabled',
		]),
		isNewWorkflow(): boolean {
			return !this.$route.params.name;
		},
		isWorkflowSaving(): boolean {
			if (this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
				return this.isActiveDisabled;
			}
			return this.$store.getters.isActionActive('workflowSaving');
		},
		isWorkflowSaved(): boolean {
			if (this.isChannelSetupNeeded) {
				return true;
			}
			if (this.isNewWorkflow && this.needToSetDefaultName) {
				return true;
			}
			if (!this.isDirty && !this.isNewWorkflow) {
				return true;
			}
			return false;
		},
		currentWorkflowId(): string {
			return this.$route.params.name;
		},
		isNameReadOnly(): boolean {
			// Disable workflow name editing in channel workflow
			// if (this.currentWorkflowType === CHANNEL_WORKFLOW_TYPE) {
			// 	return true;
			// }

			// Disable editing to prevent saving data when workflow is active
			// if (this.isWorkflowActive) {
			// 	return true;
			// }

			return false;
		},
		hideActiveSwitch(): boolean {
			const hideActiveSwitch = [
				PRESET_WORKFLOW_TYPE,
				CHANNEL_AUTOMATION_WORKFLOW_TYPE,
				CHANNEL_WORKFLOW_TYPE,
				BOARD_AUTOMATION_WORKFLOW_TYPE,
			];
			if (hideActiveSwitch.includes(this.currentWorkflowType)) {
				this.$store.commit('imbrace/setHideActiveSwitch', true);
				return true;
			}
			this.$store.commit('imbrace/setHideActiveSwitch', false);
			return false;
		},
		// isActiveImbraceAPI(): boolean {
		// 	const access_token = this.$store.getters['imbrace/access_token'];
		// 	if (this.currentWorkflowType === CHANNEL_WORKFLOW_TYPE && access_token) {
		// 		return true;
		// 	}
		// 	return false;
		// },
		isActiveLocked(): boolean {
			return this.$store.getters['imbrace/isFeatureLock']({
				automationWorkflowOperation: 'active',
			});
		},
		isChannelSetupNeeded(): boolean {
			return !!this.imbraceChannel?.is_init;
		},
		testChatUrl(): string {
			if (this.currentChannelType === 'web' && this.imbraceChannel?._id) {
				return `${process.env.VUE_APP_CHAT_HOST}/full_page.html?channel_id=${this.imbraceChannel._id}`;
			}
			return '';
		},
		whapsAppNumber(): string {
			if (this.currentChannelType === 'whatsapp' && this.imbraceChannel?.config?.phone_number) {
				return `+${this.imbraceChannel.config.phone_number}`;
			}
			return '';
		},
	},
	methods: {
		async onSaveButtonClick() {
			if (this.isWorkflowActive) {
				return;
			}

			// In channel WF, channel automation WF and board automation WF
			// every time user save WF, we will automatically active WF
			if (this.currentChannelType || this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
				this.$store.commit('setActive', true);
				this.$store.commit('imbrace/setUpdateActiveChanged', true);
				return;
			}

			const saved = await this.saveCurrentWorkflow();
			if (saved) this.$store.dispatch('settings/fetchPromptsData');
		},
		onTagsEditEnable() {
			this.$data.appliedTagIds = this.currentWorkflowTagIds;
			this.$data.isTagsEditEnabled = true;

			setTimeout(() => {
				// allow name update to occur before disabling name edit
				this.$data.isNameEditEnabled = false;
				this.$data.tagsEditBus.$emit('focus');
			}, 0);
		},
		async onTagsUpdate(tags: string[]) {
			this.$data.appliedTagIds = tags;
		},

		async onTagsBlur() {
			const current = this.currentWorkflowTagIds;
			const tags = this.$data.appliedTagIds;
			if (!hasChanged(current, tags)) {
				this.$data.isTagsEditEnabled = false;

				return;
			}
			if (this.$data.tagsSaving) {
				return;
			}
			this.$data.tagsSaving = true;

			const saved = await this.saveCurrentWorkflow({ tags });
			this.$telemetry.track('User edited workflow tags', {
				workflow_id: this.currentWorkflowId as string,
				new_tag_count: tags.length,
			});

			this.$data.tagsSaving = false;
			if (saved) {
				this.$data.isTagsEditEnabled = false;
			}
		},
		onTagsEditEsc() {
			this.$data.isTagsEditEnabled = false;
		},
		onNameToggle() {
			this.$data.isNameEditEnabled = !this.$data.isNameEditEnabled;
			if (this.$data.isNameEditEnabled) {
				if (this.$data.isTagsEditEnabled) {
					// @ts-ignore
					this.onTagsBlur();
				}

				this.$data.isTagsEditEnabled = false;
			}
		},
		async onNameSubmit(name: string, cb: (saved: boolean) => void, needToSetDefaultName: boolean) {
			const newName = name.trim();
			if (!newName) {
				this.isNameEditError = true;
				let message = this.$locale.baseText(
					'workflowDetails.showMessage.nameMissing.message.enterNameOrPressEsc',
				);
				if (needToSetDefaultName) {
					message = this.$locale.baseText(
						'workflowDetails.showMessage.nameMissing.message.enterName',
					);
				}
				this.$showMessage({
					title: this.$locale.baseText('workflowDetails.showMessage.nameMissing.title'),
					message: message,
					type: 'error',
				});

				cb(false);
				return;
			}

			this.isNameEditError = false;
			if (newName === this.workflowName) {
				this.$data.isNameEditEnabled = false;

				cb(true);
				return;
			}

			// Verify that names are not duplicated in the same organization and the same type
			const workflowList = await this.restApi().getWorkflows();
			const channelTags = CHANNEL_TYPE.map((channel) => channel.tag);

			const isMatchingType = (tagNames: string[]) => {
				switch (this.currentWorkflowType) {
					case PRESET_WORKFLOW_TYPE:
						return tagNames.includes(PRESET_WORKFLOW_TYPE);
					case BOARD_AUTOMATION_WORKFLOW_TYPE:
						return (
							tagNames.includes(AUTOMATION_WORKFLOW_TYPE) && tagNames.includes(BOARD_WORKFLOW_TYPE)
						);
					case CHANNEL_AUTOMATION_WORKFLOW_TYPE:
						return (
							tagNames.includes(AUTOMATION_WORKFLOW_TYPE) &&
							tagNames.includes(this.currentChannelType)
						);
					case AUTOMATION_WORKFLOW_TYPE:
						return (
							tagNames.includes(AUTOMATION_WORKFLOW_TYPE) &&
							!tagNames.includes(BOARD_WORKFLOW_TYPE) &&
							tagNames.findIndex((tagName) => channelTags.includes(tagName)) === -1
						);
					case CHANNEL_WORKFLOW_TYPE:
						return (
							tagNames.includes(this.currentChannelType) &&
							!tagNames.includes(AUTOMATION_WORKFLOW_TYPE)
						);
					default:
						return true;
				}
			};

			const sameTypeWorkflows = workflowList.filter((workflow) =>
				isMatchingType(workflow.tags.map((tag) => tag.name)),
			);
			const sameTypeNames = new Set(sameTypeWorkflows.map((workflow) => workflow.name));

			if (sameTypeNames.has(newName)) {
				this.isNameEditError = true;
				this.$showMessage({
					title: this.$locale.baseText('workflowDetails.showMessage.nameInUse.title'),
					message: this.$locale.baseText('workflowDetails.showMessage.nameInUse.message'),
					type: 'error',
				});
				cb(false);
				return;
			}

			const saved = await this.saveCurrentWorkflow({ name });
			if (saved) {
				this.$data.isNameEditEnabled = false;
				this.$store.commit('imbrace/setNeedToSetDefaultName', false);
			}
			cb(saved);
		},
		openChat() {
			const windowFeatures = {
				width: 500,
				height: 624,
				left: window.outerWidth - 500 + window.screenLeft,
				top: window.outerHeight - 624 + window.screenTop,
				scrollbars: 'no',
				resizable: 'yes',
				status: 'no',
				titlebar: 'no',
				location: 'no',
				toolbar: 'no',
				menubar: 'no',
			};

			const featuresString = Object.entries(windowFeatures)
				.map(([key, value]) => `${key}=${value}`)
				.join(',');

			window.open(this.testChatUrl, 'testChat', featuresString);
		},
	},
	watch: {
		currentWorkflowId() {
			this.$data.isTagsEditEnabled = false;
			this.$data.isNameEditEnabled = false;
		},
	},
});
