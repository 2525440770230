
import Vue from 'vue';
import VSwitch from '../v-switch.vue';

export default Vue.extend({
	name: 'Typography',
	components: {
		VSwitch,
	},
	props: {
		variant: {
			required: false,
			default: 'Body',
			validator: (value: string) => {
				return [
					'Heading1',
					'Heading2',
					'SubHeading1',
					'SubHeading2',
					'SubHeading2Tight',
					'SubHeading2Light',
					'Inherit',
					'Caption',
					'CaptionBold',
					'Body',
					'BodyBold',
					'BodyTight',
				].includes(value);
			},
		},
	},
	computed: {
		variantMappingClass(): Object {
			const classes: Record<string, string | string[]> = {
				Heading1: this.$style.heading1,
				Heading2: this.$style.heading2,
				SubHeading1: this.$style.subheading1,
				SubHeading2: this.$style.subheading2,
				SubHeading2Tight: [this.$style.subheading2, this.$style.tight],
				SubHeading2Light: [this.$style.subheading2, this.$style.light],
				Body: this.$style.body,
				BodyBold: [this.$style.body, this.$style.bold],
				BodyTight: [this.$style.body, this.$style.tight],
				Caption: this.$style.caption,
				CaptionBold: [this.$style.caption, this.$style.bold],
				Inherit: this.$style.inherit,
			};
			return classes[this.variant];
		},
	},
});
