
import Vue from "vue";
import ExpandableInputEdit from "@/components/ExpandableInput/ExpandableInputEdit.vue";
import ExpandableInputPreview from "@/components/ExpandableInput/ExpandableInputPreview.vue";
import { AUTOMATION_WORKFLOW_TYPE, BOARD_AUTOMATION_WORKFLOW_TYPE, CHANNEL_AUTOMATION_WORKFLOW_TYPE, PRESET_WORKFLOW_TYPE } from "@/constants";

export default Vue.extend({
	name: "InlineTextEdit",
	components: { ExpandableInputEdit, ExpandableInputPreview },
	props: ['isEditEnabled', 'value', 'placeholder', 'maxLength', 'previewValue', 'isNameReadOnly', 'isNameEditError'],
	data() {
		return {
			newValue: '',
			escPressed: false,
			disabled: false,
			inputBus: new Vue(),
			needToSetDefaultName: false,
		};
	},
	methods: {
		onInput(newValue: string) {
			if (this.disabled) {
				return;
			}

			this.newValue = newValue;
		},
		onClick() {
			if (this.disabled) {
				return;
			}

			this.$data.newValue = this.$props.value;
			this.$emit('toggle');
		},
		onBlur() {
			if (this.disabled) {
				return;
			}

			if (!this.$data.escPressed || this.$props.isNameEditError) {
				this.submit();
			}
			this.$data.escPressed = false;
		},
		submit() {
			if (this.disabled) {
				return;
			}

			const onSubmit = (updated: boolean) => {
				this.$data.disabled = false;

				if (!updated) {
					this.$data.inputBus.$emit('focus');
				}
			};

			this.$data.disabled = true;
			this.$emit('submit', this.newValue, onSubmit, this.needToSetDefaultName);
		},
		onEscape() {
			if (this.disabled || this.needToSetDefaultName) {
				return;
			}

			this.$data.escPressed = true;
			this.$emit('toggle');
		},
	},
	mounted() {
		// The first step of add new channel automation workflow,
		// will activate the name field to prompt the user to enter the workflow name
		const workflowId = this.$route.params.name;
		const currentWorkflowType = this.$store.getters['imbrace/currentWorkflowType'];
		const typeToSetDefaultName = [CHANNEL_AUTOMATION_WORKFLOW_TYPE, AUTOMATION_WORKFLOW_TYPE, BOARD_AUTOMATION_WORKFLOW_TYPE, PRESET_WORKFLOW_TYPE];
		if (!workflowId && typeToSetDefaultName.includes(currentWorkflowType)) {
			this.$store.commit('imbrace/setNeedToSetDefaultName', true);
			this.needToSetDefaultName = true;
			this.$data.newValue = '';
			this.$emit('toggle');
		}
	},
});
