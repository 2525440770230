import { ACTIONS_CATEGORY, ALL_CATEGORY, ALL_NODE_FILTER, CHANNEL_ACTIONS_CATEGORY, CHANNEL_TRIGGERS_CATEGORY, FUNCTIONS_CATEGORY, INTEGRATIONS_CATEGORY, INTERNAL_USE_CATEGORY, LOGICS_CATEGORY, PERSONALIZED_CATEGORY, PRESET_CATEGORY, REGULAR_NODE_FILTER, SUBCATEGORY_DESCRIPTIONS, TRIGGER_NODE_FILTER, TRIGGERS_CATEGORY, UNCATEGORIZED_CATEGORY, UNCATEGORIZED_SUBCATEGORY } from '@/constants';
import { ChannelType, ICategoriesWithNodes, INodeCreateElement, INodeItemProps } from '@/Interface';
import { INodeTypeDescription } from 'n8n-workflow';

const addNodeToCategory = (accu: ICategoriesWithNodes, nodeType: INodeTypeDescription, category: string, subcategory: string) => {
	if (!accu[category]) {
		accu[category] = {};
	}
	if (!accu[category][subcategory]) {
		accu[category][subcategory] = {
			triggerCount: 0,
			regularCount: 0,
			nodes: [],
		};
	}
	const isTrigger = nodeType.group.includes('trigger');
	if (isTrigger) {
		accu[category][subcategory].triggerCount++;
	}
	if (!isTrigger) {
		accu[category][subcategory].regularCount++;
	}
	accu[category][subcategory].nodes.push({
		type: 'node',
		key: nodeType.presetId ? `${category}_${nodeType.name}_${nodeType.presetId}` : `${category}_${nodeType.name}`,
		category,
		properties: {
			nodeType,
			subcategory,
		},
		includedByTrigger: isTrigger,
		includedByRegular: !isTrigger,
	});
};

export const getCategoriesWithNodes = (nodeTypes: INodeTypeDescription[], personalizedNodeTypes: string[]): ICategoriesWithNodes => {
	const sorted = [...nodeTypes].sort((a: INodeTypeDescription, b: INodeTypeDescription) => a.displayName > b.displayName? 1 : -1);
	return sorted.reduce(
		(accu: ICategoriesWithNodes, nodeType: INodeTypeDescription) => {
			// Add nodes to "All" category
			addNodeToCategory(accu, nodeType, ALL_CATEGORY, UNCATEGORIZED_SUBCATEGORY);

			if (personalizedNodeTypes.includes(nodeType.name)) {
				addNodeToCategory(accu, nodeType, PERSONALIZED_CATEGORY, UNCATEGORIZED_SUBCATEGORY);
			}

			if (!nodeType.codex || !nodeType.codex.categories) {
				addNodeToCategory(accu, nodeType, UNCATEGORIZED_CATEGORY, UNCATEGORIZED_SUBCATEGORY);
				return accu;
			}

			if (nodeType.group.includes('trigger') && !nodeType.codex.categories.includes(CHANNEL_TRIGGERS_CATEGORY)) {
				addNodeToCategory(accu, nodeType, TRIGGERS_CATEGORY, UNCATEGORIZED_SUBCATEGORY);
			}

			nodeType.codex.categories.forEach((_category: string, index: number) => {
				const category = _category.trim();
				const subcategories = 
					nodeType.codex &&
					nodeType.codex.subcategories &&
					nodeType.codex.subcategories[category]
						? nodeType.codex.subcategories[category]
						: null;

				if (subcategories === null || subcategories.length === 0) {
					addNodeToCategory(accu, nodeType, category, UNCATEGORIZED_SUBCATEGORY);
				} else {
					subcategories.forEach((subcategory) => {
						addNodeToCategory(accu, nodeType, category, subcategory);
					});
				}
			});
			return accu;
		},
		{},
	);
};

const getCategories = (categoriesWithNodes: ICategoriesWithNodes): string[] => {
	return [ACTIONS_CATEGORY, TRIGGERS_CATEGORY, CHANNEL_TRIGGERS_CATEGORY, CHANNEL_ACTIONS_CATEGORY, INTEGRATIONS_CATEGORY, FUNCTIONS_CATEGORY, LOGICS_CATEGORY, PRESET_CATEGORY, INTERNAL_USE_CATEGORY, ALL_CATEGORY];

	// const displayCategories = [ACTIONS_CATEGORY, CHANNEL_ACTIONS_CATEGORY, INTEGRATIONS_CATEGORY, FUNCTIONS_CATEGORY, LOGICS_CATEGORY];
	// const categories = Object.keys(categoriesWithNodes);
	// const sorted = categories.filter(
	// 	(category: string) =>
	// 		displayCategories.includes(category),
	// );
	// sorted.sort();

	// return [...sorted, PRESET_CATEGORY, ALL_CATEGORY];
};

export const getCategorizedList = (categoriesWithNodes: ICategoriesWithNodes, currentChannelType: ChannelType | null): INodeCreateElement[] => {
	const categories = getCategories(categoriesWithNodes);

	return categories.reduce(
		(accu: INodeCreateElement[], category: string) => {
			if (!categoriesWithNodes[category]) {
				return accu;
			}

			const categoryEl: INodeCreateElement = {
				type: 'category',
				key: category,
				category,
				properties: {
					expanded: false,
				},
			};

			const subcategories = Object.keys(categoriesWithNodes[category]);
			if (subcategories.length === 1) {
				const subcategory = categoriesWithNodes[category][
					subcategories[0]
				];
				if (subcategory.triggerCount > 0) {
					categoryEl.includedByTrigger = subcategory.triggerCount > 0;
				}
				if (subcategory.regularCount > 0) {
					categoryEl.includedByRegular = subcategory.regularCount > 0;
				}
				return [...accu, categoryEl, ...subcategory.nodes];
			}

			// Only display nodes in "Channel Actions" category that match currentChannelType
			if (currentChannelType) {
				const subcategory = categoriesWithNodes[CHANNEL_ACTIONS_CATEGORY][currentChannelType];
				if (subcategory.triggerCount > 0) {
					categoryEl.includedByTrigger = subcategory.triggerCount > 0;
				}
				if (subcategory.regularCount > 0) {
					categoryEl.includedByRegular = subcategory.regularCount > 0;
				}
				return [...accu, categoryEl, ...subcategory.nodes];
			}

			subcategories.sort();
			const subcategorized = subcategories.reduce(
				(accu: INodeCreateElement[], subcategory: string) => {
					const subcategoryEl: INodeCreateElement = {
						type: 'subcategory',
						key: `${category}_${subcategory}`,
						category,
						properties: {
							subcategory,
							description: SUBCATEGORY_DESCRIPTIONS[category][subcategory],
						},
						includedByTrigger: categoriesWithNodes[category][subcategory].triggerCount > 0,
						includedByRegular: categoriesWithNodes[category][subcategory].regularCount > 0,
					};

					if (subcategoryEl.includedByTrigger) {
						categoryEl.includedByTrigger = true;
					}
					if (subcategoryEl.includedByRegular) {
						categoryEl.includedByRegular = true;
					}

					accu.push(subcategoryEl);
					return accu;
				},
				[],
			);

			return [...accu, categoryEl, ...subcategorized];
		},
		[],
	);
};

export const matchesSelectType = (el: INodeCreateElement, selectedType: string) => {
	if (selectedType === REGULAR_NODE_FILTER && el.includedByRegular) {
		return true;
	}
	if (selectedType === TRIGGER_NODE_FILTER && el.includedByTrigger) {
		return true;
	}

	return selectedType === ALL_NODE_FILTER;
};

const matchesAlias = (nodeType: INodeTypeDescription, filter: string): boolean => {
	if (!nodeType.codex || !nodeType.codex.alias) {
		return false;
	}

	return nodeType.codex.alias.reduce((accu: boolean, alias: string) => {
		return accu || alias.toLowerCase().indexOf(filter) > -1;
	}, false);
};

export const matchesNodeType = (el: INodeCreateElement, filter: string) => {
	const nodeType = (el.properties as INodeItemProps).nodeType;

	return nodeType.displayName.toLowerCase().indexOf(filter) !== -1 || matchesAlias(nodeType, filter);
};
