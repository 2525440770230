
import Vue from "vue";
import ExpandableInputBase from "./ExpandableInputBase.vue";

export default Vue.extend({
	components: { ExpandableInputBase },
	name: "ExpandableInputEdit",
	props: ['value', 'placeholder', 'maxlength', 'autofocus', 'eventBus', 'isNameEditError'],
	mounted() {
		// autofocus on input element is not reliable
		if (this.$props.autofocus && this.$refs.input) {
			this.focus();
		}

		if (this.$props.eventBus) {
			this.$props.eventBus.$on('focus', () => {
				this.focus();
			});
		}
	},
	methods: {
		focus() {
			if (this.$refs.input) {
				(this.$refs.input as HTMLInputElement).focus();
			}
		},
		onInput() {
			this.$emit('input', (this.$refs.input as HTMLInputElement).value);
		},
		onEnter() {
			this.$emit('enter', (this.$refs.input as HTMLInputElement).value);
		},
		onClickOutside(e: Event) {
			if (e.type === 'click') {
				this.$emit('blur', (this.$refs.input as HTMLInputElement).value);
			}
		},
		onEscape() {
			this.$emit('esc');
		},
	},
});
