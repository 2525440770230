
import type {
	INodeProperties,
	INode,
	ResourceMapperValue,
	ResourceMapperField,
	INodeParameters,
	INodeTypeDescription,
	INodeIssueData,
	INodeIssueObjectProperty,
} from 'n8n-workflow';
import mixins from 'vue-typed-mixins';
import { externalHooks } from '@/components/mixins/externalHooks';
import { NodeHelpers } from 'n8n-workflow';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { ResourceMapperReqParams, IUpdateInformation } from '@/Interface';
import MappingModeSelect from './MappingModeSelect.vue';
import MatchingColumnsSelect from './MatchingColumnsSelect.vue';
import MappingFields from './MappingFields.vue';
import { fieldCannotBeDeleted, isResourceMapperValue, parseResourceMapperFieldName } from '@/utils';

export default mixins(externalHooks, nodeHelpers, showMessage, workflowHelpers).extend({
	name: 'ResourceMapper',
	props: {
		parameter: Object as () => INodeProperties,
		node: Object as () => INode,
		path: String,
		inputSize: String,
		labelSize: String,
		dependentParametersValues: String,
		teleported: Boolean,
	},

	components: {
		MappingFields,
		MappingModeSelect,
		MatchingColumnsSelect,
	},

	data() {
		return {
			// TODO: solve error: [vuex] do not mutate vuex store state outside mutation handlers.
			paramValue: {
				mappingMode: 'defineBelow',
				value: {},
				matchingColumns: [] as string[],
				schema: [] as ResourceMapperField[],
			} as ResourceMapperValue,
			parameterValues: {} as INodeParameters,
			loading: false,
			refreshInProgress: false, // Shows inline loader when refreshing fields
			loadingError: false,
		};
	},

	watch: {
		async dependentParametersValues(newVal, oldVal) {
			// Reload fields to map when dependent parameters change
			if (oldVal !== null && newVal !== null && oldVal !== newVal) {
				this.paramValue = {
					...this.paramValue,
					value: null,
					schema: [],
				};
				this.emitValueChanged();
				await this.initFetching();
				this.setDefaultFieldValues(true);
			}
		},
	},

	computed: {
		resourceMapperMode(): string | undefined {
			return this.parameter.typeOptions?.resourceMapper?.mode;
		},

		nodeType(): INodeTypeDescription | null {
			if (this.node) {
				return this.$store.getters.nodeType(this.node.type, this.node.typeVersion);
			}
			return null;
		},

		showMappingModeSelect(): boolean {
			return this.parameter.typeOptions?.resourceMapper?.supportAutoMap !== false;
		},

		showMatchingColumnsSelector(): boolean {
			return (
				!this.loading &&
				this.parameter.typeOptions?.resourceMapper?.mode !== 'add' &&
				this.paramValue.schema.length > 0
			);
		},

		showMappingFields(): boolean {
			const showMappingFields =
				this.paramValue.mappingMode === 'defineBelow' &&
				!this.loading &&
				!this.loadingError &&
				this.paramValue.schema.length > 0 &&
				this.hasAvailableMatchingColumns;
			return showMappingFields;
		},

		matchingColumns(): string[] {
			if (!this.showMatchingColumnsSelector) {
				return [];
			}
			if (this.paramValue.matchingColumns) {
				return this.paramValue.matchingColumns;
			}
			return this.defaultSelectedMatchingColumns;
		},

		hasAvailableMatchingColumns(): boolean {
			if (this.resourceMapperMode !== 'add') {
				return (
					this.paramValue.schema.filter(
						(field) =>
							(field.canBeUsedToMatch || field.defaultMatch) &&
							field.display !== false &&
							field.removed !== true,
					).length > 0
				);
			}
			return true;
		},

		defaultSelectedMatchingColumns(): string[] {
			return this.paramValue.schema.length === 1
				? [this.paramValue.schema[0].id]
				: this.paramValue.schema.reduce((acc, field) => {
					if (field.defaultMatch) {
						acc.push(field.id);
					}
					return acc;
				  }, [] as string[]);
		},

		pluralFieldWord() {
			return (
				this.parameter.typeOptions?.resourceMapper?.fieldWords?.plural ||
				this.$locale.baseText('generic.fields')
			);
		},
	},

	methods: {
		async initFetching(inlineLading = false) {
			// Fetch the remote parameters
			// and update the current field
			// with the new values
			this.loadingError = false;
			if (inlineLading) {
				this.refreshInProgress = true;
			} else {
				this.loading = true;
			}
			try {
				await this.loadFieldsToMap();
				if (!this.paramValue.matchingColumns || this.paramValue.matchingColumns.length === 0) {
					this.onMatchingColumnsChanged(this.defaultSelectedMatchingColumns);
				}
			} catch (error) {
				this.loadingError = true;
			} finally {
				this.loading = false;
				this.refreshInProgress = false;
			}
		},

		async loadFieldsToMap() {
			if (!this.node) {
				return;
			}

			// Build the request parameters
			const requestParams: ResourceMapperReqParams = {
				nodeTypeAndVersion: {
					name: this.node?.type,
					version: this.node.typeVersion,
				},
				currentNodeParameters: this.resolveRequiredParameters(
					this.parameter,
					this.node.parameters,
				) as INodeParameters,
				path: this.path,
				methodName: this.parameter.typeOptions?.resourceMapper?.resourceMapperMethod,
				credentials: this.node.credentials,
			};

			// Fetch the remote resource mapping parameters
			const fetchedFields = await this.restApi().getResourceMapperFields(requestParams);
			if (fetchedFields !== null) {
				const newSchema = fetchedFields.fields.map((field) => {
					const existingField = this.paramValue.schema.find((f) => f.id === field.id);
					if (existingField) {
						field.removed = existingField.removed;
					} else if (this.paramValue.value !== null && !(field.id in this.paramValue.value)) {
						// New fields are shown by default
						field.removed = false;
					}
					return field;
				});
				this.paramValue = {
					...this.paramValue,
					schema: newSchema,
				};

				// Rerender
				this.emitValueChanged();
			}
		},

		onModeChanged(mode: string) {
			this.paramValue.mappingMode = mode;
			if (mode === 'defineBelow') {
				this.initFetching();
			} else {
				this.loadingError = false;
			}
			this.emitValueChanged();
		},

		setDefaultFieldValues(forceMatchingFieldsUpdate = false): void {
			this.paramValue.value = {};
			const hideAllFields = this.parameter.typeOptions?.resourceMapper?.addAllFields === false;
			this.paramValue.schema.forEach((field) => {
				if (this.paramValue.value) {
					// Hide all non-required fields if it's configured in node definition
					if (hideAllFields) {
						field.removed = !field.required;
					}
					if (field.type === 'boolean') {
						this.paramValue.value = {
							...this.paramValue.value,
							[field.id]: false,
						};
					} else {
						this.paramValue.value = {
							...this.paramValue.value,
							[field.id]: null,
						};
					}
				}
			});
			this.emitValueChanged();
			if (!this.paramValue.matchingColumns || forceMatchingFieldsUpdate) {
				this.paramValue.matchingColumns = this.defaultSelectedMatchingColumns;
				this.emitValueChanged();
			}
		},

		updateNodeIssues(): void {
			if (this.node) {
				const parameterIssues = NodeHelpers.getNodeParametersIssues(
					this.nodeType?.properties || [],
					this.node,
				);

				if (parameterIssues) {
					let newIssues: INodeIssueObjectProperty | null = null;
					if (parameterIssues !== null) {
						newIssues = parameterIssues.parameters!;
					}

					this.$store.commit('setNodeIssue', {
						node: this.node.name,
						type: 'parameters',
						value: newIssues,
					} as INodeIssueData);
				}
			}
		},

		onMatchingColumnsChanged(matchingColumns: string[]): void {
			this.paramValue = {
				...this.paramValue,
				matchingColumns,
			};
			// Set all matching fields to be visible
			this.paramValue.schema.forEach((field) => {
				if (this.paramValue.matchingColumns?.includes(field.id)) {
					field.removed = false;
					this.paramValue.schema.splice(this.paramValue.schema.indexOf(field), 1, field);
				}
			});
			if (!this.loading) {
				this.emitValueChanged();
			}
		},

		fieldValueChanged(updateInfo: IUpdateInformation): void {
			let newValue = null;
			if (
				updateInfo.value !== undefined &&
				updateInfo.value !== '' &&
				updateInfo.value !== null &&
				isResourceMapperValue(updateInfo.value)
			) {
				newValue = updateInfo.value;
			}
			const fieldName = parseResourceMapperFieldName(updateInfo.name);
			if (fieldName && this.paramValue.value) {
				this.paramValue.value = {
					...this.paramValue.value,
					[fieldName]: newValue,
				};
				this.emitValueChanged();
			}
		},

		removeField(name: string): void {
			if (name === 'removeAllFields') {
				return this.removeAllFields();
			}
			const fieldName = parseResourceMapperFieldName(name);
			if (fieldName) {
				const field = this.paramValue.schema.find((f) => f.id === fieldName);
				if (field) {
					this.deleteField(field);
					this.emitValueChanged();
				}
			}
		},

		removeAllFields(): void {
			this.paramValue.schema.forEach((field) => {
				if (
					!fieldCannotBeDeleted(
						field,
						this.showMatchingColumnsSelector,
						this.resourceMapperMode,
						this.matchingColumns,
					)
				) {
					this.deleteField(field);
				}
			});
			this.emitValueChanged();
		},

		// Delete a single field from the mapping (set removed flag to true and delete from value)
		// Used when removing one or all fields
		deleteField(field: ResourceMapperField): void {
			if (this.paramValue.value) {
				delete this.paramValue.value[field.id];
				field.removed = true;
				this.paramValue.schema.splice(this.paramValue.schema.indexOf(field), 1, field);
			}
		},

		addField(name: string): void {
			if (name === 'addAllFields') {
				return this.addAllFields();
			}
			if (name === 'removeAllFields') {
				return this.removeAllFields();
			}
			this.paramValue.value = {
				...this.paramValue.value,
				[name]: null,
			};
			const field = this.paramValue.schema.find((f) => f.id === name);
			if (field) {
				field.removed = false;
				this.paramValue.schema.splice(this.paramValue.schema.indexOf(field), 1, field);
			}

			this.emitValueChanged();
		},

		addAllFields(): void {
			const newValues: { [name: string]: null } = {};
			this.paramValue.schema.forEach((field) => {
				if (field.display && field.removed) {
					newValues[field.id] = null;
					field.removed = false;
					this.paramValue.schema.splice(this.paramValue.schema.indexOf(field), 1, field);
				}
			});
			this.paramValue.value = {
				...this.paramValue.value,
				...newValues,
			};

			this.emitValueChanged();
		},

		emitValueChanged(): void {
			this.pruneParamValues();
			this.$emit('valueChanged', {
				name: `${this.path}`,
				value: this.paramValue,
				node: this.node?.name,
			});
			this.updateNodeIssues();
		},

		pruneParamValues(): void {
			if (!this.paramValue.value) {
				return;
			}
			const valueKeys = Object.keys(this.paramValue.value);
			valueKeys.forEach((key) => {
				if (this.paramValue.value && this.paramValue.value[key] === null) {
					delete this.paramValue.value[key];
				}
			});
		},
	},

	async mounted() {
		if (this.node) {
			this.parameterValues = {
				...this.parameterValues,
				parameters: this.node.parameters,
			};
		}
		const params = this.parameterValues.parameters as INodeParameters;
		const parameterName = this.parameter.name;

		if (!(parameterName in params)) {
			return;
		}
		let hasSchema = false;
		const nodeValues = params[parameterName] as unknown as ResourceMapperValue;
		this.paramValue = {
			...this.paramValue,
			...nodeValues,
		};
		if (!this.paramValue.schema) {
			this.paramValue = {
				...this.paramValue,
				schema: [],
			};
		} else {
			hasSchema = this.paramValue.schema.length > 0;
		}
		Object.keys(this.paramValue.value || {}).forEach((key) => {
			if (this.paramValue.value && this.paramValue.value[key] === '') {
				this.paramValue = {
					...this.paramValue,
					value: {
						...this.paramValue.value,
						[key]: null,
					},
				};
			}
		});
		if (nodeValues.matchingColumns) {
			this.paramValue = {
				...this.paramValue,
				matchingColumns: nodeValues.matchingColumns,
			};
		}
		if (!hasSchema) {
			// Only fetch a schema if it's not already set
			await this.initFetching();
		}
		// Set default values if this is the first time the parameter is being set
		if (!this.paramValue.value) {
			this.setDefaultFieldValues();
		}
		this.updateNodeIssues();
	},
});
