
import Vue from 'vue';

import { IUpdateInformation } from '@/Interface';

import ParameterInput from '@/components/ParameterInput.vue';
import InputHint from './ParameterInputHint.vue';

export default Vue.extend({
	name: 'ParameterInputFull',
	components: {
		ParameterInput,
		InputHint,
	},
	data() {
		return {
			focused: false,
		};
	},
	props: [
		'displayOptions',
		'isReadOnly',
		'parameter',
		'path',
		'value',
		'id',
		'dynamicMappingData',
		'dynamicDependsOnParameterValue',
	],
	methods: {
		onNodeExecute() {
			this.$emit('execute');
		},
		getArgument(argumentName: string): string[] | string | number | boolean | undefined {
			if (this.parameter.typeOptions === undefined) {
				return undefined;
			}

			if (this.parameter.typeOptions[argumentName] === undefined) {
				return undefined;
			}

			return this.parameter.typeOptions[argumentName];
		},
		valueChanged(parameterData: IUpdateInformation) {
			this.$emit('valueChanged', parameterData);
		},
	},
});
