
import mixins from 'vue-typed-mixins';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import type {
	INodeProperties,
	INodePropertyTypeOptions,
	ResourceMapperField,
	ResourceMapperFields,
	ResourceMapperTypeOptions,
} from 'n8n-workflow';
export default mixins(externalHooks, nodeHelpers, showMessage, workflowHelpers).extend({
	name: 'MatchingColumnsSelect',
	props: {
		parameter: Object as () => INodeProperties,
		initialValue: Array as () => string[] | string,
		fieldsToMap: Array as () => ResourceMapperFields['fields'],
		typeOptions: Object as () => INodePropertyTypeOptions,
		labelSize: String,
		inputSize: String,
		loading: Boolean,
		serviceName: String,
		teleported: Boolean,
		refreshInProgress: Boolean,
	},

	data() {
		return {
			selected: this.initialValue as string[] | string,
		};
	},

	watch: {
		initialValue() {
			this.selected =
				this.resourceMapperTypeOptions?.multiKeyMatch === true
					? this.initialValue
					: this.initialValue[0];
		},
	},

	computed: {
		resourceMapperTypeOptions(): ResourceMapperTypeOptions | undefined {
			return this.parameter.typeOptions?.resourceMapper;
		},

		singularFieldWord(): string {
			const singularFieldWord =
				this.resourceMapperTypeOptions?.fieldWords?.singular ||
				this.$locale.baseText('generic.field');
			return singularFieldWord;
		},

		singularFieldWordCapitalized(): string {
			return this.singularFieldWord.charAt(0).toUpperCase() + this.singularFieldWord.slice(1);
		},

		pluralFieldWord(): string {
			return (
				this.resourceMapperTypeOptions?.fieldWords?.plural ||
				this.$locale.baseText('generic.fields')
			);
		},

		pluralFieldWordCapitalized(): string {
			return this.pluralFieldWord.charAt(0).toUpperCase() + this.pluralFieldWord.slice(1);
		},

		availableMatchingFields(): ResourceMapperField[] {
			return this.fieldsToMap.filter((field) => {
				return (field.canBeUsedToMatch || field.defaultMatch) && field.display !== false;
			});
		},

		fieldLabel(): string {
			if (this.resourceMapperTypeOptions?.matchingFieldsLabels?.title) {
				return this.resourceMapperTypeOptions.matchingFieldsLabels.title;
			}
			const fieldWord =
				this.resourceMapperTypeOptions?.multiKeyMatch === true
					? this.pluralFieldWordCapitalized
					: this.singularFieldWordCapitalized;
			return this.$locale.baseText('resourceMapper.columnsToMatchOn.label', {
				interpolate: {
					fieldWord,
				},
			});
		},

		fieldDescription(): string {
			if (this.resourceMapperTypeOptions?.matchingFieldsLabels?.hint) {
				return this.resourceMapperTypeOptions.matchingFieldsLabels.hint;
			}
			const labeli18nKey =
				this.resourceMapperTypeOptions?.multiKeyMatch === true
					? 'resourceMapper.columnsToMatchOn.multi.description'
					: 'resourceMapper.columnsToMatchOn.single.description';
			return this.$locale.baseText(labeli18nKey, {
				interpolate: {
					fieldWord:
						this.resourceMapperTypeOptions?.multiKeyMatch === true
							? `${this.pluralFieldWord}`
							: `${this.singularFieldWord}`,
				},
			});
		},

		fieldTooltip(): string {
			if (this.resourceMapperTypeOptions?.matchingFieldsLabels?.description) {
				return this.resourceMapperTypeOptions.matchingFieldsLabels.description;
			}
			return this.$locale.baseText('resourceMapper.columnsToMatchOn.tooltip', {
				interpolate: {
					fieldWord:
						this.resourceMapperTypeOptions?.multiKeyMatch === true
							? `${this.pluralFieldWord}`
							: `${this.singularFieldWord}`,
				},
			});
		},

		parameterActions(): Array<{ label: string; value: string; disabled?: boolean }> {
			return [
				{
					label: this.$locale.baseText('resourceMapper.refreshFieldList', {
						interpolate: { fieldWord: this.singularFieldWordCapitalized },
					}),
					value: 'refreshFieldList',
				},
			];
		},

		fetchingFieldsLabel(): string {
			return this.$locale.baseText('resourceMapper.fetchingFields.message', {
				interpolate: {
					fieldWord: this.pluralFieldWord,
				},
			});
		},
	},

	methods: {
		emitValueChanged() {
			if (this.selected) {
				this.$emit(
					'matchingColumnsChanged',
					Array.isArray(this.selected) ? this.selected : [this.selected],
				);
			}
		},

		onSelectionChange(value: string | string[]) {
			if (this.resourceMapperTypeOptions?.multiKeyMatch === true) {
				this.selected = value as string[];
			} else {
				this.selected = value as string;
			}
			this.emitValueChanged();
		},

		refreshFieldList() {
			this.$emit('refreshFieldList');
		},

		onParameterActionSelected(action: string): void {
			switch (action) {
				case 'refreshFieldList':
					this.refreshFieldList();
					break;
				default:
					break;
			}
		},
	},
});
