
import Vue from "vue";
import mixins from "vue-typed-mixins";

import { ITag, IWorkflowDataUpdate, IWorkflowShortResponse } from "@/Interface";

import { restApi } from "@/components/mixins/restApi";
import { genericHelpers } from "@/components/mixins/genericHelpers";
import { workflowHelpers } from "@/components/mixins/workflowHelpers";
import { showMessage } from "@/components/mixins/showMessage";

import TagsContainer from "@/components/TagsContainer.vue";
import TagsDropdown from "@/components/TagsDropdown.vue";
import WorkflowActivator from "@/components/WorkflowActivator.vue";
import { convertToDisplayDate } from "@/components/helpers";
import { mapGetters } from "vuex";
import { DEFAULT_WORKFLOW_TYPE, AUTOMATION_WORKFLOW_TYPE, MODAL_CANCEL, MODAL_CLOSE, MODAL_CONFIRMED, PRESET_WORKFLOW_TYPE, VIEWS, CHANNEL_WORKFLOW_TYPE, CHANNEL_AUTOMATION_WORKFLOW_TYPE, CHANNEL_TYPE } from "@/constants";

export default mixins(
	genericHelpers,
	restApi,
	showMessage,
	workflowHelpers,
).extend({
	name: "WorkflowList",
	components: {
		WorkflowActivator,
		TagsContainer,
		TagsDropdown,
	},
	data() {
		return {
			filterText: "",
			isDataLoading: false,
			workflows: [] as IWorkflowShortResponse[],
			filterTagIds: [] as string[],
			prevFilterTagIds: [] as string[],
			windowWidth: 0,
			tabList: [DEFAULT_WORKFLOW_TYPE, CHANNEL_WORKFLOW_TYPE, CHANNEL_AUTOMATION_WORKFLOW_TYPE, AUTOMATION_WORKFLOW_TYPE, PRESET_WORKFLOW_TYPE],
			hasChannel: [CHANNEL_WORKFLOW_TYPE, CHANNEL_AUTOMATION_WORKFLOW_TYPE],
		};
	},
	computed: {
		...mapGetters("settings", ["areTagsEnabled"]),
		...mapGetters("tags", ["allTags"]),
		...mapGetters("imbrace", ["currentWorkflowType", "currentChannelType"]),
		channelNameList(): string[] {
			return this.allTags
				.filter((tag: ITag) => CHANNEL_TYPE.find((channel) => channel.tag === tag.name))
				.map((tag: ITag) => tag.name);
		},
		displayChannelTabs(): boolean {
			if (this.hasChannel.includes(this.currentWorkflowType)) {
				return true;
			}
			return false;
		},
		displayCreateNewButton(): boolean {
			if (this.hasChannel.includes(this.currentWorkflowType)) {
				return false;
			}
			return true;
		},
		displayActiveColumn(): boolean {
			if (this.currentWorkflowType === PRESET_WORKFLOW_TYPE) {
				return false;
			}
			return true;
		},
		filteredWorkflows(): IWorkflowShortResponse[] {
			return this.workflows
				.filter((workflow: IWorkflowShortResponse) => {
					if (this.filterText && !workflow.name.toLowerCase().includes(this.filterText.toLowerCase())) {
						return false;
					}

					// Check workflow type and channel type to filter workflows by tags
					const tags = workflow.tags.map((tag) => tag.name);
					const hasPresetTag = tags.includes(PRESET_WORKFLOW_TYPE);
					const hasAutomationTag = tags.includes(AUTOMATION_WORKFLOW_TYPE);
					const hasCurrentChannelTag = tags.includes(this.currentChannelType);
					const hasAnyChannelTag = CHANNEL_TYPE.find((channel) => tags.includes(channel.tag));

					if (this.currentWorkflowType === DEFAULT_WORKFLOW_TYPE) {
						return !hasPresetTag && !hasAutomationTag && !hasAnyChannelTag;
					} else if (this.currentWorkflowType === PRESET_WORKFLOW_TYPE) {
						return hasPresetTag && !hasAutomationTag && !hasAnyChannelTag;
					} else if (this.currentWorkflowType === AUTOMATION_WORKFLOW_TYPE) {
						return !hasPresetTag && hasAutomationTag && !hasAnyChannelTag;
					} else if (this.currentWorkflowType === CHANNEL_AUTOMATION_WORKFLOW_TYPE) {
						return !hasPresetTag && hasAutomationTag && hasCurrentChannelTag;
					} else {
						return !hasPresetTag && !hasAutomationTag && hasCurrentChannelTag;
					}

					// if (this.filterTagIds.length === 0 || !workflow.tags || workflow.tags.length === 0) {
					// 	return false;
					// }

					// return this.filterTagIds.reduce((accu: boolean, id: string) => {
					// 	// if one of the filtered tagIds is set on the workflow, filtered it
					// 	return accu && !!workflow.tags.find(tag => tag.id === id);
					// }, true);
				});
		},
	},
	async mounted() {
		this.filterText = "";
		this.filterTagIds = [];
		this.updateWorkflowType(this.tabList[0]);
		
		this.isDataLoading = true;
		await this.loadActiveWorkflows();
		await this.loadWorkflows();
		await this.$store.dispatch("tags/fetchAll", { force: true, withUsageCount: false });
		this.isDataLoading = false;

		Vue.nextTick(() => {
			// Make sure that users can directly type in the filter
			(this.$refs.inputFieldFilter as HTMLInputElement).focus();
		});

		window.addEventListener('resize', this.detectWindowWidth);
		this.windowWidth = window.innerWidth;
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.detectWindowWidth);
	},
	methods: {
		getIds(tags: ITag[] | undefined) {
			return (tags || []).map((tag) => tag.id);
		},
		updateTagsFilter(tags: string[]) {
			this.filterTagIds = tags;
		},
		onTagClick(tagId: string) {
			if (tagId !== "count" && !this.filterTagIds.includes(tagId)) {
				this.filterTagIds.push(tagId);
			}
		},
		async openWorkflow(data: IWorkflowShortResponse, column: any, cell: any, e: PointerEvent) { // tslint:disable-line:no-any
			if (column.label !== "Active" && column.label !== "Delete") {

				const currentWorkflowId = this.$store.getters.workflowId;

				if (e.metaKey || e.ctrlKey) {
					const route = this.$router.resolve({ name: VIEWS.WORKFLOW, params: { name: data.id } });
					window.open(route.href, "_blank");

					return;
				}

				if (data.id === currentWorkflowId) {
					this.$showMessage({
						title: this.$locale.baseText("workflowOpen.showMessage.title"),
						message: this.$locale.baseText("workflowOpen.showMessage.message"),
						type: "error",
						duration: 1500,
					});
					// Do nothing if current workflow is the one user chose to open
					return;
				}

				const result = this.$store.getters.getStateIsDirty;
				if (result) {
					const confirmModal = await this.confirmModal(
						this.$locale.baseText("workflowOpen.confirmMessage.message"),
						this.$locale.baseText("workflowOpen.confirmMessage.headline"),
						"warning",
						this.$locale.baseText("workflowOpen.confirmMessage.confirmButtonText"),
						this.$locale.baseText("workflowOpen.confirmMessage.cancelButtonText"),
						true,
					);

					if (confirmModal === MODAL_CONFIRMED) {
						const saved = await this.saveCurrentWorkflow({}, false);
						if (saved) this.$store.dispatch("settings/fetchPromptsData");

						this.$router.push({
							name: VIEWS.WORKFLOW,
							params: { name: data.id },
						});
					} else if (confirmModal === MODAL_CANCEL) {
						this.$store.commit("setStateDirty", false);

						this.$router.push({
							name: VIEWS.WORKFLOW,
							params: { name: data.id },
						});
					} else if (confirmModal === MODAL_CLOSE) {
						return;
					}
				} else {
					this.$router.push({
						name: VIEWS.WORKFLOW,
						params: { name: data.id },
					});
				}
				this.$store.commit("ui/closeAllModals");
			}
		},
		async loadWorkflows() {
			try {
				this.workflows = await this.restApi().getWorkflows();
				this.workflows.forEach((workflowData: IWorkflowShortResponse) => {
					workflowData.createdAt = convertToDisplayDate(workflowData.createdAt as number);
					workflowData.updatedAt = convertToDisplayDate(workflowData.updatedAt as number);
				});
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText("workflowOpen.showError.title"),
				);
			}
		},
		async loadActiveWorkflows() {
			try {
				const activeWorkflows = await this.restApi().getActiveWorkflows();
				this.$store.commit("setActiveWorkflows", activeWorkflows);
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText("workflowOpen.couldNotLoadActiveWorkflows"),
				);
			}
		},
		async deleteWorkflow(workflowId: string, workflowName: string) {
			const deleteConfirmed = await this.confirmMessage(
				this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.message', {
					interpolate: { workflowName: workflowName },
				}),
				this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.headline'),
				'warning',
				this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.confirmButtonText'),
				this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.cancelButtonText'),
			);

			if (deleteConfirmed === false) {
				return;
			}

			try {
				await this.restApi().deleteWorkflow(workflowId);
				this.loadWorkflows();
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('mainSidebar.showError.stopExecution.title'),
				);
				return;
			}
		},
		workflowActiveChanged(data: { id: string, active: boolean }) {
			for (const workflow of this.workflows) {
				if (workflow.id === data.id) {
					workflow.active = data.active;
				}
			}
		},
		onTagsFilterBlur() {
			this.prevFilterTagIds = this.filterTagIds;
		},
		onTagsFilterEsc() {
			// revert last applied tags
			this.filterTagIds = this.prevFilterTagIds;
		},
		createWorkflow() {
			if (this.$router.currentRoute.name === VIEWS.NEW_WORKFLOW) {
				this.$root.$emit("newWorkflow");
			} else {
				const query: Record<string, string> = {};

				for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
					query[key] = value;
				}
				this.$router.push({ name: VIEWS.NEW_WORKFLOW, query });
			}
		},
		updateWorkflowType(type: string) {
			this.$store.commit('imbrace/setCurrentWorkflowType', type);
			if (this.hasChannel.includes(type)) {
				this.updateChannelType(this.channelNameList[0]);
			} else {
				this.updateChannelType(null);
			}
		},
		updateChannelType(type: string | null) {
			this.$store.commit('imbrace/setCurrentChannelType', type);
		},
		detectWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
		tableColumnWidth(colNum: number): number {
			if (this.windowWidth > 1280) {
				return 74 * colNum + 24 * (colNum - 1);
			} else {
				return 70 * colNum + 16 * (colNum - 1);
			}
		},
	},
});
