
import Vue from 'vue';

export default Vue.extend({
	name: 'SlideTransition',
	props: [
		'direction',
	],
	computed: {
		name() {
			if (this.direction && this.direction === 'left') {
				return 'slide-left';
			}
			return 'slide';
		},
	},
});
