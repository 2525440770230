
import { VIEWS } from '@/constants';
import Vue from 'vue';

export default Vue.extend({
	name: 'TemplateList',
	data() {
		return {
			routeHasHistory: false,
		};
	},
	methods: {
		navigateTo() {
			if (this.routeHasHistory) this.$router.go(-1);
			else this.$router.push({ name: VIEWS.TEMPLATES });
		},
	},
	mounted() {
		window.history.state ? this.routeHasHistory = true : this.routeHasHistory = false;
	},
});
