import {
	CORE_NODES_CATEGORY,
	TEMPLATES_NODES_FILTER,
} from '@/constants';
import type {
	ITemplatesNode,
} from '@/Interface';
import type {
	INodeProperties,
	INodeTypeDescription,
	ResourceMapperField,
} from 'n8n-workflow';

/*
	Constants and utility functions mainly used to get information about
	or manipulate node types and nodes.
*/

const CRED_KEYWORDS_TO_FILTER = ['API', 'OAuth1', 'OAuth2'];
const NODE_KEYWORDS_TO_FILTER = ['Trigger'];
const RESOURCE_MAPPER_FIELD_NAME_REGEX = /value\[\"(.+)\"\]/;

export function getAppNameFromCredType(name: string) {
	return name
		.split(' ')
		.filter((word) => !CRED_KEYWORDS_TO_FILTER.includes(word))
		.join(' ');
}

export function getAppNameFromNodeName(name: string) {
	return name
		.split(' ')
		.filter((word) => !NODE_KEYWORDS_TO_FILTER.includes(word))
		.join(' ');
}

export function getTriggerNodeServiceName(nodeType: INodeTypeDescription): string {
	return nodeType.displayName.replace(/ trigger/i, '');
}


export function filterTemplateNodes(nodes: ITemplatesNode[]) {
	const notCoreNodes = nodes.filter((node: ITemplatesNode) => {
		return !(node.categories || []).some((category) => category.name === CORE_NODES_CATEGORY);
	});

	const results = notCoreNodes.length > 0 ? notCoreNodes : nodes;
	return results.filter((elem) => !TEMPLATES_NODES_FILTER.includes(elem.name));
}


export const hasOnlyListMode = (parameter: INodeProperties): boolean => {
	return (
		parameter.modes !== undefined &&
		parameter.modes.length === 1 &&
		parameter.modes[0].name === 'list'
	);
};

export const isNodeFieldMatchingNodeVersion = (
	nodeField: INodeProperties,
	nodeVersion: number | undefined,
) => {
	if (nodeVersion && nodeField.displayOptions?.show?.['@version']) {
		return nodeField.displayOptions.show['@version']?.includes(nodeVersion);
	}
	return true;
};

export const isNodeParameterRequired = (
	nodeType: INodeTypeDescription,
	parameter: INodeProperties,
): boolean => {
	if (!parameter.displayOptions || !parameter.displayOptions.show) {
		return true;
	}
	// If parameter itself contains 'none'?
	// Walk through dependencies and check if all their values are used in displayOptions
	Object.keys(parameter.displayOptions.show).forEach((name) => {
		const relatedField = nodeType.properties.find((prop) => {
			prop.name === name;
		});
		if (relatedField && !isNodeParameterRequired(nodeType, relatedField)) {
			return false;
		} else {
			return true;
		}
	});
	return true;
};

export const parseResourceMapperFieldName = (fullName: string) => {
	const match = fullName.match(RESOURCE_MAPPER_FIELD_NAME_REGEX);
	const fieldName = match ? match.pop() : fullName;

	return fieldName;
};

export const fieldCannotBeDeleted = (
	field: INodeProperties | ResourceMapperField,
	showMatchingColumnsSelector: boolean,
	resourceMapperMode = '',
	matchingFields: string[] = [],
): boolean => {
	const fieldIdentifier = 'id' in field ? field.id : field.name;
	return (
		(resourceMapperMode === 'add' && field.required === true) ||
		isMatchingField(fieldIdentifier, matchingFields, showMatchingColumnsSelector)
	);
};

export const isMatchingField = (
	field: string,
	matchingFields: string[],
	showMatchingColumnsSelector: boolean,
): boolean => {
	const fieldName = parseResourceMapperFieldName(field);
	if (fieldName) {
		return showMatchingColumnsSelector && (matchingFields || []).includes(fieldName);
	}
	return false;
};
