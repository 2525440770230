
import { MessageBoxInputData } from 'element-ui/types/message-box';

import { IExecutionResponse, IWorkflowDataUpdate, IMenuItem, IUser } from '../Interface';

import ExecutionsList from '@/components/ExecutionsList.vue';
import GiftNotificationIcon from './GiftNotificationIcon.vue';
import WorkflowSettings from '@/components/WorkflowSettings.vue';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { titleChange } from '@/components/mixins/titleChange';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { workflowRun } from '@/components/mixins/workflowRun';

import { saveAs } from 'file-saver';

import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';
import MenuItemsIterator from './MenuItemsIterator.vue';
import {
	ABOUT_MODAL_KEY,
	CREDENTIAL_LIST_MODAL_KEY,
	CREDENTIAL_SELECT_MODAL_KEY,
	DUPLICATE_MODAL_KEY,
	MODAL_CANCEL,
	MODAL_CLOSE,
	MODAL_CONFIRMED,
	TAGS_MANAGER_MODAL_KEY,
	VERSIONS_MODAL_KEY,
	WORKFLOW_SETTINGS_MODAL_KEY,
	WORKFLOW_OPEN_MODAL_KEY,
	EXECUTIONS_MODAL_KEY,
	VIEWS,
} from '@/constants';
import { userHelpers } from './mixins/userHelpers';

export default mixins(
	genericHelpers,
	restApi,
	showMessage,
	titleChange,
	workflowHelpers,
	workflowRun,
	userHelpers,
).extend({
	name: 'MainSidebar',
	components: {
		ExecutionsList,
		GiftNotificationIcon,
		WorkflowSettings,
		MenuItemsIterator,
	},
	data() {
		return {
			// @ts-ignore
			basePath: this.$store.getters.getBaseUrl,
			stopExecutionInProgress: false,
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		...mapGetters('versions', ['hasVersionUpdates', 'nextVersions']),
		...mapGetters('users', ['canUserAccessSidebarUserInfo', 'currentUser']),
		...mapGetters('settings', ['isTemplatesEnabled']),
		canUserAccessSettings(): boolean {
			return (
				this.canUserAccessRouteByName(VIEWS.PERSONAL_SETTINGS) ||
				this.canUserAccessRouteByName(VIEWS.USERS_SETTINGS)
			);
		},
		helpMenuItems(): object[] {
			return [
				{
					id: 'docs',
					type: 'link',
					properties: {
						href: 'https://docs.n8n.io',
						title: this.$locale.baseText('mainSidebar.helpMenuItems.documentation'),
						icon: 'book',
						newWindow: true,
					},
				},
				{
					id: 'forum',
					type: 'link',
					properties: {
						href: 'https://community.n8n.io',
						title: this.$locale.baseText('mainSidebar.helpMenuItems.forum'),
						icon: 'users',
						newWindow: true,
					},
				},
				{
					id: 'examples',
					type: 'link',
					properties: {
						href: 'https://docs.n8n.io/courses',
						title: this.$locale.baseText('mainSidebar.helpMenuItems.course'),
						icon: 'graduation-cap',
						newWindow: true,
					},
				},
			];
		},
		exeuctionId(): string | undefined {
			return this.$route.params.id;
		},
		executionFinished(): boolean {
			if (!this.isExecutionPage) {
				// We are not on an exeuction page so return false
				return false;
			}

			const fullExecution = this.$store.getters.getWorkflowExecution;

			if (fullExecution === null) {
				// No exeuction loaded so return also false
				return false;
			}

			if (fullExecution.finished === true) {
				return true;
			}

			return false;
		},
		executionWaitingForWebhook(): boolean {
			return this.$store.getters.executionWaitingForWebhook;
		},
		isExecutionPage(): boolean {
			return this.$route.name === VIEWS.EXECUTION;
		},
		isWorkflowActive(): boolean {
			return this.$store.getters.isActive;
		},
		currentWorkflow(): string {
			return this.$route.params.name;
		},
		workflowExecution(): IExecutionResponse | null {
			return this.$store.getters.getWorkflowExecution;
		},
		workflowName(): string {
			return this.$store.getters.workflowName;
		},
		workflowRunning(): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
		sidebarMenuTopItems(): IMenuItem[] {
			return this.$store.getters.sidebarMenuItems.filter(
				(item: IMenuItem) => item.position === 'top',
			);
		},
		sidebarMenuBottomItems(): IMenuItem[] {
			return this.$store.getters.sidebarMenuItems.filter(
				(item: IMenuItem) => item.position === 'bottom',
			);
		},
		onWorkflowPage(): boolean {
			return this.$route.meta && this.$route.meta.nodeView;
		},
	},
	methods: {
		trackHelpItemClick(itemType: string) {
			this.$telemetry.track('User clicked help resource', {
				type: itemType,
				workflow_id: this.$store.getters.workflowId,
			});
		},
		async onUserActionToggle(action: string) {
			if (action === 'logout') {
				this.onLogout();
			} else {
				this.$router.push({ name: VIEWS.PERSONAL_SETTINGS });
			}
		},
		async onLogout() {
			try {
				await this.$store.dispatch('users/logout');

				const route = this.$router.resolve({ name: VIEWS.SIGNIN });
				window.open(route.href, '_self');
			} catch (e) {
				this.$showError(e, this.$locale.baseText('auth.signout.error'));
			}
		},
		toggleCollapse() {
			this.$store.commit('ui/toggleSidebarMenuCollapse');
		},
		clearExecutionData() {
			this.$store.commit('setWorkflowExecutionData', null);
			this.updateNodesExecutionIssues();
		},
		openTagManager() {
			this.$store.dispatch('ui/openModal', TAGS_MANAGER_MODAL_KEY);
		},
		openUpdatesPanel() {
			this.$store.dispatch('ui/openModal', VERSIONS_MODAL_KEY);
		},
		openWorkflowList() {
			this.$router.push({ name: VIEWS.WORKFLOW_LIST });
		},
		async stopExecution() {
			const executionId = this.$store.getters.activeExecutionId;
			if (executionId === null) {
				return;
			}

			try {
				this.stopExecutionInProgress = true;
				await this.restApi().stopCurrentExecution(executionId);
				this.$showMessage({
					title: this.$locale.baseText('mainSidebar.showMessage.stopExecution.title'),
					type: 'success',
				});
			} catch (error) {
				this.$showError(error, this.$locale.baseText('mainSidebar.showError.stopExecution.title'));
			}
			this.stopExecutionInProgress = false;
		},
		async openWorkflow(workflowId: string) {
			// Change to other workflow
			this.$router.push({
				name: VIEWS.WORKFLOW,
				params: { name: workflowId },
			});

			this.$store.commit('ui/closeAllModals');
		},
		async handleFileImport() {
			const reader = new FileReader();

			reader.onload = (event: ProgressEvent) => {
				const data = (event.target as FileReader).result;

				let worflowData: IWorkflowDataUpdate;
				try {
					worflowData = JSON.parse(data as string);
				} catch (error) {
					this.$showMessage({
						title: this.$locale.baseText('mainSidebar.showMessage.handleFileImport.title'),
						message: this.$locale.baseText('mainSidebar.showMessage.handleFileImport.message'),
						type: 'error',
					});
					return;
				}

				this.$telemetry.track('User imported workflow', {
					source: 'file',
					workflow_id: this.$store.getters.workflowId,
				});
				this.$root.$emit('importWorkflowData', { data: worflowData });
			};

			const input = this.$refs.importFile as HTMLInputElement;
			if (input !== null && input.files !== null && input.files.length !== 0) {
				reader.readAsText(input!.files[0]!);
			}
		},
		async handleSelect(key: string, keyPath: string) {
			if (key === 'workflow-open') {
				this.$store.dispatch('ui/openModal', WORKFLOW_OPEN_MODAL_KEY);
			} else if (key === 'workflow-import-file') {
				(this.$refs.importFile as HTMLInputElement).click();
			} else if (key === 'workflow-import-url') {
				try {
					const promptResponse = (await this.$prompt(
						this.$locale.baseText('mainSidebar.prompt.workflowUrl') + ':',
						this.$locale.baseText('mainSidebar.prompt.importWorkflowFromUrl') + ':',
						{
							confirmButtonText: this.$locale.baseText('mainSidebar.prompt.import'),
							cancelButtonText: this.$locale.baseText('mainSidebar.prompt.cancel'),
							inputErrorMessage: this.$locale.baseText('mainSidebar.prompt.invalidUrl'),
							inputPattern: /^http[s]?:\/\/.*\.json$/i,
						},
					)) as MessageBoxInputData;

					this.$root.$emit('importWorkflowUrl', { url: promptResponse.value });
				} catch (e) {}
			} else if (key === 'workflow-delete') {
				const deleteConfirmed = await this.confirmMessage(
					this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.message', {
						interpolate: { workflowName: this.workflowName },
					}),
					this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.headline'),
					'warning',
					this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.confirmButtonText'),
					this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.cancelButtonText'),
				);

				if (deleteConfirmed === false) {
					return;
				}

				try {
					await this.restApi().deleteWorkflow(this.currentWorkflow);
				} catch (error) {
					this.$showError(
						error,
						this.$locale.baseText('mainSidebar.showError.stopExecution.title'),
					);
					return;
				}
				this.$store.commit('setStateDirty', false);
				// Reset tab title since workflow is deleted.
				this.$titleReset();
				this.$showMessage({
					title: this.$locale.baseText('mainSidebar.showMessage.handleSelect1.title'),
					type: 'success',
				});

				this.$router.push({ name: VIEWS.NEW_WORKFLOW });
			} else if (key === 'workflow-download') {
				const workflowData = await this.getWorkflowDataToSave();

				const { tags, ...data } = workflowData;
				if (data.id && typeof data.id === 'string') {
					data.id = parseInt(data.id, 10);
				}
				const blob = new Blob([JSON.stringify(data, null, 2)], {
					type: 'application/json;charset=utf-8',
				});

				let workflowName = this.$store.getters.workflowName || 'unsaved_workflow';

				workflowName = workflowName.replace(/[^a-z0-9]/gi, '_');

				this.$telemetry.track('User exported workflow', { workflow_id: workflowData.id });

				saveAs(blob, workflowName + '.json');
			} else if (key === 'workflow-save') {
				const saved = await this.saveCurrentWorkflow();
				if (saved) this.$store.dispatch('settings/fetchPromptsData');
			} else if (key === 'workflow-duplicate') {
				this.$store.dispatch('ui/openModal', DUPLICATE_MODAL_KEY);
			} else if (key === 'help-about') {
				this.trackHelpItemClick('about');
				this.$store.dispatch('ui/openModal', ABOUT_MODAL_KEY);
			} else if (key === 'workflow-settings') {
				this.$store.dispatch('ui/openModal', WORKFLOW_SETTINGS_MODAL_KEY);
			} else if (key === 'user') {
				this.$router.push({ name: VIEWS.PERSONAL_SETTINGS });
			} else if (key === 'workflow-new') {
				const result = this.$store.getters.getStateIsDirty;
				if (result) {
					const confirmModal = await this.confirmModal(
						this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.message'),
						this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.headline'),
						'warning',
						this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.confirmButtonText'),
						this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.cancelButtonText'),
						true,
					);

					if (confirmModal === MODAL_CONFIRMED) {
						const saved = await this.saveCurrentWorkflow({}, false);
						if (saved) this.$store.dispatch('settings/fetchPromptsData');

						if (this.$router.currentRoute.name === VIEWS.NEW_WORKFLOW) {
							this.$root.$emit('newWorkflow');
						} else {
							this.$router.push({ name: VIEWS.NEW_WORKFLOW });
						}

						this.$showMessage({
							title: this.$locale.baseText('mainSidebar.showMessage.handleSelect2.title'),
							type: 'success',
						});
					} else if (confirmModal === MODAL_CANCEL) {
						this.$store.commit('setStateDirty', false);
						if (this.$router.currentRoute.name === VIEWS.NEW_WORKFLOW) {
							this.$root.$emit('newWorkflow');
						} else {
							this.$router.push({ name: VIEWS.NEW_WORKFLOW });
						}

						this.$showMessage({
							title: this.$locale.baseText('mainSidebar.showMessage.handleSelect2.title'),
							type: 'success',
						});
					} else if (confirmModal === MODAL_CLOSE) {
						return;
					}
				} else {
					if (this.$router.currentRoute.name !== VIEWS.NEW_WORKFLOW) {
						this.$router.push({ name: VIEWS.NEW_WORKFLOW });
					}

					this.$showMessage({
						title: this.$locale.baseText('mainSidebar.showMessage.handleSelect3.title'),
						type: 'success',
					});
				}
				this.$titleReset();
			} else if (key === 'templates' || key === 'template-new') {
				if (this.$router.currentRoute.name !== VIEWS.TEMPLATES) {
					this.$router.push({ name: VIEWS.TEMPLATES });
				}
			} else if (key === 'credentials-open') {
				this.$store.dispatch('ui/openModal', CREDENTIAL_LIST_MODAL_KEY);
			} else if (key === 'credentials-new') {
				this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
			} else if (key === 'execution-open-workflow') {
				if (this.workflowExecution !== null) {
					this.openWorkflow(this.workflowExecution.workflowId as string);
				}
			} else if (key === 'executions') {
				this.$store.dispatch('ui/openModal', EXECUTIONS_MODAL_KEY);
			} else if (key === 'settings') {
				if ((this.currentUser as IUser).isDefaultUser) {
					this.$router.push('/settings/users');
				} else {
					this.$router.push('/settings/personal');
				}
			}
		},
	},
});
