
import Vue from 'vue';

export default Vue.extend({
	computed: {
		basePath(): string {
			return this.$store.getters.getBaseUrl;
		},
	},
});
