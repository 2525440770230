
import Vue from 'vue';

export default Vue.extend({
	name: 'TextEdit',
	props: [
		'dialogVisible',
		'parameter',
		'path',
		'value',
	],
	data () {
		return {
			tempValue: '', // el-input does not seem to work without v-model so add one
		};
	},
	methods: {
		valueChanged (value: string) {
			this.$emit('valueChanged', value);
		},

		onKeyDownEsc () {
			// Resetting input value when closing the dialog, required when closing it using the `Esc` key
			this.tempValue = this.value;

			this.closeDialog();
		},

		closeDialog () {
			// Handle the close externally as the visible parameter is an external prop
			// and is so not allowed to be changed here.
			this.$emit('closeDialog');
			return false;
		},
	},
	mounted () {
		this.tempValue = this.value as string;
	},
	watch: {
		dialogVisible () {
			if (this.dialogVisible === true) {
				Vue.nextTick(() => {
					(this.$refs.inputField as HTMLInputElement).focus();
				});
			}
		},
		value () {
			this.tempValue = this.value as string;
		},
	},
});
