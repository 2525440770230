

import Vue from 'vue';

import { externalHooks } from '@/components/mixins/externalHooks';

import mixins from 'vue-typed-mixins';
import ItemIterator from './ItemIterator.vue';
import NoResults from './NoResults.vue';
import SearchBar from './SearchBar.vue';
import SubcategoryPanel from './SubcategoryPanel.vue';
import { INodeCreateElement, INodeItemProps, ISubcategoryItemProps } from '@/Interface';
import { ACTIONS_CATEGORY, ALL_NODE_FILTER, AUTOMATION_WORKFLOW_TYPE, BOARD_AUTOMATION_WORKFLOW_TYPE, FUNCTIONS_CATEGORY, INTEGRATIONS_CATEGORY, PRESET_WORKFLOW_TYPE, REGULAR_NODE_FILTER, TRIGGERS_CATEGORY, TRIGGER_NODE_FILTER } from '@/constants';
import SlideTransition from '../transitions/SlideTransition.vue';
import { matchesNodeType, matchesSelectType } from './helpers';

export default mixins(externalHooks).extend({
	name: 'NodeCreateList',
	components: {
		ItemIterator,
		NoResults,
		SubcategoryPanel,
		SlideTransition,
		SearchBar,
	},
	props: ['categorizedItems', 'categoriesWithNodes', 'searchItems'],
	data() {
		return {
			activeCategory: [] as string[],
			activeSubcategory: null as INodeCreateElement | null,
			activeIndex: 0,
			activeSubcategoryIndex: 0,
			nodeFilter: '',
			selectedType: ALL_NODE_FILTER,
			searchEventBus: new Vue(),
			REGULAR_NODE_FILTER,
			TRIGGER_NODE_FILTER,
			ALL_NODE_FILTER,
		};
	},
	computed: {
		currentWorkflowType(): string {
			return this.$store.getters['imbrace/currentWorkflowType'];
		},
		searchFilter(): string {
			return this.nodeFilter.toLowerCase().trim();
		},
		filteredNodeTypes(): INodeCreateElement[] {
			const nodeTypes: INodeCreateElement[] = this.searchItems;
			const filter = this.searchFilter;
			const returnData = nodeTypes.filter((el: INodeCreateElement) => {
				return filter && matchesSelectType(el, this.selectedType) && matchesNodeType(el, filter);
			});

			setTimeout(() => {
				this.$externalHooks().run('nodeCreateList.filteredNodeTypesComputed', {
					nodeFilter: this.nodeFilter,
					result: returnData,
					selectedType: this.selectedType,
				});
			}, 0);

			return returnData;
		},

		categorized() {
			return this.categorizedItems && this.categorizedItems
				.reduce((accu: INodeCreateElement[], el: INodeCreateElement) => {
					if (
						el.type !== 'category' &&
						!this.activeCategory.includes(el.category)
					) {
						return accu;
					}

					if (!matchesSelectType(el, this.selectedType)) {
						return accu;
					}

					if (el.type === 'category') {
						accu.push({
							...el,
							properties: {
								expanded: this.activeCategory.includes(el.category),
							},
						} as INodeCreateElement);
						return accu;
					}

					accu.push(el);
					return accu;
				}, []);
		},

		subcategorizedNodes() {
			const activeSubcategory = this.activeSubcategory as INodeCreateElement;
			const category = activeSubcategory.category;
			const subcategory = (activeSubcategory.properties as ISubcategoryItemProps).subcategory;

			return activeSubcategory && this.categoriesWithNodes[category][subcategory]
				.nodes.filter((el: INodeCreateElement) => matchesSelectType(el, this.selectedType));
		},
	},
	watch: {
		nodeFilter(newValue, oldValue) {
			// Reset the index whenver the filter-value changes
			this.activeIndex = 0;
			this.$externalHooks().run('nodeCreateList.nodeFilterChanged', {
				oldValue,
				newValue,
				selectedType: this.selectedType,
				filteredNodes: this.filteredNodeTypes,
			});
			this.$telemetry.trackNodesPanel('nodeCreateList.nodeFilterChanged', {
				oldValue,
				newValue,
				selectedType: this.selectedType,
				filteredNodes: this.filteredNodeTypes,
				workflow_id: this.$store.getters.workflowId,
			});
		},
		selectedType(newValue, oldValue) {
			this.$externalHooks().run('nodeCreateList.selectedTypeChanged', {
				oldValue,
				newValue,
			});
			this.$telemetry.trackNodesPanel('nodeCreateList.selectedTypeChanged', {
				old_filter: oldValue,
				new_filter: newValue,
				workflow_id: this.$store.getters.workflowId,
			});
		},
	},
	methods: {
		nodeFilterKeyDown(e: KeyboardEvent) {
			if (!['Escape', 'Tab'].includes(e.key)) {
				// We only want to propagate 'Escape' as it closes the node-creator and
				// 'Tab' which toggles it
				e.stopPropagation();
			}

			if (this.activeSubcategory) {
				const activeList = this.subcategorizedNodes;
				const activeNodeType = activeList[this.activeSubcategoryIndex];

				if (e.key === 'ArrowDown' && this.activeSubcategory) {
					this.activeSubcategoryIndex++;
					this.activeSubcategoryIndex = Math.min(
						this.activeSubcategoryIndex,
						activeList.length - 1,
					);
				}
				else if (e.key === 'ArrowUp' && this.activeSubcategory) {
					this.activeSubcategoryIndex--;
					this.activeSubcategoryIndex = Math.max(this.activeSubcategoryIndex, 0);
				}
				else if (e.key === 'Enter') {
					this.selected(activeNodeType);
				}
				else if (e.key === 'ArrowLeft') {
					this.onSubcategoryClose();
				}

				return;
			}

			let activeList;
			if (this.searchFilter.length > 0) {
				activeList = this.filteredNodeTypes;
			} else {
				activeList = this.categorized;
			}
			const activeNodeType = activeList[this.activeIndex];

			if (e.key === 'ArrowDown') {
				this.activeIndex++;
				// Make sure that we stop at the last nodeType
				this.activeIndex = Math.min(
					this.activeIndex,
					activeList.length - 1,
				);
			} else if (e.key === 'ArrowUp') {
				this.activeIndex--;
				// Make sure that we do not get before the first nodeType
				this.activeIndex = Math.max(this.activeIndex, 0);
			} else if (e.key === 'Enter' && activeNodeType) {
				this.selected(activeNodeType);
			} else if (e.key === 'ArrowRight' && activeNodeType && activeNodeType.type === 'subcategory') {
				this.selected(activeNodeType);
			} else if (e.key === 'ArrowRight' && activeNodeType && activeNodeType.type === 'category' && !activeNodeType.properties.expanded) {
				this.selected(activeNodeType);
			} else if (e.key === 'ArrowLeft' && activeNodeType && activeNodeType.type === 'category' && activeNodeType.properties.expanded) {
				this.selected(activeNodeType);
			}
		},
		selected(element: INodeCreateElement) {
			if (element.type === 'node') {
				this.$emit('nodeTypeSelected', (element.properties as INodeItemProps).nodeType.name);
				this.$store.commit('imbrace/addNewNode', true);

				// When we select a preset connector in creator,
				// auto set preset workflow id to that connector
				const properties: any = element.properties;
				if (properties.nodeType.presetId) {
					const presetData = {
						presetId: properties.nodeType.presetId,
						presetName: properties.nodeType.displayName,
					};
					this.$store.commit('imbrace/setPresetData', presetData);
					this.$store.commit('imbrace/addNewPreset', true);
				}
			} else if (element.type === 'category') {
				this.onCategorySelected(element.category);
			} else if (element.type === 'subcategory') {
				this.onSubcategorySelected(element);
			}
		},
		onCategorySelected(category: string) {
			if (this.activeCategory.includes(category)) {
				this.activeCategory = this.activeCategory.filter(
					(active: string) => active !== category,
				);
			} else {
				this.activeCategory = [...this.activeCategory, category];
				this.$telemetry.trackNodesPanel('nodeCreateList.onCategoryExpanded', { category_name: category, workflow_id: this.$store.getters.workflowId });
			}

			this.activeIndex = this.categorized.findIndex(
				(el: INodeCreateElement) => el.category === category,
			);
		},
		onSubcategorySelected(selected: INodeCreateElement) {
			this.activeSubcategoryIndex = 0;
			this.activeSubcategory = selected;
			this.$telemetry.trackNodesPanel('nodeCreateList.onSubcategorySelected', { selected, workflow_id: this.$store.getters.workflowId });
		},
		onSubcategoryClose() {
			this.activeSubcategory = null;
			this.activeSubcategoryIndex = 0;
			this.nodeFilter = '';
		},
		onClickInside() {
			this.searchEventBus.$emit('focus');
		},
	},
	async mounted() {
		this.$nextTick(() => {
			// initial opening effect
			if (this.currentWorkflowType === AUTOMATION_WORKFLOW_TYPE) {
				this.activeCategory = [TRIGGERS_CATEGORY];
				this.activeIndex = 1;
			} else if (this.currentWorkflowType === BOARD_AUTOMATION_WORKFLOW_TYPE) {
				this.activeCategory = [FUNCTIONS_CATEGORY];
				this.activeIndex = 2;
			} else if (this.currentWorkflowType === PRESET_WORKFLOW_TYPE) {
				this.activeCategory = [INTEGRATIONS_CATEGORY];
				this.activeIndex = 1;
			} else {
				this.activeCategory = [ACTIONS_CATEGORY];
				this.activeIndex = 0;
			}
		});
		this.$externalHooks().run('nodeCreateList.mounted');
	},
	async destroyed() {
		this.$externalHooks().run('nodeCreateList.destroyed');
		this.$telemetry.trackNodesPanel('nodeCreateList.destroyed', { workflow_id: this.$store.getters.workflowId });
	},
});
