var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.container},[_c('div',{class:{
			[_vm.$style.inputContainer]: true,
			[_vm.$style.disabled]: _vm.disabled,
		}},[_c('input',{ref:"input",attrs:{"type":"text","placeholder":_vm.$locale.baseText('nodeCreator.searchBar.searchNodes'),"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.handleInput}}),_c('div',{class:_vm.$style.extra},[(_vm.value.length > 0)?_c('div',{on:{"click":_vm.clear}},[_c('span',{class:{
						[_vm.$style.clear]: true,
						'el-icon-close': true,
						clickable: true,
					}})]):_c('div',{class:{ active: _vm.value.length > 0 }},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }