
import AuthView from './AuthView.vue';
import { showMessage } from '@/components/mixins/showMessage';

import mixins from 'vue-typed-mixins';
import { IFormBoxConfig } from '@/Interface';
import { mapGetters } from 'vuex';

export default mixins(
	showMessage,
).extend({
	name: 'ForgotMyPasswordView',
	components: {
		AuthView,
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapGetters('settings', ['isSmtpSetup']),
		formConfig(): IFormBoxConfig {
			const EMAIL_INPUTS: IFormBoxConfig['inputs'] = [
				{
					name: 'email',
					properties: {
						label: this.$locale.baseText('auth.email'),
						type: 'email',
						required: true,
						validationRules: [{name: 'VALID_EMAIL'}],
						autocomplete: 'email',
						capitalize: true,
					},
				},
			];

			const NO_SMTP_INPUTS: IFormBoxConfig['inputs'] = [
				{
					name: 'no-smtp-warning',
					properties: {
						label: this.$locale.baseText('forgotPassword.noSMTPToSendEmailWarning'),
						type: 'info',
					},
				},
			];

			const DEFAULT_FORM_CONFIG = {
				title: this.$locale.baseText('forgotPassword.recoverPassword'),
				redirectText: this.$locale.baseText('forgotPassword.returnToSignIn'),
				redirectLink: '/signin',
			};

			if (this.isSmtpSetup) {
				return {
					...DEFAULT_FORM_CONFIG,
					buttonText: this.$locale.baseText('forgotPassword.getRecoveryLink'),
					inputs: EMAIL_INPUTS,
				};
			}
			return {
				...DEFAULT_FORM_CONFIG,
				inputs: NO_SMTP_INPUTS,
			};
		},
	},
	methods: {
		async onSubmit(values: { email: string }) {
			try {
				this.loading = true;
				await this.$store.dispatch('users/sendForgotPasswordEmail', values);

				this.$showMessage({
					type: 'success',
					title: this.$locale.baseText('forgotPassword.recoveryEmailSent'),
					message: this.$locale.baseText(
						'forgotPassword.emailSentIfExists',
						{ interpolate: { email: values.email }},
					),
				});
			} catch (error) {
				this.$showMessage({
					type: 'error',
					title: this.$locale.baseText('forgotPassword.sendingEmailError'),
					message: this.$locale.baseText('forgotPassword.smtpErrorContactAdministrator'),
				});
			}
			this.loading = false;
		},
	},
});
