

import Vue from 'vue';
import mixins from "vue-typed-mixins";

import { IWorkflowShortResponse } from "@/Interface";

import { ICategoriesWithNodes, INodeCreateElement } from '@/Interface';
import { INodeTypeDescription } from 'n8n-workflow';
import SlideTransition from '../transitions/SlideTransition.vue';
import { EXECUTE_WORKFLOW_NODE_TYPE, INTERNAL_USE_CATEGORY, PRESET_CATEGORY, PRESET_WORKFLOW_TYPE } from '@/constants';
import { restApi } from "@/components/mixins/restApi";
import MainPanel from './MainPanel.vue';
import { getCategoriesWithNodes, getCategorizedList } from './helpers';
import { mapGetters } from 'vuex';

export default mixins(restApi).extend({
	name: 'NodeCreator',
	components: {
		MainPanel,
		SlideTransition,
	},
	props: [
		'active',
	],
	data() {
		return {
			allNodeTypes: [],
			presetWorkflows: [] as IWorkflowShortResponse[],
		};
	},
	computed: {
		...mapGetters('users', ['personalizedNodeTypes']),
		...mapGetters('imbrace', ['currentChannelType', 'availableNodeTypes', 'organizationID']),
		nodeTypes(): INodeTypeDescription[] {
			return this.$store.getters.allNodeTypes;
		},
		showNodeTypes(): INodeTypeDescription[] {
			return this.availableNodeTypes.filter((nodeType: INodeTypeDescription) => {
				// "Internal Use" category can only appear in the list if the orgnization id is "org_imbrace"
				if (this.organizationID !== 'org_imbrace') {
					return !nodeType.codex?.categories?.includes(INTERNAL_USE_CATEGORY);
				}
				return true;
			});
		},
		presetNodeList(): any {
			const executeWorkflowNode = this.nodeTypes.find((nodeType: INodeTypeDescription) => nodeType.name === EXECUTE_WORKFLOW_NODE_TYPE);
			return this.presetWorkflows.map((presetWorkflow) => ({
				...executeWorkflowNode,
				codex: {categories: [PRESET_CATEGORY]},
				defaults: {
					color: '#ff6d5a',
					name: presetWorkflow.name,
				},
				description: '',
				displayName: presetWorkflow.name,
				presetId: presetWorkflow.id,
			}));
		},
		categoriesWithNodes(): ICategoriesWithNodes {
			const totalNodeType = [...this.showNodeTypes, ...this.presetNodeList];
			return getCategoriesWithNodes(totalNodeType, this.personalizedNodeTypes as string[]);
		},
		categorizedItems(): INodeCreateElement[] {
			return getCategorizedList(this.categoriesWithNodes, this.currentChannelType);
		},
		searchItems(): INodeCreateElement[] {
			const sorted = [...this.showNodeTypes, ...this.presetNodeList];
			sorted.sort((a, b) => {
				const textA = a.displayName.toLowerCase();
				const textB = b.displayName.toLowerCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});

			return sorted.map((nodeType) => ({
				type: 'node',
				category: '',
				key: nodeType.presetId ? `${nodeType.name}_${nodeType.presetId}` : `${nodeType.name}`,
				properties: {
					nodeType,
					subcategory: '',
				},
				includedByTrigger: nodeType.group.includes('trigger'),
				includedByRegular: !nodeType.group.includes('trigger'),
			}));
		},
	},
	methods: {
		onClickOutside (e: Event) {
			if (e.type === 'click') {
				this.$emit('closeNodeCreator');
			}
		},
		nodeTypeSelected (nodeTypeName: string) {
			this.$emit('nodeTypeSelected', nodeTypeName);
		},
		onDragOver(event: DragEvent) {
			event.preventDefault();
		},
		onDrop(event: DragEvent) {
			if (!event.dataTransfer) {
				return;
			}

			const nodeTypeName = event.dataTransfer.getData('nodeTypeName');
			const presetId = event.dataTransfer.getData('presetId');
			const presetName = event.dataTransfer.getData('presetName');
			const nodeCreatorBoundingRect = (this.$refs.nodeCreator as Element).getBoundingClientRect();

			// Abort drag end event propagation if dropped inside nodes panel
			if (nodeTypeName && event.pageX >= nodeCreatorBoundingRect.x && event.pageY >= nodeCreatorBoundingRect.y) {
				event.stopPropagation();
			}

			// When user exactly drop a new preset connector from creator,
			// auto set preset workflow id to that connector
			if (!!presetId && !!presetName) {
				this.$store.commit('imbrace/setPresetData', {presetId, presetName});
				this.$store.commit('imbrace/addNewPreset', true);
			}
		},
	},
	watch: {
		nodeTypes(newList) {
			if (newList.length !== this.allNodeTypes.length) {
				this.allNodeTypes = newList;
			}
		},
	},
	async mounted() {
		const workflows = await this.restApi().getWorkflows();
		this.presetWorkflows = workflows.filter((workflow) => {
			return workflow.tags && workflow.tags.findIndex((tag) => tag.name === PRESET_WORKFLOW_TYPE) !== -1;
		});
		this.$store.commit('imbrace/setPresetWorkflows', this.presetWorkflows);
	},
});
