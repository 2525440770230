
import Vue from 'vue';

import SettingsSidebar from '../components/SettingsSidebar.vue';

export default Vue.extend({
	name: 'SettingsView',
	components: {
		SettingsSidebar,
	},
});
