
import { INodeParameters, INodeProperties, NodeParameterValue } from 'n8n-workflow';

import { INodeUi, IUpdateInformation } from '@/Interface';

import MultipleParameter from '@/components/MultipleParameter.vue';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ParameterInputFull from '@/components/ParameterInputFull.vue';
import ResourceMapper from './ResourceMapper/ResourceMapper.vue';

import { get, set } from 'lodash-es';

import mixins from 'vue-typed-mixins';
import { WEBHOOK_NODE_TYPE } from '@/constants';

export default mixins(genericHelpers, workflowHelpers).extend({
	name: 'ParameterInputList',
	components: {
		MultipleParameter,
		ParameterInputFull,
		ResourceMapper,
	},
	props: [
		'nodeValues', // INodeParameters
		'parameters', // INodeProperties
		'path', // string
		'hideDelete', // boolean
		'indent',
		'parametersReadOnly', // boolean
		'dynamicMappingData', // object
	],
	computed: {
		filteredParameters(): INodeProperties[] {
			const displayed = this.parameters.filter((parameter: INodeProperties) =>
				this.displayNodeParameter(parameter),
			);
			return displayed;
		},
		filteredParameterNames(): string[] {
			return this.filteredParameters.map((parameter) => parameter.name);
		},
		node(): INodeUi {
			return this.$store.getters.activeNode;
		},
		currentChannelType() {
			return this.$store.getters['imbrace/currentChannelType'];
		},
	},
	methods: {
		onNodeExecute() {
			this.$emit('execute');
		},
		multipleValues(parameter: INodeProperties): boolean {
			if (this.getArgument('multipleValues', parameter) === true) {
				return true;
			}
			return false;
		},
		getArgument(
			argumentName: string,
			parameter: INodeProperties,
		): string | string[] | number | boolean | undefined {
			if (parameter.typeOptions === undefined) {
				return undefined;
			}

			if (parameter.typeOptions[argumentName] === undefined) {
				return undefined;
			}

			return parameter.typeOptions[argumentName];
		},
		getPath(parameterName: string): string {
			return (this.path ? `${this.path}.` : '') + parameterName;
		},
		deleteOption(optionName: string): void {
			const parameterData = {
				name: this.getPath(optionName),
				value: undefined,
			};

			// TODO: If there is only one option it should delete the whole one

			this.$emit('valueChanged', parameterData);
		},
		mustHideDuringCustomApiCall(parameter: INodeProperties, nodeValues: INodeParameters): boolean {
			if (parameter && parameter.displayOptions && parameter.displayOptions.hide) return true;
			const MUST_REMAIN_VISIBLE = [
				'authentication',
				'resource',
				'operation',
				...Object.keys(nodeValues),
			];
			return !MUST_REMAIN_VISIBLE.includes(parameter.name);
		},
		displayNodeParameter(parameter: INodeProperties): boolean {
			// re-trigger button should be only shown when it is starter
			if (
				!this.currentChannelType &&
				this.node.type === WEBHOOK_NODE_TYPE &&
				parameter.name === 'retrigger'
			) {
				return false;
			}

			if (parameter.type === 'hidden') {
				return false;
			}

			if (
				this.isCustomApiCallSelected(this.nodeValues) &&
				this.mustHideDuringCustomApiCall(parameter, this.nodeValues)
			) {
				return false;
			}

			if (parameter.displayOptions === undefined) {
				// If it is not defined no need to do a proper check
				return true;
			}

			const nodeValues: INodeParameters = {};
			let rawValues = this.nodeValues;
			if (this.path) {
				rawValues = get(this.nodeValues, this.path);
			}

			// Resolve expressions
			const resolveKeys = Object.keys(rawValues);
			let key: string;
			let i = 0;
			let parameterGotResolved = false;
			do {
				key = resolveKeys.shift() as string;
				if (typeof rawValues[key] === 'string' && rawValues[key].charAt(0) === '=') {
					// Contains an expression that
					if (
						rawValues[key].includes('$parameter') &&
						resolveKeys.some((parameterName) => rawValues[key].includes(parameterName))
					) {
						// Contains probably an expression of a missing parameter so skip
						resolveKeys.push(key);
						continue;
					} else {
						// Contains probably no expression with a missing parameter so resolve
						try {
							nodeValues[key] = this.resolveExpression(
								rawValues[key],
								nodeValues,
							) as NodeParameterValue;
						} catch (e) {
							// If expression is invalid ignore
							nodeValues[key] = '';
						}
						parameterGotResolved = true;
					}
				} else {
					// Does not contain an expression, add directly
					nodeValues[key] = rawValues[key];
				}
				// TODO: Think about how to calculate this best
				if (i++ > 50) {
					// Make sure we do not get caught
					break;
				}
			} while (resolveKeys.length !== 0);

			if (parameterGotResolved === true) {
				if (this.path) {
					rawValues = JSON.parse(JSON.stringify(this.nodeValues));
					set(rawValues, this.path, nodeValues);
					return this.displayParameter(rawValues, parameter, this.path, this.node);
				} else {
					return this.displayParameter(nodeValues, parameter, '', this.node);
				}
			}

			return this.displayParameter(this.nodeValues, parameter, this.path, this.node);
		},

		valueChanged(parameterData: IUpdateInformation): void {
			this.$emit('valueChanged', parameterData);
		},
		getDependsOnParameterValue(parameter: INodeProperties) {
			// if parameter.type is 'dynamic', use 'dynamicDependsOn' to find the related parameter value as 'dynamicDependsOnParameterValue'(which is field id)

			if ((parameter.type as string) !== 'dynamic') return null;

			const dynamicDependsOn = parameter?.typeOptions?.dynamicConfigs?.dynamicDependsOn;
			if (!dynamicDependsOn) return null;

			const dynamicDependsOnParameterValue = this.getParameterValue(this.nodeValues, dynamicDependsOn, this.path);
			if (!dynamicDependsOnParameterValue) return null;

			return dynamicDependsOnParameterValue;
		},

		getDependentParametersValues(parameter: INodeProperties): string | null {
			const loadOptionsDependsOn = this.getArgument('loadOptionsDependsOn', parameter) as
				| string[]
				| undefined;

			if (loadOptionsDependsOn === undefined) {
				return null;
			}

			// Get the resolved parameter values of the current node
			const currentNodeParameters = this.$store.getters.activeNode?.parameters;
			try {
				const resolvedNodeParameters = this.resolveParameter(currentNodeParameters);

				const returnValues: string[] = [];
				for (const parameterPath of loadOptionsDependsOn) {
					returnValues.push(get(resolvedNodeParameters, parameterPath) as string);
				}

				return returnValues.join('|');
			} catch (error) {
				return null;
			}
		},
	},

	watch: {
		filteredParameterNames(newValue, oldValue) {
			if (newValue === undefined) {
				return;
			}
			// After a parameter does not get displayed anymore make sure that its value gets removed
			// Is only needed for the edge-case when a parameter gets displayed depending on another field
			// which contains an expression.
			for (const parameter of oldValue) {
				if (!newValue.includes(parameter)) {
					const parameterData = {
						name: `${this.path}.${parameter}`,
						node: this.$store.getters.activeNode.name,
						value: undefined,
					};
					this.$emit('valueChanged', parameterData);
				}
			}
		},
	},
	beforeCreate: function () {
		// tslint:disable-line
		// Because we have a circular dependency on CollectionParameter import it here
		// to not break Vue.
		this.$options!.components!.FixedCollectionParameter =
			require('./FixedCollectionParameter.vue').default;
		this.$options!.components!.CollectionParameter = require('./CollectionParameter.vue').default;
	},
});
