
//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import {
	GenericValue,
	IBinaryData,
	IBinaryKeyData,
	IDataObject,
	INodeExecutionData,
	INodeTypeDescription,
	IRunData,
	IRunExecutionData,
	ITaskData,
} from 'n8n-workflow';

import {
	IBinaryDisplayData,
	IExecutionResponse,
	INodeUi,
	IRunDataDisplayMode,
	ITab,
	ITableData,
} from '@/Interface';

import {
	MAX_DISPLAY_DATA_SIZE,
	MAX_DISPLAY_ITEMS_AUTO_ALL,
} from '@/constants';

import BinaryDataDisplay from '@/components/BinaryDataDisplay.vue';
import WarningTooltip from '@/components/WarningTooltip.vue';
import NodeErrorView from '@/components/Error/NodeErrorView.vue';

import { copyPaste } from '@/components/mixins/copyPaste';
import { externalHooks } from "@/components/mixins/externalHooks";
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import mixins from 'vue-typed-mixins';

import { saveAs } from 'file-saver';

// A path that does not exist so that nothing is selected by default
const deselectedPlaceholder = '_!^&*';

export default mixins(
	copyPaste,
	externalHooks,
	genericHelpers,
	nodeHelpers,
)
	.extend({
		name: 'NDVRunData',
		components: {
			BinaryDataDisplay,
			NodeErrorView,
			VueJsonPretty,
			WarningTooltip,
		},
		props: {
			nodeUi: {
			}, // INodeUi | null
			runIndex: {
				type: Number,
			},
			linkedRuns: {
				type: Boolean,
			},
			canLinkRuns: {
				type: Boolean,
			},
			tooMuchDataTitle: {
				type: String,
			},
			noDataInBranchMessage: {
				type: String,
			},
			isExecuting: {
				type: Boolean,
			},
			executingMessage: {
				type: String,
			},
			sessionId: {
				type: String,
			},
			paneType: {
				type: String,
			},
			overrideOutputs: {
				type: Array,
			},
		},
		data () {
			return {
				binaryDataPreviewActive: false,
				dataSize: 0,
				deselectedPlaceholder,
				state: {
					value: '' as object | number | string,
					path: deselectedPlaceholder,
				},
				showData: false,
				outputIndex: 0,
				binaryDataDisplayVisible: false,
				binaryDataDisplayData: null as IBinaryDisplayData | null,

				MAX_DISPLAY_DATA_SIZE,
				MAX_DISPLAY_ITEMS_AUTO_ALL,
				currentPage: 1,
				pageSize: 10,
				pageSizes: [10, 25, 50, 100],
			};
		},
		mounted() {
			this.init();
		},
		computed: {
			activeNode(): INodeUi {
				return this.$store.getters.activeNode;
			},
			displayMode(): IRunDataDisplayMode {
				return this.$store.getters['ui/getPanelDisplayMode'](this.paneType);
			},
			node(): INodeUi | null {
				return (this.nodeUi as INodeUi | null) || null;
			},
			nodeType (): INodeTypeDescription | null {
				if (this.node) {
					return this.$store.getters.nodeType(this.node.type, this.node.typeVersion);
				}
				return null;
			},
			buttons(): Array<{label: string, value: string}> {
				const defaults = [
					{ label: this.$locale.baseText('runData.table'), value: 'table'},
					{ label: this.$locale.baseText('runData.json'), value: 'json'},
				];
				if (this.binaryData.length) {
					return [ ...defaults,
						{ label: this.$locale.baseText('runData.binary'), value: 'binary'},
					];
				}

				return defaults;
			},
			hasNodeRun(): boolean {
				return Boolean(!this.isExecuting && this.node && this.workflowRunData && this.workflowRunData.hasOwnProperty(this.node.name));
			},
			hasRunError(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData[this.node.name] && this.workflowRunData[this.node.name][this.runIndex] && this.workflowRunData[this.node.name][this.runIndex].error);
			},
			workflowExecution (): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowRunData (): IRunData | null {
				if (this.workflowExecution === null) {
					return null;
				}
				const executionData: IRunExecutionData = this.workflowExecution.data;
				if (executionData && executionData.resultData) {
					return executionData.resultData.runData;
				}
				return null;
			},
			dataCount (): number {
				return this.getDataCount(this.runIndex, this.currentOutputIndex);
			},
			dataSizeInMB(): string {
				return (this.dataSize / 1024 / 1000).toLocaleString();
			},
			maxOutputIndex (): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length < this.runIndex) {
					return 0;
				}

				if (runData[this.node.name][this.runIndex]) {
					const taskData = runData[this.node.name][this.runIndex].data;
					if (taskData && taskData.main) {
						return taskData.main.length - 1;
					}
				}

				return 0;
			},
			maxRunIndex (): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length) {
					return runData[this.node.name].length - 1;
				}

				return 0;
			},
			inputData (): INodeExecutionData[] {
				let inputData = this.getNodeInputData(this.node, this.runIndex, this.currentOutputIndex);
				if (inputData.length === 0 || !Array.isArray(inputData)) {
					return [];
				}

				const offset = this.pageSize * (this.currentPage - 1);
				inputData = inputData.slice(offset, offset + this.pageSize);

				return inputData;
			},
			jsonData (): IDataObject[] {
				return this.convertToJson(this.inputData);
			},
			tableData (): ITableData | undefined {
				return this.convertToTable(this.inputData);
			},
			binaryData (): IBinaryKeyData[] {
				if (!this.node) {
					return [];
				}

				return this.getBinaryData(this.workflowRunData, this.node.name, this.runIndex, this.currentOutputIndex);
			},
			currentOutputIndex(): number {
				if (this.overrideOutputs && this.overrideOutputs.length && !this.overrideOutputs.includes(this.outputIndex)) {
					return this.overrideOutputs[0] as number;
				}

				return this.outputIndex;
			},
			branches (): ITab[] {
				function capitalize(name: string) {
					return name.charAt(0).toLocaleUpperCase() + name.slice(1);
				}
				const branches: ITab[] = [];
				for (let i = 0; i <= this.maxOutputIndex; i++) {
					if (this.overrideOutputs && !this.overrideOutputs.includes(i)) {
						continue;
					}
					const itemsCount = this.getDataCount(this.runIndex, i);
					const items = this.$locale.baseText('ndv.output.items', {adjustToNumber: itemsCount});
					let outputName = this.getOutputName(i);
					if (`${outputName}` === `${i}`) {
						outputName = `${this.$locale.baseText('ndv.output')} ${outputName}`;
					}
					else {
						outputName = capitalize(`${this.getOutputName(i)} ${this.$locale.baseText('ndv.output.branch')}`);
					}
					branches.push({
						label: itemsCount ? `${outputName} (${itemsCount} ${items})` : outputName,
						value: i,
					});
				}
				return branches;
			},
		},
		methods: {
			switchToBinary() {
				this.onDisplayModeChange('binary');
			},
			onBranchChange(value: number) {
				this.outputIndex = value;

				this.$telemetry.track('User changed ndv branch', {
					session_id: this.sessionId,
					branch_index: value,
					node_type: this.activeNode.type,
					node_type_input_selection: this.nodeType? this.nodeType.name: '',
					pane: this.paneType,
				});
			},
			showTooMuchData() {
				this.showData = true;
				this.$telemetry.track('User clicked ndv button', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					type: 'showTooMuchData',
				});
			},
			linkRun() {
				this.$emit('linkRun');
			},
			unlinkRun() {
				this.$emit('unlinkRun');
			},
			onCurrentPageChange() {
				this.$telemetry.track('User changed ndv page', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					page_selected: this.currentPage,
					page_size: this.pageSize,
					items_total: this.dataCount,
				});
			},
			onPageSizeChange(pageSize: number) {
				this.pageSize = pageSize;
				const maxPage = Math.ceil(this.dataCount / this.pageSize);
				if (maxPage < this.currentPage) {
					this.currentPage = maxPage;
				}

				this.$telemetry.track('User changed ndv page size', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					page_selected: this.currentPage,
					page_size: this.pageSize,
					items_total: this.dataCount,
				});
			},
			onDisplayModeChange(displayMode: IRunDataDisplayMode) {
				const previous = this.displayMode;
				this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: displayMode});

				const dataContainer = this.$refs.dataContainer;
				if (dataContainer) {
					const dataDisplay = (dataContainer as Element).children[0];

					if (dataDisplay){
						dataDisplay.scrollTo(0, 0);
					}
				}

				this.closeBinaryDataDisplay();
				this.$externalHooks().run('runData.displayModeChanged', { newValue: displayMode, oldValue: previous });
				if(this.activeNode) {
					this.$telemetry.track('User changed ndv item view', {
						previous_view: previous,
						new_view: displayMode,
						node_type: this.activeNode.type,
						workflow_id: this.$store.getters.workflowId,
						session_id: this.sessionId,
						pane: this.paneType,
					});
				}
			},
			getRunLabel(option: number) {
				let itemsCount = 0;
				for (let i = 0; i <= this.maxOutputIndex; i++) {
					itemsCount += this.getDataCount(option - 1, i);
				}
				const items = this.$locale.baseText('ndv.output.items', {adjustToNumber: itemsCount});
				const itemsLabel = itemsCount > 0 ? ` (${itemsCount} ${items})` : '';
				return option + this.$locale.baseText('ndv.output.of') + (this.maxRunIndex+1) + itemsLabel;
			},
			getDataCount(runIndex: number, outputIndex: number) {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length <= runIndex) {
					return 0;
				}

				if (runData[this.node.name][runIndex].hasOwnProperty('error')) {
					return 1;
				}

				if (!runData[this.node.name][runIndex].hasOwnProperty('data') ||
					runData[this.node.name][runIndex].data === undefined
				) {
					return 0;
				}

				const inputData = this.getMainInputData(runData[this.node.name][runIndex].data!, outputIndex);

				return inputData.length;
			},
			init() {
				// Reset the selected output index every time another node gets selected
				this.outputIndex = 0;
				this.refreshDataSize();
				this.closeBinaryDataDisplay();
				if (this.binaryData.length > 0) {
					this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: 'binary'});
				}
				else if (this.displayMode === 'binary') {
					this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: 'table'});
				}
			},
			closeBinaryDataDisplay () {
				this.binaryDataDisplayVisible = false;
				this.binaryDataDisplayData = null;
			},
			convertToJson (inputData: INodeExecutionData[]): IDataObject[] {
				const returnData: IDataObject[] = [];
				inputData.forEach((data) => {
					if (!data.hasOwnProperty('json')) {
						return;
					}
					returnData.push(data.json);
				});

				return returnData;
			},
			convertToTable (inputData: INodeExecutionData[]): ITableData | undefined {
				const tableData: GenericValue[][] = [];
				const tableColumns: string[] = [];
				let leftEntryColumns: string[], entryRows: GenericValue[];
				// Go over all entries
				let entry: IDataObject;
				inputData.forEach((data) => {
					if (!data.hasOwnProperty('json')) {
						return;
					}
					entry = data.json;

					// Go over all keys of entry
					entryRows = [];
					leftEntryColumns = Object.keys(entry);

					// Go over all the already existing column-keys
					tableColumns.forEach((key) => {
						if (entry.hasOwnProperty(key)) {
							// Entry does have key so add its value
							entryRows.push(entry[key]);
							// Remove key so that we know that it got added
							leftEntryColumns.splice(leftEntryColumns.indexOf(key), 1);
						} else {
							// Entry does not have key so add null
							entryRows.push(null);
						}
					});

					// Go over all the columns the entry has but did not exist yet
					leftEntryColumns.forEach((key) => {
						// Add the key for all runs in the future
						tableColumns.push(key);
						// Add the value
						entryRows.push(entry[key]);
					});

					// Add the data of the entry
					tableData.push(entryRows);
				});

				// Make sure that all entry-rows have the same length
				tableData.forEach((entryRows) => {
					if (tableColumns.length > entryRows.length) {
						// Has to less entries so add the missing ones
						entryRows.push.apply(entryRows, new Array(tableColumns.length - entryRows.length));
					}
				});

				return {
					columns: tableColumns,
					data: tableData,
				};
			},
			clearExecutionData () {
				this.$store.commit('setWorkflowExecutionData', null);
				this.updateNodesExecutionIssues();
			},
			dataItemClicked (path: string, data: object | number | string) {
				this.state.value = data;
			},
			isDownloadable (index: number, key: string): boolean {
				const binaryDataItem: IBinaryData = this.binaryData[index][key];
				return !!(binaryDataItem.mimeType && binaryDataItem.fileName);
			},
			async downloadBinaryData (index: number, key: string) {
				const binaryDataItem: IBinaryData = this.binaryData[index][key];

				let bufferString = 'data:' + binaryDataItem.mimeType + ';base64,';
				if(binaryDataItem.id) {
					bufferString += await this.restApi().getBinaryBufferString(binaryDataItem.id);
				} else {
					bufferString += binaryDataItem.data;
				}

				const data = await fetch(bufferString);
				const blob = await data.blob();
				saveAs(blob, binaryDataItem.fileName);
			},
			displayBinaryData (index: number, key: string) {
				this.binaryDataDisplayVisible = true;

				this.binaryDataDisplayData = {
					node: this.node!.name,
					runIndex: this.runIndex,
					outputIndex: this.currentOutputIndex,
					index,
					key,
				};
			},
			getOutputName (outputIndex: number) {
				if (this.node === null) {
					return outputIndex + 1;
				}

				const nodeType = this.nodeType;
				if (!nodeType || !nodeType.outputNames || nodeType.outputNames.length <= outputIndex) {
					return outputIndex + 1;
				}

				return nodeType.outputNames[outputIndex];
			},
			convertPath (path: string): string {
				// TODO: That can for sure be done fancier but for now it works
				const placeholder = '*___~#^#~___*';
				let inBrackets = path.match(/\[(.*?)\]/g);

				if (inBrackets === null) {
					inBrackets = [];
				} else {
					inBrackets = inBrackets.map(item => item.slice(1, -1)).map(item => {
						if (item.startsWith('"') && item.endsWith('"')) {
							return item.slice(1, -1);
						}
						return item;
					});
				}
				const withoutBrackets = path.replace(/\[(.*?)\]/g, placeholder);
				const pathParts = withoutBrackets.split('.');
				const allParts = [] as string[];
				pathParts.forEach(part => {
					let index = part.indexOf(placeholder);
					while(index !== -1) {
						if (index === 0) {
							allParts.push(inBrackets!.shift() as string);
							part = part.substr(placeholder.length);
						} else {
							allParts.push(part.substr(0, index));
							part = part.substr(index);
						}
						index = part.indexOf(placeholder);
					}
					if (part !== '') {
						allParts.push(part);
					}
				});

				return '["' + allParts.join('"]["') + '"]';
			},
			handleCopyClick (commandData: { command: string }) {
				const newPath = this.convertPath(this.state.path);

				let value: string;
				if (commandData.command === 'value') {
					if (typeof this.state.value === 'object') {
						value = JSON.stringify(this.state.value, null, 2);
					} else {
						value = this.state.value.toString();
					}
				} else {
					let startPath = '';
					let path = '';
					if (commandData.command === 'itemPath') {
						const pathParts = newPath.split(']');
						const index = pathParts[0].slice(1);
						path = pathParts.slice(1).join(']');
						startPath = `$item(${index}).$node["${this.node!.name}"].json`;
					} else if (commandData.command === 'parameterPath') {
						path = newPath.split(']').slice(1).join(']');
						startPath = `$node["${this.node!.name}"].json`;
					}
					if (!path.startsWith('[') && !path.startsWith('.') && path) {
						path += '.';
					}
					value = `{{ ${startPath + path} }}`;
				}

				this.copyToClipboard(value);
			},
			refreshDataSize () {
				// Hide by default the data from being displayed
				this.showData = false;

				// Check how much data there is to display
				const inputData = this.getNodeInputData(this.node, this.runIndex, this.currentOutputIndex);

				const offset = this.pageSize * (this.currentPage - 1);
				const jsonItems = inputData.slice(offset, offset + this.pageSize).map(item => item.json);

				this.dataSize = JSON.stringify(jsonItems).length;

				if (this.dataSize < this.MAX_DISPLAY_DATA_SIZE) {
					// Data is reasonable small (< 200kb) so display it directly
					this.showData = true;
				}
			},
			onRunIndexChange(run: number) {
				this.$emit('runChange', run);
			},
		},
		watch: {
			node() {
				this.init();
			},
			jsonData () {
				this.refreshDataSize();
			},
			binaryData (newData: IBinaryKeyData[], prevData: IBinaryKeyData[]) {
				if (newData.length && !prevData.length && this.displayMode !== 'binary') {
					this.switchToBinary();
				}
				else if (!newData.length && this.displayMode === 'binary') {
					this.onDisplayModeChange('table');
				}
			},
		},
	});
