
import {
	INodeTypeDescription,
} from 'n8n-workflow';
import {
	INodeUi,
	IUpdateInformation,
} from '../Interface';

import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import NodeSettings from '@/components/NodeSettings.vue';
import RunData from '@/components/RunData.vue';

import mixins from 'vue-typed-mixins';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { STICKY_NODE_TYPE } from '@/constants';

export default mixins(externalHooks, nodeHelpers, workflowHelpers).extend({
	name: 'DataDisplay',
	components: {
		NodeSettings,
		RunData,
	},
	props: {
		renaming: {
			type: Boolean,
		},
	},
	data () {
		return {
			settingsEventBus: new Vue(),
			triggerWaitingWarningEnabled: false,
		};
	},
	computed: {
		...mapGetters(['executionWaitingForWebhook']),
		...mapGetters('ui', {isDataPanelCollapsed: 'parameterDataPanelCollapsed'}),
		...mapGetters('imbrace', ['showDebugElements']),
		workflowRunning (): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
		showTriggerWaitingWarning(): boolean {
			return this.triggerWaitingWarningEnabled && !!this.nodeType && !this.nodeType.group.includes('trigger') && this.workflowRunning && this.executionWaitingForWebhook;
		},
		node (): INodeUi {
			return this.$store.getters.activeNode;
		},
		nodeType (): INodeTypeDescription | null {
			if (this.node) {
				return this.$store.getters.nodeType(this.node.type, this.node.typeVersion);
			}
			return null;
		},
		isActiveStickyNode(): boolean {
			return !!this.$store.getters.activeNode && this.$store.getters.activeNode.type === STICKY_NODE_TYPE;
		},
	},
	watch: {
		node (node, oldNode) {
			if(node && !oldNode && !this.isActiveStickyNode) {
				this.triggerWaitingWarningEnabled = false;
				this.$externalHooks().run('dataDisplay.nodeTypeChanged', { nodeSubtitle: this.getNodeSubtitle(node, this.nodeType, this.getWorkflow()) });
				this.$telemetry.track('User opened node modal', { node_type: this.nodeType ? this.nodeType.name : '', workflow_id: this.$store.getters.workflowId });
			}
			// if (window.top && !this.isActiveStickyNode) {
			// 	window.top.postMessage(JSON.stringify({command: (node? 'openNDV': 'closeNDV')}), '*');
			// }
			if (!this.node && !this.renaming && !this.isDataPanelCollapsed) {
				this.$store.commit('ui/toggleParameterDataPanelCollapse');
			}
		},
	},
	methods: {
		onNodeExecute() {
			setTimeout(() => {
				if (!this.node || !this.workflowRunning) {
					return;
				}
				this.triggerWaitingWarningEnabled = true;
			}, 1000);
		},
		openSettings() {
			this.settingsEventBus.$emit('openSettings');
		},
		valueChanged (parameterData: IUpdateInformation) {
			this.$emit('valueChanged', parameterData);
		},
		nodeTypeSelected (nodeTypeName: string) {
			this.$emit('nodeTypeSelected', nodeTypeName);
		},
		close () {
			this.$externalHooks().run('dataDisplay.nodeEditingFinished');
			this.triggerWaitingWarningEnabled = false;
			this.$store.commit('setActiveNode', null);
		},
		getDialogStyle () {
			if (!this.showDebugElements) {
				return 'data-display-wrapper data-panel-noDebugData';
			} else if (this.showDebugElements && this.isDataPanelCollapsed) {
				return 'data-display-wrapper data-panel-collapsed';
			} else {
				return 'data-display-wrapper';
			}
		},
	},
});

