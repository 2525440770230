
import mixins from 'vue-typed-mixins';
import Draggable from './Draggable.vue';
import dragging from './Draggable.vue';
export default mixins(dragging).extend({
	components: {
		Draggable,
	},
	props: {
		canMoveRight: {
			type: Boolean,
		},
		canMoveLeft: {
			type: Boolean,
		},
	},
	methods: {
		onDrag(e: { x: number, y: number }) {
			this.$emit('drag', e);
		},
		onDragStart() {
			this.$emit('dragstart');
		},
		onDragEnd() {
			this.$emit('dragend');
		},
	},
});
