
import { INodeTypeDescription, IWebhookDescription, NodeHelpers } from 'n8n-workflow';

import { WEBHOOK_NODE_TYPE } from '@/constants';
import { copyPaste } from '@/components/mixins/copyPaste';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(copyPaste, showMessage, workflowHelpers).extend({
	name: 'NodeWebhooks',
	props: [
		'node', // NodeUi
		'nodeType', // INodeTypeDescription
	],
	data() {
		return {
			isMinimized: this.nodeType && this.nodeType.name !== WEBHOOK_NODE_TYPE,
			showUrlFor: 'test',
		};
	},
	computed: {
		webhooksNode(): IWebhookDescription[] {
			if (this.nodeType === null || this.nodeType.webhooks === undefined) {
				return [];
			}

			return (this.nodeType as INodeTypeDescription).webhooks!.filter((webhookData) => {
				if (webhookData.hideWebhook) return false;
				return webhookData.restartWebhook !== true;
			});
		},
	},
	methods: {
		copyWebhookUrl(webhookData: IWebhookDescription): void {
			const webhookUrl = this.getWebhookUrl(webhookData);
			this.copyToClipboard(webhookUrl);

			this.$showMessage({
				title: this.$locale.baseText('nodeWebhooks.showMessage.title'),
				type: 'success',
			});
		},
		getValue(webhookData: IWebhookDescription, key: string): string {
			if (webhookData[key] === undefined) {
				return 'empty';
			}
			try {
				return this.resolveExpression(webhookData[key] as string) as string;
			} catch (e) {
				return this.$locale.baseText('nodeWebhooks.invalidExpression');
			}
		},
		getWebhookUrl(webhookData: IWebhookDescription): string {
			if (webhookData.restartWebhook === true) {
				return '$resumeWebhookUrl';
			}
			let baseUrl = this.$store.getters.getWebhookUrl;
			if (this.showUrlFor === 'test') {
				baseUrl = this.$store.getters.getWebhookTestUrl;
			}

			const workflowId = this.$store.getters.workflowId;
			const path = this.getValue(webhookData, 'path');
			const isFullPath = (this.getValue(webhookData, 'isFullPath') as unknown as boolean) || false;

			return NodeHelpers.getNodeWebhookUrl(baseUrl, workflowId, this.node, path, isFullPath);
		},
		getWebhookUrlDisplay(webhookData: IWebhookDescription): string {
			return this.getWebhookUrl(webhookData);
		},
	},
	watch: {
		node() {
			this.isMinimized = this.nodeType.name !== WEBHOOK_NODE_TYPE;
		},
	},
});
