
import TemplateDetails from '@/components/TemplateDetails.vue';
import TemplatesView from './TemplatesView.vue';
import WorkflowPreview from '@/components/WorkflowPreview.vue';

import { ITemplatesWorkflow, ITemplatesWorkflowFull } from '@/Interface';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import mixins from 'vue-typed-mixins';
import { setPageTitle } from '@/components/helpers';
import { VIEWS } from '@/constants';

export default mixins(workflowHelpers).extend({
	name: 'TemplatesWorkflowView',
	components: {
		TemplateDetails,
		TemplatesView,
		WorkflowPreview,
	},
	computed: {
		template(): ITemplatesWorkflow | ITemplatesWorkflowFull {
			return this.$store.getters['templates/getTemplateById'](this.templateId);
		},
		templateId() {
			return this.$route.params.id;
		},
	},
	data() {
		return {
			loading: true,
			showPreview: true,
			notFoundError: false,
		};
	},
	methods: {
		openWorkflow(id: string, e: PointerEvent) {
			this.$telemetry.track('User inserted workflow template', {
				source: 'workflow',
				template_id: id,
				wf_template_repo_session_id: this.$store.getters['templates/currentSessionId'],
			});

			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: VIEWS.TEMPLATE_IMPORT, params: { id } });
				window.open(route.href, '_blank');
				return;
			} else {
				this.$router.push({ name: VIEWS.TEMPLATE_IMPORT, params: { id } });
			}
		},
		onHidePreview() {
			this.showPreview = false;
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
			});
		},
	},
	watch: {
		template(template: ITemplatesWorkflowFull) {
			if (template) {
				setPageTitle(`iMBrace - Template template: ${template.name}`);
			}
			else {
				setPageTitle(`iMBrace - Templates`);
			}
		},
	},
	async mounted() {
		this.scrollToTop();

		if (this.template && (this.template as ITemplatesWorkflowFull).full) {
			this.loading = false;
			return;
		}

		try {
			await this.$store.dispatch('templates/getTemplateById', this.templateId);
		} catch (e) {
			this.notFoundError = true;
		}

		this.loading = false;
	},
});
