
import { camelCase } from 'lodash-es';
import { INodeCreateElement } from '@/Interface';
import Vue from 'vue';

import ItemIterator from './ItemIterator.vue';

export default Vue.extend({
	name: 'SubcategoryPanel',
	components: {
		ItemIterator,
	},
	props: ['title', 'elements', 'activeIndex'],
	computed: {
		subcategoryName() {
			return camelCase(this.title);
		},
	},
	methods: {
		onBackArrowClick() {
			this.$emit('close');
		},
	},
});
