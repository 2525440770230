
import Vue from 'vue';

export default Vue.extend({
	name: 'ShortenText',
	props: {
		text: {
			type: String,
			required: true,
		},
		characterLimit: {
			type: Number,
		},
		characterEndCount: {
			type: Number,
			default: 0,
		},
		styles: {
			type: Object,
		},
		tooltipPlacement: {
			type: String,
			default: 'bottom',
		},
		tooltipEffect: {
			type: String,
			default: 'gray',
		},
		tooltipDisabled: {
			type: Boolean,
			default: undefined,
		},
	},
	data() {
		return {
			isComponentOverflow: false,
		};
	},
	watch: {
		text() {
			this.checkComponentOverflow();
		},
	},
	computed: {
		shortenedText(): string {
			// If there is no characterLimit, decide whether to omit it based on the width.
			if (!this.characterLimit) {
				return this.text;
			}

			// If there is a character limit, omit according to the length of the character limit
			let shortenedString = this.text;

			if (shortenedString.length <= this.characterLimit) {
				return shortenedString;
			}

			const first = shortenedString.slice(0, this.characterLimit - this.characterEndCount);
			const last = shortenedString.slice(
				shortenedString.length - this.characterEndCount,
				shortenedString.length,
			);
			return `${first}...${last}`;
		},
		isCharacterOverflow(): boolean | undefined {
			return this.text.length >= this.characterLimit;
		},
		disableTooltip(): boolean {
			// If tooltipDisabled is set, the tooltip displayed depends on this value
			if (this.tooltipDisabled !== undefined) {
				return this.tooltipDisabled;
			}
			// If characterLimit is set, check whether it overflows
			if (this.characterLimit) {
				return !this.isCharacterOverflow && !this.isComponentOverflow;
			}
			// If text is not shortened, disabled tooltip
			return !this.isComponentOverflow;
		},
		textStyles(): object {
			return {
				'tooltip-wrapper': true,
				...this.styles,
			};
		},
	},
	methods: {
		checkComponentOverflow() {
			this.$nextTick(() => {
				const element = this.$refs.content as HTMLElement;
				if (element) {
					this.isComponentOverflow =
						element.offsetHeight < element.scrollHeight ||
						element.offsetWidth < element.scrollWidth;
				}
			});
		},
	},
	mounted() {
		this.checkComponentOverflow();
	},
});
